/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchAssetAdd = /* GraphQL */ `
  mutation BatchAssetAdd($assetImport: [AssetsInput]) {
    batchAssetAdd(assetImport: $assetImport) {
      id
      siteId
      formFieldValues
      status
      assettemplateID
      Repairs {
        nextToken
        __typename
      }
      Testings {
        nextToken
        __typename
      }
      assetLocation
      assetType
      assetSerialNumber
      assetDescription
      assetModel
      assetMake
      assetWarrantyStart
      assetWarrantyEnd
      installedOn
      assetNumber
      batteryTypeId
      lampTypeId
      fittingTypeId
      assetImage
      distributionboxID
      logbookId
      sitedetailsID
      fittingNumber
      type
      assettemplateSiteID
      source
      createdBy
      assetNum
      duplicate
      assetOwnerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchRepairAdd = /* GraphQL */ `
  mutation BatchRepairAdd($RepairData: [RepairInput]) {
    batchRepairAdd(RepairData: $RepairData) {
      id
      technician
      repairDate
      warrantyUpto
      details
      reportorPics
      status
      customFields
      assetsID
      additionalInformation
      logbookID
      replacedAssetID
      repairTime
      siteId
      assettemplateSiteID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchTestingAdd = /* GraphQL */ `
  mutation BatchTestingAdd($TestingData: [TestingInput]) {
    batchTestingAdd(TestingData: $TestingData) {
      id
      testResult
      warranty
      testOn
      nextTestSchedule
      testReport
      emailNofication
      calendar
      reminder
      status
      customFields
      technician
      assetsID
      additionalInformation
      spUserId
      logbookID
      testTime
      groupId
      siteId
      bulkUpdate
      testType
      tripTime
      assettemplateSiteID
      sitedetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchSiteAdd = /* GraphQL */ `
  mutation BatchSiteAdd($siteData: [SiteInput]) {
    batchSiteAdd(siteData: $siteData) {
      id
      unitNumber
      street
      suburb
      state
      postCode
      country
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      DistributionBoxes {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      companydetailsID
      assetownerID
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      LogBooks {
        nextToken
        __typename
      }
      siteName
      Assets {
        nextToken
        __typename
      }
      Tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchDistributionBoxAdd = /* GraphQL */ `
  mutation BatchDistributionBoxAdd(
    $distributionBoxData: [DistributionBoxInput]
  ) {
    batchDistributionBoxAdd(distributionBoxData: $distributionBoxData) {
      id
      distributionBoxNumber
      location
      status
      sitedetailsID
      distributionBoxName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetCounts = /* GraphQL */ `
  mutation CreateAssetCounts(
    $input: CreateAssetCountsInput!
    $condition: ModelAssetCountsConditionInput
  ) {
    createAssetCounts(input: $input, condition: $condition) {
      id
      assetCount
      categoriesID
      sitedetailsID
      assetownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetCounts = /* GraphQL */ `
  mutation UpdateAssetCounts(
    $input: UpdateAssetCountsInput!
    $condition: ModelAssetCountsConditionInput
  ) {
    updateAssetCounts(input: $input, condition: $condition) {
      id
      assetCount
      categoriesID
      sitedetailsID
      assetownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetCounts = /* GraphQL */ `
  mutation DeleteAssetCounts(
    $input: DeleteAssetCountsInput!
    $condition: ModelAssetCountsConditionInput
  ) {
    deleteAssetCounts(input: $input, condition: $condition) {
      id
      assetCount
      categoriesID
      sitedetailsID
      assetownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLinkAOAPCompany = /* GraphQL */ `
  mutation CreateLinkAOAPCompany(
    $input: CreateLinkAOAPCompanyInput!
    $condition: ModelLinkAOAPCompanyConditionInput
  ) {
    createLinkAOAPCompany(input: $input, condition: $condition) {
      id
      spaoId
      spcomapnyId
      companyAddress
      companyBN
      companyLicense
      companyName
      email
      logo
      phone
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLinkAOAPCompany = /* GraphQL */ `
  mutation UpdateLinkAOAPCompany(
    $input: UpdateLinkAOAPCompanyInput!
    $condition: ModelLinkAOAPCompanyConditionInput
  ) {
    updateLinkAOAPCompany(input: $input, condition: $condition) {
      id
      spaoId
      spcomapnyId
      companyAddress
      companyBN
      companyLicense
      companyName
      email
      logo
      phone
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLinkAOAPCompany = /* GraphQL */ `
  mutation DeleteLinkAOAPCompany(
    $input: DeleteLinkAOAPCompanyInput!
    $condition: ModelLinkAOAPCompanyConditionInput
  ) {
    deleteLinkAOAPCompany(input: $input, condition: $condition) {
      id
      spaoId
      spcomapnyId
      companyAddress
      companyBN
      companyLicense
      companyName
      email
      logo
      phone
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActionLogs = /* GraphQL */ `
  mutation CreateActionLogs(
    $input: CreateActionLogsInput!
    $condition: ModelActionLogsConditionInput
  ) {
    createActionLogs(input: $input, condition: $condition) {
      id
      dataType
      action
      actionedBy
      recordDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActionLogs = /* GraphQL */ `
  mutation UpdateActionLogs(
    $input: UpdateActionLogsInput!
    $condition: ModelActionLogsConditionInput
  ) {
    updateActionLogs(input: $input, condition: $condition) {
      id
      dataType
      action
      actionedBy
      recordDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActionLogs = /* GraphQL */ `
  mutation DeleteActionLogs(
    $input: DeleteActionLogsInput!
    $condition: ModelActionLogsConditionInput
  ) {
    deleteActionLogs(input: $input, condition: $condition) {
      id
      dataType
      action
      actionedBy
      recordDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImportLogs = /* GraphQL */ `
  mutation CreateImportLogs(
    $input: CreateImportLogsInput!
    $condition: ModelImportLogsConditionInput
  ) {
    createImportLogs(input: $input, condition: $condition) {
      id
      dataType
      sourceFileLocation
      successCount
      failedCount
      errorFileLocation
      impotedBy
      siteId
      categoryId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImportLogs = /* GraphQL */ `
  mutation UpdateImportLogs(
    $input: UpdateImportLogsInput!
    $condition: ModelImportLogsConditionInput
  ) {
    updateImportLogs(input: $input, condition: $condition) {
      id
      dataType
      sourceFileLocation
      successCount
      failedCount
      errorFileLocation
      impotedBy
      siteId
      categoryId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImportLogs = /* GraphQL */ `
  mutation DeleteImportLogs(
    $input: DeleteImportLogsInput!
    $condition: ModelImportLogsConditionInput
  ) {
    deleteImportLogs(input: $input, condition: $condition) {
      id
      dataType
      sourceFileLocation
      successCount
      failedCount
      errorFileLocation
      impotedBy
      siteId
      categoryId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriptionAdd = /* GraphQL */ `
  mutation CreateSubscriptionAdd(
    $input: CreateSubscriptionAddInput!
    $condition: ModelSubscriptionAddConditionInput
  ) {
    createSubscriptionAdd(input: $input, condition: $condition) {
      id
      subscriptionId
      quantity
      amount
      currentCount
      subscriptionlimitID
      dueDate
      invoiceId
      startDate
      userdetailsID
      status
      paymentObject
      stripeSubscriptionId
      stripeSubscriptionEndedOn
      paymentObjectBeforePayment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriptionAdd = /* GraphQL */ `
  mutation UpdateSubscriptionAdd(
    $input: UpdateSubscriptionAddInput!
    $condition: ModelSubscriptionAddConditionInput
  ) {
    updateSubscriptionAdd(input: $input, condition: $condition) {
      id
      subscriptionId
      quantity
      amount
      currentCount
      subscriptionlimitID
      dueDate
      invoiceId
      startDate
      userdetailsID
      status
      paymentObject
      stripeSubscriptionId
      stripeSubscriptionEndedOn
      paymentObjectBeforePayment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriptionAdd = /* GraphQL */ `
  mutation DeleteSubscriptionAdd(
    $input: DeleteSubscriptionAddInput!
    $condition: ModelSubscriptionAddConditionInput
  ) {
    deleteSubscriptionAdd(input: $input, condition: $condition) {
      id
      subscriptionId
      quantity
      amount
      currentCount
      subscriptionlimitID
      dueDate
      invoiceId
      startDate
      userdetailsID
      status
      paymentObject
      stripeSubscriptionId
      stripeSubscriptionEndedOn
      paymentObjectBeforePayment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmailSent = /* GraphQL */ `
  mutation CreateEmailSent(
    $input: CreateEmailSentInput!
    $condition: ModelEmailSentConditionInput
  ) {
    createEmailSent(input: $input, condition: $condition) {
      id
      emailType
      lastSent
      testId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailSent = /* GraphQL */ `
  mutation UpdateEmailSent(
    $input: UpdateEmailSentInput!
    $condition: ModelEmailSentConditionInput
  ) {
    updateEmailSent(input: $input, condition: $condition) {
      id
      emailType
      lastSent
      testId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailSent = /* GraphQL */ `
  mutation DeleteEmailSent(
    $input: DeleteEmailSentInput!
    $condition: ModelEmailSentConditionInput
  ) {
    deleteEmailSent(input: $input, condition: $condition) {
      id
      emailType
      lastSent
      testId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      taskType
      taskAssignee
      assignedDate
      dueDate
      assignedBy
      taskStatus
      taskCompletionDate
      taskCompletionLink
      sitedetailsID
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      taskType
      taskAssignee
      assignedDate
      dueDate
      assignedBy
      taskStatus
      taskCompletionDate
      taskCompletionLink
      sitedetailsID
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      taskType
      taskAssignee
      assignedDate
      dueDate
      assignedBy
      taskStatus
      taskCompletionDate
      taskCompletionLink
      sitedetailsID
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLogbookEmail = /* GraphQL */ `
  mutation CreateLogbookEmail(
    $input: CreateLogbookEmailInput!
    $condition: ModelLogbookEmailConditionInput
  ) {
    createLogbookEmail(input: $input, condition: $condition) {
      id
      emailID
      status
      pdfUrl
      logbookID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLogbookEmail = /* GraphQL */ `
  mutation UpdateLogbookEmail(
    $input: UpdateLogbookEmailInput!
    $condition: ModelLogbookEmailConditionInput
  ) {
    updateLogbookEmail(input: $input, condition: $condition) {
      id
      emailID
      status
      pdfUrl
      logbookID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLogbookEmail = /* GraphQL */ `
  mutation DeleteLogbookEmail(
    $input: DeleteLogbookEmailInput!
    $condition: ModelLogbookEmailConditionInput
  ) {
    deleteLogbookEmail(input: $input, condition: $condition) {
      id
      emailID
      status
      pdfUrl
      logbookID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReports = /* GraphQL */ `
  mutation CreateReports(
    $input: CreateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    createReports(input: $input, condition: $condition) {
      id
      reportURL
      userID
      assetID
      categoriesID
      sitedetailsID
      reportName
      distributionBoxId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReports = /* GraphQL */ `
  mutation UpdateReports(
    $input: UpdateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    updateReports(input: $input, condition: $condition) {
      id
      reportURL
      userID
      assetID
      categoriesID
      sitedetailsID
      reportName
      distributionBoxId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReports = /* GraphQL */ `
  mutation DeleteReports(
    $input: DeleteReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    deleteReports(input: $input, condition: $condition) {
      id
      reportURL
      userID
      assetID
      categoriesID
      sitedetailsID
      reportName
      distributionBoxId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCertificates = /* GraphQL */ `
  mutation CreateCertificates(
    $input: CreateCertificatesInput!
    $condition: ModelCertificatesConditionInput
  ) {
    createCertificates(input: $input, condition: $condition) {
      id
      certificateURL
      categoriesID
      sitedetailsID
      userID
      certificateName
      certificateType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCertificates = /* GraphQL */ `
  mutation UpdateCertificates(
    $input: UpdateCertificatesInput!
    $condition: ModelCertificatesConditionInput
  ) {
    updateCertificates(input: $input, condition: $condition) {
      id
      certificateURL
      categoriesID
      sitedetailsID
      userID
      certificateName
      certificateType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCertificates = /* GraphQL */ `
  mutation DeleteCertificates(
    $input: DeleteCertificatesInput!
    $condition: ModelCertificatesConditionInput
  ) {
    deleteCertificates(input: $input, condition: $condition) {
      id
      certificateURL
      categoriesID
      sitedetailsID
      userID
      certificateName
      certificateType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLogbookAssetLink = /* GraphQL */ `
  mutation CreateLogbookAssetLink(
    $input: CreateLogbookAssetLinkInput!
    $condition: ModelLogbookAssetLinkConditionInput
  ) {
    createLogbookAssetLink(input: $input, condition: $condition) {
      id
      Logbook
      AssetID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLogbookAssetLink = /* GraphQL */ `
  mutation UpdateLogbookAssetLink(
    $input: UpdateLogbookAssetLinkInput!
    $condition: ModelLogbookAssetLinkConditionInput
  ) {
    updateLogbookAssetLink(input: $input, condition: $condition) {
      id
      Logbook
      AssetID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLogbookAssetLink = /* GraphQL */ `
  mutation DeleteLogbookAssetLink(
    $input: DeleteLogbookAssetLinkInput!
    $condition: ModelLogbookAssetLinkConditionInput
  ) {
    deleteLogbookAssetLink(input: $input, condition: $condition) {
      id
      Logbook
      AssetID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriptionLimit = /* GraphQL */ `
  mutation CreateSubscriptionLimit(
    $input: CreateSubscriptionLimitInput!
    $condition: ModelSubscriptionLimitConditionInput
  ) {
    createSubscriptionLimit(input: $input, condition: $condition) {
      id
      subscriptionId
      AssetLimit
      SiteLimit
      SiteUserLimit
      SPUserLimit
      Price
      SubscriptionName
      FutureCost
      UserTopup
      MinAsset
      DiscountPrice
      Type
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriptionLimit = /* GraphQL */ `
  mutation UpdateSubscriptionLimit(
    $input: UpdateSubscriptionLimitInput!
    $condition: ModelSubscriptionLimitConditionInput
  ) {
    updateSubscriptionLimit(input: $input, condition: $condition) {
      id
      subscriptionId
      AssetLimit
      SiteLimit
      SiteUserLimit
      SPUserLimit
      Price
      SubscriptionName
      FutureCost
      UserTopup
      MinAsset
      DiscountPrice
      Type
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriptionLimit = /* GraphQL */ `
  mutation DeleteSubscriptionLimit(
    $input: DeleteSubscriptionLimitInput!
    $condition: ModelSubscriptionLimitConditionInput
  ) {
    deleteSubscriptionLimit(input: $input, condition: $condition) {
      id
      subscriptionId
      AssetLimit
      SiteLimit
      SiteUserLimit
      SPUserLimit
      Price
      SubscriptionName
      FutureCost
      UserTopup
      MinAsset
      DiscountPrice
      Type
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createServiceGroupUserMap = /* GraphQL */ `
  mutation CreateServiceGroupUserMap(
    $input: CreateServiceGroupUserMapInput!
    $condition: ModelServiceGroupUserMapConditionInput
  ) {
    createServiceGroupUserMap(input: $input, condition: $condition) {
      id
      groupName
      cognitogroupID
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateServiceGroupUserMap = /* GraphQL */ `
  mutation UpdateServiceGroupUserMap(
    $input: UpdateServiceGroupUserMapInput!
    $condition: ModelServiceGroupUserMapConditionInput
  ) {
    updateServiceGroupUserMap(input: $input, condition: $condition) {
      id
      groupName
      cognitogroupID
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteServiceGroupUserMap = /* GraphQL */ `
  mutation DeleteServiceGroupUserMap(
    $input: DeleteServiceGroupUserMapInput!
    $condition: ModelServiceGroupUserMapConditionInput
  ) {
    deleteServiceGroupUserMap(input: $input, condition: $condition) {
      id
      groupName
      cognitogroupID
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCognitoGroup = /* GraphQL */ `
  mutation CreateCognitoGroup(
    $input: CreateCognitoGroupInput!
    $condition: ModelCognitoGroupConditionInput
  ) {
    createCognitoGroup(input: $input, condition: $condition) {
      id
      groupName
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCognitoGroup = /* GraphQL */ `
  mutation UpdateCognitoGroup(
    $input: UpdateCognitoGroupInput!
    $condition: ModelCognitoGroupConditionInput
  ) {
    updateCognitoGroup(input: $input, condition: $condition) {
      id
      groupName
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCognitoGroup = /* GraphQL */ `
  mutation DeleteCognitoGroup(
    $input: DeleteCognitoGroupInput!
    $condition: ModelCognitoGroupConditionInput
  ) {
    deleteCognitoGroup(input: $input, condition: $condition) {
      id
      groupName
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssets = /* GraphQL */ `
  mutation CreateAssets(
    $input: CreateAssetsInput!
    $condition: ModelAssetsConditionInput
  ) {
    createAssets(input: $input, condition: $condition) {
      id
      siteId
      formFieldValues
      status
      assettemplateID
      Repairs {
        nextToken
        __typename
      }
      Testings {
        nextToken
        __typename
      }
      assetLocation
      assetType
      assetSerialNumber
      assetDescription
      assetModel
      assetMake
      assetWarrantyStart
      assetWarrantyEnd
      installedOn
      assetNumber
      batteryTypeId
      lampTypeId
      fittingTypeId
      assetImage
      distributionboxID
      logbookId
      sitedetailsID
      fittingNumber
      type
      assettemplateSiteID
      source
      createdBy
      assetNum
      duplicate
      assetOwnerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssets = /* GraphQL */ `
  mutation UpdateAssets(
    $input: UpdateAssetsInput!
    $condition: ModelAssetsConditionInput
  ) {
    updateAssets(input: $input, condition: $condition) {
      id
      siteId
      formFieldValues
      status
      assettemplateID
      Repairs {
        nextToken
        __typename
      }
      Testings {
        nextToken
        __typename
      }
      assetLocation
      assetType
      assetSerialNumber
      assetDescription
      assetModel
      assetMake
      assetWarrantyStart
      assetWarrantyEnd
      installedOn
      assetNumber
      batteryTypeId
      lampTypeId
      fittingTypeId
      assetImage
      distributionboxID
      logbookId
      sitedetailsID
      fittingNumber
      type
      assettemplateSiteID
      source
      createdBy
      assetNum
      duplicate
      assetOwnerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssets = /* GraphQL */ `
  mutation DeleteAssets(
    $input: DeleteAssetsInput!
    $condition: ModelAssetsConditionInput
  ) {
    deleteAssets(input: $input, condition: $condition) {
      id
      siteId
      formFieldValues
      status
      assettemplateID
      Repairs {
        nextToken
        __typename
      }
      Testings {
        nextToken
        __typename
      }
      assetLocation
      assetType
      assetSerialNumber
      assetDescription
      assetModel
      assetMake
      assetWarrantyStart
      assetWarrantyEnd
      installedOn
      assetNumber
      batteryTypeId
      lampTypeId
      fittingTypeId
      assetImage
      distributionboxID
      logbookId
      sitedetailsID
      fittingNumber
      type
      assettemplateSiteID
      source
      createdBy
      assetNum
      duplicate
      assetOwnerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetTemplate = /* GraphQL */ `
  mutation CreateAssetTemplate(
    $input: CreateAssetTemplateInput!
    $condition: ModelAssetTemplateConditionInput
  ) {
    createAssetTemplate(input: $input, condition: $condition) {
      id
      templatename
      description
      formId
      status
      categoriesID
      Assets {
        nextToken
        __typename
      }
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetTemplate = /* GraphQL */ `
  mutation UpdateAssetTemplate(
    $input: UpdateAssetTemplateInput!
    $condition: ModelAssetTemplateConditionInput
  ) {
    updateAssetTemplate(input: $input, condition: $condition) {
      id
      templatename
      description
      formId
      status
      categoriesID
      Assets {
        nextToken
        __typename
      }
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetTemplate = /* GraphQL */ `
  mutation DeleteAssetTemplate(
    $input: DeleteAssetTemplateInput!
    $condition: ModelAssetTemplateConditionInput
  ) {
    deleteAssetTemplate(input: $input, condition: $condition) {
      id
      templatename
      description
      formId
      status
      categoriesID
      Assets {
        nextToken
        __typename
      }
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLinkUsersAndSite = /* GraphQL */ `
  mutation CreateLinkUsersAndSite(
    $input: CreateLinkUsersAndSiteInput!
    $condition: ModelLinkUsersAndSiteConditionInput
  ) {
    createLinkUsersAndSite(input: $input, condition: $condition) {
      id
      status
      sitedetailsID
      userdetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLinkUsersAndSite = /* GraphQL */ `
  mutation UpdateLinkUsersAndSite(
    $input: UpdateLinkUsersAndSiteInput!
    $condition: ModelLinkUsersAndSiteConditionInput
  ) {
    updateLinkUsersAndSite(input: $input, condition: $condition) {
      id
      status
      sitedetailsID
      userdetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLinkUsersAndSite = /* GraphQL */ `
  mutation DeleteLinkUsersAndSite(
    $input: DeleteLinkUsersAndSiteInput!
    $condition: ModelLinkUsersAndSiteConditionInput
  ) {
    deleteLinkUsersAndSite(input: $input, condition: $condition) {
      id
      status
      sitedetailsID
      userdetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStaticForm = /* GraphQL */ `
  mutation CreateStaticForm(
    $input: CreateStaticFormInput!
    $condition: ModelStaticFormConditionInput
  ) {
    createStaticForm(input: $input, condition: $condition) {
      id
      formName
      formFields
      dataModelName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStaticForm = /* GraphQL */ `
  mutation UpdateStaticForm(
    $input: UpdateStaticFormInput!
    $condition: ModelStaticFormConditionInput
  ) {
    updateStaticForm(input: $input, condition: $condition) {
      id
      formName
      formFields
      dataModelName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStaticForm = /* GraphQL */ `
  mutation DeleteStaticForm(
    $input: DeleteStaticFormInput!
    $condition: ModelStaticFormConditionInput
  ) {
    deleteStaticForm(input: $input, condition: $condition) {
      id
      formName
      formFields
      dataModelName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategories = /* GraphQL */ `
  mutation CreateCategories(
    $input: CreateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    createCategories(input: $input, condition: $condition) {
      id
      categoryName
      formDescription
      formIds
      categoryNumber
      status
      AssetTemplates {
        nextToken
        __typename
      }
      servicegroupID
      imageURL
      assetNumberPrefix
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategories = /* GraphQL */ `
  mutation UpdateCategories(
    $input: UpdateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    updateCategories(input: $input, condition: $condition) {
      id
      categoryName
      formDescription
      formIds
      categoryNumber
      status
      AssetTemplates {
        nextToken
        __typename
      }
      servicegroupID
      imageURL
      assetNumberPrefix
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategories = /* GraphQL */ `
  mutation DeleteCategories(
    $input: DeleteCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    deleteCategories(input: $input, condition: $condition) {
      id
      categoryName
      formDescription
      formIds
      categoryNumber
      status
      AssetTemplates {
        nextToken
        __typename
      }
      servicegroupID
      imageURL
      assetNumberPrefix
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createServiceGroup = /* GraphQL */ `
  mutation CreateServiceGroup(
    $input: CreateServiceGroupInput!
    $condition: ModelServiceGroupConditionInput
  ) {
    createServiceGroup(input: $input, condition: $condition) {
      id
      serviceName
      serviceProvider
      status
      serviceNumber
      Categories {
        nextToken
        __typename
      }
      AssetTemplates {
        nextToken
        __typename
      }
      FormDetails {
        nextToken
        __typename
      }
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateServiceGroup = /* GraphQL */ `
  mutation UpdateServiceGroup(
    $input: UpdateServiceGroupInput!
    $condition: ModelServiceGroupConditionInput
  ) {
    updateServiceGroup(input: $input, condition: $condition) {
      id
      serviceName
      serviceProvider
      status
      serviceNumber
      Categories {
        nextToken
        __typename
      }
      AssetTemplates {
        nextToken
        __typename
      }
      FormDetails {
        nextToken
        __typename
      }
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteServiceGroup = /* GraphQL */ `
  mutation DeleteServiceGroup(
    $input: DeleteServiceGroupInput!
    $condition: ModelServiceGroupConditionInput
  ) {
    deleteServiceGroup(input: $input, condition: $condition) {
      id
      serviceName
      serviceProvider
      status
      serviceNumber
      Categories {
        nextToken
        __typename
      }
      AssetTemplates {
        nextToken
        __typename
      }
      FormDetails {
        nextToken
        __typename
      }
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDistributionBox = /* GraphQL */ `
  mutation CreateDistributionBox(
    $input: CreateDistributionBoxInput!
    $condition: ModelDistributionBoxConditionInput
  ) {
    createDistributionBox(input: $input, condition: $condition) {
      id
      distributionBoxNumber
      location
      status
      sitedetailsID
      distributionBoxName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDistributionBox = /* GraphQL */ `
  mutation UpdateDistributionBox(
    $input: UpdateDistributionBoxInput!
    $condition: ModelDistributionBoxConditionInput
  ) {
    updateDistributionBox(input: $input, condition: $condition) {
      id
      distributionBoxNumber
      location
      status
      sitedetailsID
      distributionBoxName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDistributionBox = /* GraphQL */ `
  mutation DeleteDistributionBox(
    $input: DeleteDistributionBoxInput!
    $condition: ModelDistributionBoxConditionInput
  ) {
    deleteDistributionBox(input: $input, condition: $condition) {
      id
      distributionBoxNumber
      location
      status
      sitedetailsID
      distributionBoxName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLampType = /* GraphQL */ `
  mutation CreateLampType(
    $input: CreateLampTypeInput!
    $condition: ModelLampTypeConditionInput
  ) {
    createLampType(input: $input, condition: $condition) {
      id
      lampType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLampType = /* GraphQL */ `
  mutation UpdateLampType(
    $input: UpdateLampTypeInput!
    $condition: ModelLampTypeConditionInput
  ) {
    updateLampType(input: $input, condition: $condition) {
      id
      lampType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLampType = /* GraphQL */ `
  mutation DeleteLampType(
    $input: DeleteLampTypeInput!
    $condition: ModelLampTypeConditionInput
  ) {
    deleteLampType(input: $input, condition: $condition) {
      id
      lampType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBatteryType = /* GraphQL */ `
  mutation CreateBatteryType(
    $input: CreateBatteryTypeInput!
    $condition: ModelBatteryTypeConditionInput
  ) {
    createBatteryType(input: $input, condition: $condition) {
      id
      batteryType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBatteryType = /* GraphQL */ `
  mutation UpdateBatteryType(
    $input: UpdateBatteryTypeInput!
    $condition: ModelBatteryTypeConditionInput
  ) {
    updateBatteryType(input: $input, condition: $condition) {
      id
      batteryType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBatteryType = /* GraphQL */ `
  mutation DeleteBatteryType(
    $input: DeleteBatteryTypeInput!
    $condition: ModelBatteryTypeConditionInput
  ) {
    deleteBatteryType(input: $input, condition: $condition) {
      id
      batteryType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFittingType = /* GraphQL */ `
  mutation CreateFittingType(
    $input: CreateFittingTypeInput!
    $condition: ModelFittingTypeConditionInput
  ) {
    createFittingType(input: $input, condition: $condition) {
      id
      fittingType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFittingType = /* GraphQL */ `
  mutation UpdateFittingType(
    $input: UpdateFittingTypeInput!
    $condition: ModelFittingTypeConditionInput
  ) {
    updateFittingType(input: $input, condition: $condition) {
      id
      fittingType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFittingType = /* GraphQL */ `
  mutation DeleteFittingType(
    $input: DeleteFittingTypeInput!
    $condition: ModelFittingTypeConditionInput
  ) {
    deleteFittingType(input: $input, condition: $condition) {
      id
      fittingType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormDetails = /* GraphQL */ `
  mutation CreateFormDetails(
    $input: CreateFormDetailsInput!
    $condition: ModelFormDetailsConditionInput
  ) {
    createFormDetails(input: $input, condition: $condition) {
      id
      formName
      serviceGroup
      formDescription
      enableSchedule
      formFields
      status
      formNumber
      formType
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormDetails = /* GraphQL */ `
  mutation UpdateFormDetails(
    $input: UpdateFormDetailsInput!
    $condition: ModelFormDetailsConditionInput
  ) {
    updateFormDetails(input: $input, condition: $condition) {
      id
      formName
      serviceGroup
      formDescription
      enableSchedule
      formFields
      status
      formNumber
      formType
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormDetails = /* GraphQL */ `
  mutation DeleteFormDetails(
    $input: DeleteFormDetailsInput!
    $condition: ModelFormDetailsConditionInput
  ) {
    deleteFormDetails(input: $input, condition: $condition) {
      id
      formName
      serviceGroup
      formDescription
      enableSchedule
      formFields
      status
      formNumber
      formType
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSPUserDetails = /* GraphQL */ `
  mutation CreateSPUserDetails(
    $input: CreateSPUserDetailsInput!
    $condition: ModelSPUserDetailsConditionInput
  ) {
    createSPUserDetails(input: $input, condition: $condition) {
      id
      fname
      lname
      email
      mobile
      password
      license
      profilepic
      status
      sitedetailsID
      UserDetails {
        id
        username
        email
        companyDetailsId
        assetOwnerId
        paymentId
        siteDetailsId
        userDetailsId
        status
        pushNotificationToken
        permissions
        paymentObject
        createdAt
        updatedAt
        __typename
      }
      userGroup
      permission
      invitedBy
      SPGroup
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSPUserDetails = /* GraphQL */ `
  mutation UpdateSPUserDetails(
    $input: UpdateSPUserDetailsInput!
    $condition: ModelSPUserDetailsConditionInput
  ) {
    updateSPUserDetails(input: $input, condition: $condition) {
      id
      fname
      lname
      email
      mobile
      password
      license
      profilepic
      status
      sitedetailsID
      UserDetails {
        id
        username
        email
        companyDetailsId
        assetOwnerId
        paymentId
        siteDetailsId
        userDetailsId
        status
        pushNotificationToken
        permissions
        paymentObject
        createdAt
        updatedAt
        __typename
      }
      userGroup
      permission
      invitedBy
      SPGroup
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSPUserDetails = /* GraphQL */ `
  mutation DeleteSPUserDetails(
    $input: DeleteSPUserDetailsInput!
    $condition: ModelSPUserDetailsConditionInput
  ) {
    deleteSPUserDetails(input: $input, condition: $condition) {
      id
      fname
      lname
      email
      mobile
      password
      license
      profilepic
      status
      sitedetailsID
      UserDetails {
        id
        username
        email
        companyDetailsId
        assetOwnerId
        paymentId
        siteDetailsId
        userDetailsId
        status
        pushNotificationToken
        permissions
        paymentObject
        createdAt
        updatedAt
        __typename
      }
      userGroup
      permission
      invitedBy
      SPGroup
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBillingPlan = /* GraphQL */ `
  mutation CreateBillingPlan(
    $input: CreateBillingPlanInput!
    $condition: ModelBillingPlanConditionInput
  ) {
    createBillingPlan(input: $input, condition: $condition) {
      id
      plan
      assets
      price
      users
      additionalUsers
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBillingPlan = /* GraphQL */ `
  mutation UpdateBillingPlan(
    $input: UpdateBillingPlanInput!
    $condition: ModelBillingPlanConditionInput
  ) {
    updateBillingPlan(input: $input, condition: $condition) {
      id
      plan
      assets
      price
      users
      additionalUsers
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBillingPlan = /* GraphQL */ `
  mutation DeleteBillingPlan(
    $input: DeleteBillingPlanInput!
    $condition: ModelBillingPlanConditionInput
  ) {
    deleteBillingPlan(input: $input, condition: $condition) {
      id
      plan
      assets
      price
      users
      additionalUsers
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserDetails = /* GraphQL */ `
  mutation CreateUserDetails(
    $input: CreateUserDetailsInput!
    $condition: ModelUserDetailsConditionInput
  ) {
    createUserDetails(input: $input, condition: $condition) {
      id
      username
      email
      companyDetailsId
      assetOwnerId
      paymentId
      siteDetailsId
      userDetailsId
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      pushNotificationToken
      permissions
      paymentObject
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails(
    $input: UpdateUserDetailsInput!
    $condition: ModelUserDetailsConditionInput
  ) {
    updateUserDetails(input: $input, condition: $condition) {
      id
      username
      email
      companyDetailsId
      assetOwnerId
      paymentId
      siteDetailsId
      userDetailsId
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      pushNotificationToken
      permissions
      paymentObject
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserDetails = /* GraphQL */ `
  mutation DeleteUserDetails(
    $input: DeleteUserDetailsInput!
    $condition: ModelUserDetailsConditionInput
  ) {
    deleteUserDetails(input: $input, condition: $condition) {
      id
      username
      email
      companyDetailsId
      assetOwnerId
      paymentId
      siteDetailsId
      userDetailsId
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      pushNotificationToken
      permissions
      paymentObject
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRepair = /* GraphQL */ `
  mutation CreateRepair(
    $input: CreateRepairInput!
    $condition: ModelRepairConditionInput
  ) {
    createRepair(input: $input, condition: $condition) {
      id
      technician
      repairDate
      warrantyUpto
      details
      reportorPics
      status
      customFields
      assetsID
      additionalInformation
      logbookID
      replacedAssetID
      repairTime
      siteId
      assettemplateSiteID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRepair = /* GraphQL */ `
  mutation UpdateRepair(
    $input: UpdateRepairInput!
    $condition: ModelRepairConditionInput
  ) {
    updateRepair(input: $input, condition: $condition) {
      id
      technician
      repairDate
      warrantyUpto
      details
      reportorPics
      status
      customFields
      assetsID
      additionalInformation
      logbookID
      replacedAssetID
      repairTime
      siteId
      assettemplateSiteID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRepair = /* GraphQL */ `
  mutation DeleteRepair(
    $input: DeleteRepairInput!
    $condition: ModelRepairConditionInput
  ) {
    deleteRepair(input: $input, condition: $condition) {
      id
      technician
      repairDate
      warrantyUpto
      details
      reportorPics
      status
      customFields
      assetsID
      additionalInformation
      logbookID
      replacedAssetID
      repairTime
      siteId
      assettemplateSiteID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTesting = /* GraphQL */ `
  mutation CreateTesting(
    $input: CreateTestingInput!
    $condition: ModelTestingConditionInput
  ) {
    createTesting(input: $input, condition: $condition) {
      id
      testResult
      warranty
      testOn
      nextTestSchedule
      testReport
      emailNofication
      calendar
      reminder
      status
      customFields
      technician
      assetsID
      additionalInformation
      spUserId
      logbookID
      testTime
      groupId
      siteId
      bulkUpdate
      testType
      tripTime
      assettemplateSiteID
      sitedetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTesting = /* GraphQL */ `
  mutation UpdateTesting(
    $input: UpdateTestingInput!
    $condition: ModelTestingConditionInput
  ) {
    updateTesting(input: $input, condition: $condition) {
      id
      testResult
      warranty
      testOn
      nextTestSchedule
      testReport
      emailNofication
      calendar
      reminder
      status
      customFields
      technician
      assetsID
      additionalInformation
      spUserId
      logbookID
      testTime
      groupId
      siteId
      bulkUpdate
      testType
      tripTime
      assettemplateSiteID
      sitedetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTesting = /* GraphQL */ `
  mutation DeleteTesting(
    $input: DeleteTestingInput!
    $condition: ModelTestingConditionInput
  ) {
    deleteTesting(input: $input, condition: $condition) {
      id
      testResult
      warranty
      testOn
      nextTestSchedule
      testReport
      emailNofication
      calendar
      reminder
      status
      customFields
      technician
      assetsID
      additionalInformation
      spUserId
      logbookID
      testTime
      groupId
      siteId
      bulkUpdate
      testType
      tripTime
      assettemplateSiteID
      sitedetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLogBook = /* GraphQL */ `
  mutation CreateLogBook(
    $input: CreateLogBookInput!
    $condition: ModelLogBookConditionInput
  ) {
    createLogBook(input: $input, condition: $condition) {
      id
      reports
      status
      siteId
      customFields
      additionalInformation
      technician
      Testings {
        nextToken
        __typename
      }
      Repairs {
        nextToken
        __typename
      }
      distributionBox
      repairInfo
      LogbookAssetLink {
        id
        Logbook
        AssetID
        createdAt
        updatedAt
        __typename
      }
      logbookDateTime
      LogbookEmails {
        nextToken
        __typename
      }
      sitedetailsID
      assetsID
      logbookName
      categoryId
      createdAt
      updatedAt
      logBookLogbookAssetLinkId
      __typename
    }
  }
`;
export const updateLogBook = /* GraphQL */ `
  mutation UpdateLogBook(
    $input: UpdateLogBookInput!
    $condition: ModelLogBookConditionInput
  ) {
    updateLogBook(input: $input, condition: $condition) {
      id
      reports
      status
      siteId
      customFields
      additionalInformation
      technician
      Testings {
        nextToken
        __typename
      }
      Repairs {
        nextToken
        __typename
      }
      distributionBox
      repairInfo
      LogbookAssetLink {
        id
        Logbook
        AssetID
        createdAt
        updatedAt
        __typename
      }
      logbookDateTime
      LogbookEmails {
        nextToken
        __typename
      }
      sitedetailsID
      assetsID
      logbookName
      categoryId
      createdAt
      updatedAt
      logBookLogbookAssetLinkId
      __typename
    }
  }
`;
export const deleteLogBook = /* GraphQL */ `
  mutation DeleteLogBook(
    $input: DeleteLogBookInput!
    $condition: ModelLogBookConditionInput
  ) {
    deleteLogBook(input: $input, condition: $condition) {
      id
      reports
      status
      siteId
      customFields
      additionalInformation
      technician
      Testings {
        nextToken
        __typename
      }
      Repairs {
        nextToken
        __typename
      }
      distributionBox
      repairInfo
      LogbookAssetLink {
        id
        Logbook
        AssetID
        createdAt
        updatedAt
        __typename
      }
      logbookDateTime
      LogbookEmails {
        nextToken
        __typename
      }
      sitedetailsID
      assetsID
      logbookName
      categoryId
      createdAt
      updatedAt
      logBookLogbookAssetLinkId
      __typename
    }
  }
`;
export const createCompanyDetails = /* GraphQL */ `
  mutation CreateCompanyDetails(
    $input: CreateCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    createCompanyDetails(input: $input, condition: $condition) {
      id
      companyName
      companyBN
      companyAddress
      logo
      managerName
      email
      mobile
      status
      street
      suburb
      state
      postcode
      country
      SiteDetails {
        nextToken
        __typename
      }
      AssetOwners {
        nextToken
        __typename
      }
      website
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyDetails = /* GraphQL */ `
  mutation UpdateCompanyDetails(
    $input: UpdateCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    updateCompanyDetails(input: $input, condition: $condition) {
      id
      companyName
      companyBN
      companyAddress
      logo
      managerName
      email
      mobile
      status
      street
      suburb
      state
      postcode
      country
      SiteDetails {
        nextToken
        __typename
      }
      AssetOwners {
        nextToken
        __typename
      }
      website
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyDetails = /* GraphQL */ `
  mutation DeleteCompanyDetails(
    $input: DeleteCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    deleteCompanyDetails(input: $input, condition: $condition) {
      id
      companyName
      companyBN
      companyAddress
      logo
      managerName
      email
      mobile
      status
      street
      suburb
      state
      postcode
      country
      SiteDetails {
        nextToken
        __typename
      }
      AssetOwners {
        nextToken
        __typename
      }
      website
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSPCompanyDetails = /* GraphQL */ `
  mutation CreateSPCompanyDetails(
    $input: CreateSPCompanyDetailsInput!
    $condition: ModelSPCompanyDetailsConditionInput
  ) {
    createSPCompanyDetails(input: $input, condition: $condition) {
      id
      companyName
      companyBN
      companyAddress
      companyLicense
      status
      street
      suburb
      state
      postcode
      country
      logo
      phone
      website
      EC
      FAX
      email
      Tasks {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSPCompanyDetails = /* GraphQL */ `
  mutation UpdateSPCompanyDetails(
    $input: UpdateSPCompanyDetailsInput!
    $condition: ModelSPCompanyDetailsConditionInput
  ) {
    updateSPCompanyDetails(input: $input, condition: $condition) {
      id
      companyName
      companyBN
      companyAddress
      companyLicense
      status
      street
      suburb
      state
      postcode
      country
      logo
      phone
      website
      EC
      FAX
      email
      Tasks {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSPCompanyDetails = /* GraphQL */ `
  mutation DeleteSPCompanyDetails(
    $input: DeleteSPCompanyDetailsInput!
    $condition: ModelSPCompanyDetailsConditionInput
  ) {
    deleteSPCompanyDetails(input: $input, condition: $condition) {
      id
      companyName
      companyBN
      companyAddress
      companyLicense
      status
      street
      suburb
      state
      postcode
      country
      logo
      phone
      website
      EC
      FAX
      email
      Tasks {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetOwner = /* GraphQL */ `
  mutation CreateAssetOwner(
    $input: CreateAssetOwnerInput!
    $condition: ModelAssetOwnerConditionInput
  ) {
    createAssetOwner(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      mobile
      profilePicture
      companyName
      sites
      status
      companydetailsID
      SiteDetails {
        nextToken
        __typename
      }
      permission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetOwner = /* GraphQL */ `
  mutation UpdateAssetOwner(
    $input: UpdateAssetOwnerInput!
    $condition: ModelAssetOwnerConditionInput
  ) {
    updateAssetOwner(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      mobile
      profilePicture
      companyName
      sites
      status
      companydetailsID
      SiteDetails {
        nextToken
        __typename
      }
      permission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetOwner = /* GraphQL */ `
  mutation DeleteAssetOwner(
    $input: DeleteAssetOwnerInput!
    $condition: ModelAssetOwnerConditionInput
  ) {
    deleteAssetOwner(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      mobile
      profilePicture
      companyName
      sites
      status
      companydetailsID
      SiteDetails {
        nextToken
        __typename
      }
      permission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSiteDetails = /* GraphQL */ `
  mutation CreateSiteDetails(
    $input: CreateSiteDetailsInput!
    $condition: ModelSiteDetailsConditionInput
  ) {
    createSiteDetails(input: $input, condition: $condition) {
      id
      unitNumber
      street
      suburb
      state
      postCode
      country
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      DistributionBoxes {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      companydetailsID
      assetownerID
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      LogBooks {
        nextToken
        __typename
      }
      siteName
      Assets {
        nextToken
        __typename
      }
      Tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSiteDetails = /* GraphQL */ `
  mutation UpdateSiteDetails(
    $input: UpdateSiteDetailsInput!
    $condition: ModelSiteDetailsConditionInput
  ) {
    updateSiteDetails(input: $input, condition: $condition) {
      id
      unitNumber
      street
      suburb
      state
      postCode
      country
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      DistributionBoxes {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      companydetailsID
      assetownerID
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      LogBooks {
        nextToken
        __typename
      }
      siteName
      Assets {
        nextToken
        __typename
      }
      Tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSiteDetails = /* GraphQL */ `
  mutation DeleteSiteDetails(
    $input: DeleteSiteDetailsInput!
    $condition: ModelSiteDetailsConditionInput
  ) {
    deleteSiteDetails(input: $input, condition: $condition) {
      id
      unitNumber
      street
      suburb
      state
      postCode
      country
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      DistributionBoxes {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      companydetailsID
      assetownerID
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      LogBooks {
        nextToken
        __typename
      }
      siteName
      Assets {
        nextToken
        __typename
      }
      Tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
