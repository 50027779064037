import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Btn } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardFooter,
  Col,
  Card,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Amplify, API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Tab, Tabs } from "react-bootstrap";
import { PlusCircle, Download } from "react-feather";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import DatePicker from "react-datepicker";
import { FormGroup } from "reactstrap";
import { SiteContext } from "../../App";
import { aocontext } from "../../App";
import { useContext } from "react";
import AWS from "aws-sdk";
import Select from "react-select";
import * as mergedQueries from "../../graphql/mergedQueries";
import {
  ACCESSKEYID,
  SECRETACCESSKEY,
  limit,
  EMAIL_API,
} from "../../Config/Config";
import moment from "moment";
import SPCompany from "../../Data/SPCompanyData/index";
import SiteData from "../../Data/Sites/index";
import CategoryData from "../../Data/Category/index";
import AssetTemplate from "../../Data/AssetTemplate/index";
import SiteDBData from "../../Data/DistributionBoard/index";
import axios from "axios";
var base64 = require("base-64");

Amplify.configure(awsExports);

const TasksContainSp = () => {
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const navigate = useNavigate();
  const state = useLocation();
  console.log(state);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [sPUser, setSPUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const spaoUserCheckForSUInvite = localStorage.getItem(
    "spaoUserCheckForSUInvite"
  );
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const LoggedInUserId =
    LoginUserGroup === "site_user" && spaoUserCheckForSUInvite === "spao"
      ? LoginUserDetails.assetOwnerId
      : LoginUserDetails.id;
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [errorMessage, setErrorMessage] = useState("");
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage1 = 10;
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage4, setCurrentPage4] = useState(1);
  const countPerPage2 = 10;
  const [currentPage2, setCurrentPage2] = useState(1);
  const [tasksDemo, setTasksDemo] = useState([]);
  const [tasksCompleted, setTasksCompleted] = useState([]);
  const [tasksCompletedDemo, setTasksCompletedDemo] = useState([]);
  const [tasks, setTasks] = useState([]);
  const LargeModaltoggle = () =>
    navigate(`${process.env.PUBLIC_URL}/tasksao/addtasks`, {
      state: { menu: "sp" },
    });
  const [sPUserName, setSPUserName] = useState([]);
  const [sPUserName1, setSPUserName1] = useState([]);
  const [sPUserName2, setSPUserName2] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [sPUserNamename, setSPUserNamename] = useState([]);
  const [userName, setUserName] = useState([]);
  const [assetsDropdown, setAssetsDropDown] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAssetNew, setSelectedAssetNew] = useState("");
  const [tasksExport, setTasksExport] = useState([]);
  const [tasksdemo1, setTasksdemo1] = useState([]);
  const [assetOwner, setAOwner] = useState([]);
  const [assetsView, setAssetsView] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [assetTemplateData, setAssetTemplateData] = useState(
    AssetTemplate.getAssetTemplate()
  );

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  const [passTest, setPassTest] = useState([]);
  const [passTestDemo, setPassTestDemo] = useState([]);
  const [passAssets, setPassAssets] = useState([]);
  const { differenceInMonths, parseISO } = require("date-fns");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState([]);
  const [isCheckboxCheckedOpen, setIsCheckboxCheckedOpen] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsOpen, setSelectedItemsOpen] = useState([]);
  const [assettemplate, setAssetTemplate] = useState(
    AssetTemplate.getAssetTemplate()
  );
  const [sites, setSites] = useState([]);
  const [testView, setTestView] = useState([]);
  const [logbook, setLogbook] = useState([]);
  const [assetsPassTest, setAssetsPassTest] = useState([]);
  const [spCompany, setSpCompany] = useState([]);
  const [compID, setCompId] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [distributionBoxList, setDistributionBoxList] = useState(
    SiteDBData.getSiteDBData()
  );
  const [spcompanyname, setspcompanyname] = useState([]);
  const [taskExportCompleted, setTaskExportCompleted] = useState([]);
  const [tasksExportHold, setTaskExportHold] = useState([]);
  const [tasksExportNonAssigned, settasksExportNonAssigned] = useState([]);
  const [nonAssignedcatFilter, setNonAssignedCatFilter] = useState([]);
  const [spLoginComp, setSpLogincomp] = useState([]);
  const [spId, setspId] = useState([]);
  const [sPUserName3, setSPUserName3] = useState([]);
  const [opentaskFilter, setOpentaskFilter] = useState([]);
  const [completedtaskFilter, setCompletedtaskFilter] = useState([]);
  const [holdtaskFilter, setHoldtaskFilter] = useState([]);
  const [taskFilterAssetStatus, setTaskFilterAssetStatus] = useState([]);
  const [taskCompletedFilterAssetStatus, settaskCompletedFilterAssetStatus] =
    useState([]);
  const [taskHoldFilterAssetStatus, settaskHoldFilterAssetStatus] = useState(
    []
  );
  const [nonAssignedFilter, setNonAssignedFilter] = useState([]);
  const [holdtasksdemo, setHoldTasksDemo] = useState([]);
  const [holdTasks, setHoldTasks] = useState([]);
  const [siteassets, setSiteAssets] = useState([]);
  const [openTaskSpUser, setOpenTaskSpUser] = useState([]);
  const [sitedetails, setSiteDetails] = useState([]);
  const [dateRangeCustom, setDateRangeCustom] = useState([null, null]);
  const [startDateCustom, endDateCustom] = dateRangeCustom;
  const [isAssignLoad, setIsAssignLoad] = useState(false);

  const handleEditHold = useCallback(
    (row) => navigate(`/addtasks/${row.id}`, { state: { purpose: "hold" } }),
    []
  );
  const handleEdit = useCallback((row) => navigate(`/addtasks/${row.id}`), []);

  useEffect(() => {
    console.log(LoginUserGroup);
    //fetchSPLogin();
    fetchTestingDemotest();
    fetchSPLoginCompany();
    fetchSPUderDetails();

    //fetchTest();
    fetchTasksSPLogin("All Time");
    //fetchCompletedTasksSPLogin("All Time");
    fetchTestingtest("All Time");
    //fetchTasksHoldSPLogin("All Time");
    fetchSPUser();
    fetchUserDetails();
    fetchLogbook();
    fetchAssetsPassTest();
    if (localStorage.getItem("spuser") === null) {
      fetchSpuserSP(LoginUserDetails.id);
    }
    if (localStorage.getItem("site") === null && sites.length === 0) {
      fetchSiteDetailsAO();
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSites(activeSites.filter((item) => item.status === "Active"));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
    }
    fetchAssetsForAssign();
    if (localStorage.getItem("spCompany") === null && spCompany.length === 0) {
      fetchSPCompanySU(LoggedInUserId);
    } else {
      setSpCompany(
        JSON.parse(base64.decode(localStorage.getItem("spCompany")))
      );
    }
    if (assetTemplateData.length === 0) {
      fetchAssetTemplate();
    }
    if (categoryDetails.length === 0) {
      fetchCategory();
    }
    return () => API.cancel();
  }, [selectedSiteGlobal, selectedAssetOwnerGlobal]);

  useEffect(() => {
    if (sites.length > 0) {
      fetchAssetsForAssign();
    }
  }, [sites]);

  //---spuser sp
  async function fetchSpuserSP(assetOwnerId) {
    //--
    try {
      let nextToken = "";
      let sites = [];
      const result = await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          limit: limit,
        },
      });
      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        sites.push(assetDatas);
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: assetOwnerId,
              limit: limit,
              nextToken: nextToken,
            },
          });
          nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergesites = sites.flat(1);
          // let sitesforUsers = [];
          // const promises = mergesites.map(async (item) => {
          //   return await API.graphql({
          //     query: queries.getSiteDetails,
          //     variables: {
          //       id: item.sitedetailsID,
          //     },
          //     authMode: "API_KEY",
          //   });
          // });

          let userIds = [];
          let nextToken1 = "";
          console.log(mergesites);
          const filterSites = mergesites.filter(
            (item) => item.id === selectedSiteGlobal
          );
          const filtersitesss = selectedSiteGlobal ? filterSites : mergesites;
          if (filtersitesss.length > 0) {
            for (let site of filtersitesss) {
              const getSiteIds = await API.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.sitedetailsID,
                },
              });
              console.log(getSiteIds);
              if (
                getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                );
                while (nextToken1 !== null) {
                  const getSiteIds1 = await API.graphql({
                    query: queries.linkUsersAndSitesBySitedetailsID,
                    authMode: "API_KEY",
                    variables: {
                      sitedetailsID: site.sitedetailsID,
                      nextToken: nextToken1,
                    },
                  });
                  console.log(getSiteIds1);
                  if (
                    getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                      .length > 0
                  ) {
                    nextToken1 =
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID
                        .nextToken;
                    userIds.push(
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                    );
                  }
                }
                console.log(userIds);
                if (userIds.length > 0) {
                  const mergeUserIds = userIds.flat(1);
                  const response1 = await API.graphql({
                    query: queries.listSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      // filter: {
                      //   userGroup: {
                      //     eq: "electrician",
                      //   },
                      // },
                      limit: 200000,
                    },
                  });
                  console.log(response1);
                  if (response1.data.listSPUserDetails.items.length > 0) {
                    const spDetails2 =
                      response1.data.listSPUserDetails.items.filter((item) => {
                        const _matchSites = mergeUserIds?.find(
                          (user) => user.userdetailsID === item?.id
                        );
                        if (_matchSites) {
                          return item;
                        }
                      });
                    console.log(spDetails2);
                    const sortedItems = spDetails2.sort((a, b) => {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    console.log(sortedItems);
                    localStorage.setItem(
                      "spuser",
                      base64.encode(JSON.stringify(sortedItems))
                    );
                  }
                } else {
                }
              }
            }
          } else {
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAssetsForAssign() {
    try {
      let nextToken = "";
      let assets = [];
      for (let assetTemplate of sites) {
        const result = await API.graphql({
          query: queries.assetsBySitedetailsID,
          variables: {
            sitedetailsID: assetTemplate.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.assetsBySitedetailsID.items;
          nextToken = result.data.assetsBySitedetailsID.nextToken;
          if (result.data.assetsBySitedetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: assetTemplate.id,
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.assetsBySitedetailsID.items.length > 0) {
              nextToken = results.data.assetsBySitedetailsID.nextToken;
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setAssetsList(mergeAssets);
          }
        }
        return () => API.cancel(result);
      }
    } catch (error) {
      console.log(error);
      setAssetsList([]);
    }
  }

  // testing data for view and edit
  async function fetchTestingDemotest() {
    try {
      //---
      let nextToken = "";
      let assets = [];
      for (let assetTemplate of sitedetails) {
        const result = await API.graphql({
          query: queries.testingsBySitedetailsID,
          variables: {
            sitedetailsID: assetTemplate.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.testingsBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.testingsBySitedetailsID.items;
          nextToken = result.data.testingsBySitedetailsID.nextToken;
          console.log(
            "first token count",
            result.data.testingsBySitedetailsID.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.testingsBySitedetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.testingsBySitedetailsID,
              variables: {
                sitedetailsID: assetTemplate.id,
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.testingsBySitedetailsID.items.length > 0) {
              nextToken = results.data.testingsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.testingsBySitedetailsID.items.length);
              if (results.data.testingsBySitedetailsID.items.length > 0) {
                assets.push(results.data.testingsBySitedetailsID.items);
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setTestView(mergeAssets);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setTestView([]);
    }
    // try {
    //   let nextToken = "";
    //   let assets = [];
    //   const result = await API.graphql({
    //     query: queries.listTestings,
    //     variables: {
    //       limit: limit,
    //     },
    //     authMode: "API_KEY",
    //   });
    //   if (result.data.listTestings.items.length > 0) {
    //     let assetDatas = result.data.listTestings.items;
    //     nextToken = result.data.listTestings.nextToken;
    //     if (result.data.listTestings.items.length > 0) {
    //       assets.push(assetDatas);
    //     }
    //     while (nextToken !== null) {
    //       const results = await API.graphql({
    //         query: queries.listTestings,
    //         variables: {
    //           limit: limit,
    //           nextToken: nextToken,
    //         },
    //         authMode: "API_KEY",
    //       });
    //       if (results.data.listTestings.items.length > 0) {
    //         nextToken = results.data.listTestings.nextToken;
    //         if (results.data.listTestings.items.length > 0) {
    //           assets.push(results.data.listTestings.items);
    //         }
    //       }
    //     }
    //     if (assets.length > 0) {
    //       const mergeAssets = assets.flat(1);
    //       console.log(mergeAssets);
    //       setTestView(mergeAssets);
    //     } else {
    //       setTestView([]);
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  }

  // ---Pass Testing ----non assigned tasks
  async function fetchTestingtest(DateRange) {
    let tasksOpen = [];
    let nextToken = "";
    let assets = [];
    let testReport2 = [];
    let testReporttest = [];
    //setIsLoading(true);
    const result = await API.graphql({
      query: queries.listAssetTemplates,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    const assetTemplate = result.data.listAssetTemplates.items;
    console.log(sites);
    if (sites.length > 0) {
      for (let site of sites) {
        const result = await API.graphql({
          query: queries.testingsBySitedetailsID,
          variables: {
            sitedetailsID: site.id,
            filter: {
              testResult: {
                eq: "Pass",
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.testingsBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.testingsBySitedetailsID.items;
          nextToken = result.data.testingsBySitedetailsID.nextToken;
          console.log(
            "first token count",
            result.data.testingsBySitedetailsID.items.length
          );
          console.log("nextToken 1", nextToken);
          assets.push(assetDatas);
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.testingsBySitedetailsID,
              variables: {
                sitedetailsID: site.id,
                filter: {
                  testResult: {
                    eq: "Pass",
                  },
                },
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.testingsBySitedetailsID.items.length > 0) {
              nextToken = results.data.testingsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.testingsBySitedetailsID.items.length);
              if (results.data.testingsBySitedetailsID.items.length > 0) {
                assets.push(results.data.testingsBySitedetailsID.items);
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            const filteredItems = mergeAssets.filter((item) => {
              const testOnDate = moment(item.testOn).format("YYYY-MM-DD");
              const nextTestScheduleDate = moment(item.nextTestSchedule).format(
                "YYYY-MM-DD"
              );
              const monthsDifference = differenceInMonths(
                parseISO(nextTestScheduleDate),
                parseISO(testOnDate)
              );
              return monthsDifference <= 6;
            });
            console.log(filteredItems);

            if (filteredItems.length > 0) {
              await Promise.all(
                filteredItems.map(async (test) => {
                  //console.log(assetsss);
                  //console.log(test.assetsID);
                  const site = testReport2.filter(
                    (item) => item.id === test.assetsID
                  );
                  console.log(site);
                  // const sitess = asset.filter(
                  //   (item) => item.id === test.logbookID
                  // );
                  // console.log(sitess);
                  const catId =
                    site &&
                    site[0] &&
                    assetTemplate.filter(
                      (item) => item.id === site[0].assettemplateID
                    );
                  console.log(catId);
                  //const categorydata = catId && catId[0] && await getcategory(catId);
                  const assetdata = await getAssets1(test.assetsID);

                  testReporttest.push({
                    id: test.id,
                    assetsID: assetdata,
                    assetsId: test.assetsID,
                    createdAt: test.createdAt,
                    siteId: test.siteId,
                    categoriesID: catId && catId[0] && catId[0].categoriesID,
                    testOn: test.testOn,
                    testResult: test.testResult,
                    nextTest: test.nextTestSchedule,
                    customfields: test.customfields,
                    additionalInformation: test.additionalInformation,
                  });
                })
              );
              //--
              const sortedItems = testReporttest.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              console.log(sortedItems);

              const tasksNew = sortedItems;
              if (DateRange === "This Week") {
                tasksOpen = tasksNew.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return (
                    createdAtDate.isSameOrAfter(
                      moment(startOfWeek, "DD/MM/YYYY"),
                      "day"
                    ) &&
                    createdAtDate.isSameOrBefore(
                      moment(endOfWeek, "DD/MM/YYYY"),
                      "day"
                    )
                  );
                });
              } else if (DateRange === "This Month") {
                tasksOpen = tasksNew.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startOfMonth, "DD/MM/YYYY"),
                    moment(endOfMonth, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "Today") {
                tasksOpen = tasksNew.filter(
                  (item) =>
                    moment(item.createdAt).format("DD/MM/YYYY") ===
                    moment(new Date()).format("DD/MM/YYYY")
                );
              } else if (DateRange === "This Year") {
                tasksOpen = tasksNew.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startOfThisYear, "DD/MM/YYYY"),
                    moment(endOfThisYear, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "Custom") {
                tasksOpen = tasksNew.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startDate, "DD/MM/YYYY"),
                    moment(endDate, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "All Time") {
                tasksOpen = tasksNew;
              }
              settasksExportNonAssigned(tasksOpen);
              setPassTestDemo(tasksOpen);
              setNonAssignedCatFilter(tasksOpen);
              setPassTest(cloneDeep(tasksOpen.slice(0, countPerPage)));
              setIsLoading(false);
            }
            //--
          } else {
            setIsLoading(false);
          }
        }
      }
    } else {
      if (LoginUserGroup === "site_owner" || LoginUserGroup === "spao") {
        try {
          //setIsLoading(true);
          let nextToken = "";
          let sites = [];
          const result = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
            },
            authMode: "API_KEY",
          });
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            let assetDatas = result.data.siteDetailsByAssetownerID.items;
            nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            sites.push(assetDatas);
            while (nextToken !== null) {
              const results = await API.graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: 200000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;
              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                sites.push(results.data.siteDetailsByAssetownerID.items);
              }
            }
            console.log(sites);
            if (sites.length > 0) {
              const mergeSites = sites.flat(1);
              const activesites = mergeSites.filter(
                (item) => item.status === "Active"
              );
              //---
              for (let site of activesites) {
                const result = await API.graphql({
                  query: queries.testingsBySitedetailsID,
                  variables: {
                    sitedetailsID: site.id,
                    filter: {
                      testResult: {
                        eq: "Pass",
                      },
                    },
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (result.data.testingsBySitedetailsID.items.length > 0) {
                  let assetDatas = result.data.testingsBySitedetailsID.items;
                  nextToken = result.data.testingsBySitedetailsID.nextToken;
                  console.log(
                    "first token count",
                    result.data.testingsBySitedetailsID.items.length
                  );
                  console.log("nextToken 1", nextToken);
                  assets.push(assetDatas);
                  while (nextToken !== null) {
                    const results = await API.graphql({
                      query: queries.testingsBySitedetailsID,
                      variables: {
                        sitedetailsID: site.id,
                        filter: {
                          testResult: {
                            eq: "Pass",
                          },
                        },
                        limit: limit,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    });
                    if (results.data.testingsBySitedetailsID.items.length > 0) {
                      nextToken =
                        results.data.testingsBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(
                        results.data.testingsBySitedetailsID.items.length
                      );
                      if (
                        results.data.testingsBySitedetailsID.items.length > 0
                      ) {
                        assets.push(results.data.testingsBySitedetailsID.items);
                      }
                    }
                  }
                  console.log(assets);
                  if (assets.length > 0) {
                    const mergeAssets = assets.flat(1);
                    console.log(mergeAssets);
                    const filteredItems = mergeAssets.filter((item) => {
                      const testOnDate = moment(item.testOn).format(
                        "YYYY-MM-DD"
                      );
                      const nextTestScheduleDate = moment(
                        item.nextTestSchedule
                      ).format("YYYY-MM-DD");
                      const monthsDifference = differenceInMonths(
                        parseISO(nextTestScheduleDate),
                        parseISO(testOnDate)
                      );
                      return monthsDifference <= 6;
                    });
                    console.log(filteredItems);

                    if (filteredItems.length > 0) {
                      await Promise.all(
                        filteredItems.map(async (test) => {
                          //console.log(assetsss);
                          //console.log(test.assetsID);
                          const site = testReport2.filter(
                            (item) => item.id === test.assetsID
                          );
                          console.log(site);
                          // const sitess = asset.filter(
                          //   (item) => item.id === test.logbookID
                          // );
                          // console.log(sitess);
                          const catId =
                            site &&
                            site[0] &&
                            assetTemplate.filter(
                              (item) => item.id === site[0].assettemplateID
                            );
                          console.log(catId);
                          //const categorydata = catId && catId[0] && await getcategory(catId);
                          const assetdata = await getAssets1(test.assetsID);

                          testReporttest.push({
                            id: test.id,
                            assetsID: assetdata,
                            assetsId: test.assetsID,
                            createdAt: test.createdAt,
                            siteId: test.siteId,
                            categoriesID:
                              catId && catId[0] && catId[0].categoriesID,
                            testOn: test.testOn,
                            testResult: test.testResult,
                            nextTest: test.nextTestSchedule,
                            customfields: test.customfields,
                            additionalInformation: test.additionalInformation,
                          });
                        })
                      );
                      //--
                      const sortedItems = testReporttest.sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      });
                      console.log(sortedItems);

                      const tasksNew = sortedItems;
                      if (DateRange === "This Week") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return (
                            createdAtDate.isSameOrAfter(
                              moment(startOfWeek, "DD/MM/YYYY"),
                              "day"
                            ) &&
                            createdAtDate.isSameOrBefore(
                              moment(endOfWeek, "DD/MM/YYYY"),
                              "day"
                            )
                          );
                        });
                      } else if (DateRange === "This Month") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfMonth, "DD/MM/YYYY"),
                            moment(endOfMonth, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Today") {
                        tasksOpen = tasksNew.filter(
                          (item) =>
                            moment(item.createdAt).format("DD/MM/YYYY") ===
                            moment(new Date()).format("DD/MM/YYYY")
                        );
                      } else if (DateRange === "This Year") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfThisYear, "DD/MM/YYYY"),
                            moment(endOfThisYear, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Custom") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startDate, "DD/MM/YYYY"),
                            moment(endDate, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "All Time") {
                        tasksOpen = tasksNew;
                      }
                      settasksExportNonAssigned(tasksOpen);
                      setPassTestDemo(tasksOpen);
                      setNonAssignedCatFilter(tasksOpen);
                      setPassTest(cloneDeep(tasksOpen.slice(0, countPerPage)));
                      setIsLoading(false);
                    }
                    //--
                  } else {
                    setIsLoading(false);
                  }
                }
              }
            }
          } else {
            setSites([]);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          setSites([]);
          setIsLoading(false);
        }
      } else if (LoginUserGroup === "site_user") {
        console.log("su");
        try {
          //setIsLoading(true);
          let nextToken = "";
          let sites = [];
          const result = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.assetOwnerId,
              limit: 200000,
            },
            authMode: "API_KEY",
          });
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            let assetDatas = result.data.siteDetailsByAssetownerID.items;
            nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            sites.push(assetDatas);
            while (nextToken !== null) {
              const results = await API.graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.assetOwnerId,
                  limit: 200000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;
              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                sites.push(results.data.siteDetailsByAssetownerID.items);
              }
            }
            console.log(sites);
            if (sites.length > 0) {
              const mergeSites = sites.flat(1);
              const activesites = mergeSites.filter(
                (item) => item.status === "Active"
              );
              for (let site of activesites) {
                const result = await API.graphql({
                  query: queries.testingsBySitedetailsID,
                  variables: {
                    sitedetailsID: site.id,
                    filter: {
                      testResult: {
                        eq: "Pass",
                      },
                    },
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (result.data.testingsBySitedetailsID.items.length > 0) {
                  let assetDatas = result.data.testingsBySitedetailsID.items;
                  nextToken = result.data.testingsBySitedetailsID.nextToken;
                  console.log(
                    "first token count",
                    result.data.testingsBySitedetailsID.items.length
                  );
                  console.log("nextToken 1", nextToken);
                  assets.push(assetDatas);
                  while (nextToken !== null) {
                    const results = await API.graphql({
                      query: queries.testingsBySitedetailsID,
                      variables: {
                        sitedetailsID: site.id,
                        filter: {
                          testResult: {
                            eq: "Pass",
                          },
                        },
                        limit: limit,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    });
                    if (results.data.testingsBySitedetailsID.items.length > 0) {
                      nextToken =
                        results.data.testingsBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(
                        results.data.testingsBySitedetailsID.items.length
                      );
                      if (
                        results.data.testingsBySitedetailsID.items.length > 0
                      ) {
                        assets.push(results.data.testingsBySitedetailsID.items);
                      }
                    }
                  }
                  console.log(assets);
                  if (assets.length > 0) {
                    const mergeAssets = assets.flat(1);
                    console.log(mergeAssets);
                    const filteredItems = mergeAssets.filter((item) => {
                      const testOnDate = moment(item.testOn).format(
                        "YYYY-MM-DD"
                      );
                      const nextTestScheduleDate = moment(
                        item.nextTestSchedule
                      ).format("YYYY-MM-DD");
                      const monthsDifference = differenceInMonths(
                        parseISO(nextTestScheduleDate),
                        parseISO(testOnDate)
                      );
                      return monthsDifference <= 6;
                    });
                    console.log(filteredItems);

                    if (filteredItems.length > 0) {
                      await Promise.all(
                        filteredItems.map(async (test) => {
                          //console.log(assetsss);
                          //console.log(test.assetsID);
                          const site = testReport2.filter(
                            (item) => item.id === test.assetsID
                          );
                          console.log(site);
                          // const sitess = asset.filter(
                          //   (item) => item.id === test.logbookID
                          // );
                          // console.log(sitess);
                          const catId =
                            site &&
                            site[0] &&
                            assetTemplate.filter(
                              (item) => item.id === site[0].assettemplateID
                            );
                          console.log(catId);
                          //const categorydata = catId && catId[0] && await getcategory(catId);
                          const assetdata = await getAssets1(test.assetsID);

                          testReporttest.push({
                            id: test.id,
                            assetsID: assetdata,
                            assetsId: test.assetsID,
                            createdAt: test.createdAt,
                            siteId: test.siteId,
                            categoriesID:
                              catId && catId[0] && catId[0].categoriesID,
                            testOn: test.testOn,
                            testResult: test.testResult,
                            nextTest: test.nextTestSchedule,
                            customfields: test.customfields,
                            additionalInformation: test.additionalInformation,
                          });
                        })
                      );
                      //--
                      const sortedItems = testReporttest.sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      });
                      console.log(sortedItems);

                      const tasksNew = sortedItems;
                      if (DateRange === "This Week") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return (
                            createdAtDate.isSameOrAfter(
                              moment(startOfWeek, "DD/MM/YYYY"),
                              "day"
                            ) &&
                            createdAtDate.isSameOrBefore(
                              moment(endOfWeek, "DD/MM/YYYY"),
                              "day"
                            )
                          );
                        });
                      } else if (DateRange === "This Month") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfMonth, "DD/MM/YYYY"),
                            moment(endOfMonth, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Today") {
                        tasksOpen = tasksNew.filter(
                          (item) =>
                            moment(item.createdAt).format("DD/MM/YYYY") ===
                            moment(new Date()).format("DD/MM/YYYY")
                        );
                      } else if (DateRange === "This Year") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfThisYear, "DD/MM/YYYY"),
                            moment(endOfThisYear, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Custom") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startDate, "DD/MM/YYYY"),
                            moment(endDate, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "All Time") {
                        tasksOpen = tasksNew;
                      }
                      settasksExportNonAssigned(tasksOpen);
                      setPassTestDemo(tasksOpen);
                      setNonAssignedCatFilter(tasksOpen);
                      setPassTest(cloneDeep(tasksOpen.slice(0, countPerPage)));
                      setIsLoading(false);
                    }
                    //--
                  } else {
                    setIsLoading(false);
                  }
                }
              }
            }
          } else {
            setSites([]);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          setSites([]);
          setIsLoading(false);
        }
      } else {
        let nextToken = "";
        let sites = [];
        const result = await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: limit,
          },
        });
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
          nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
          sites.push(assetDatas);
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                limit: limit,
                nextToken: nextToken,
              },
            });
            nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
            if (
              results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
            ) {
              sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
            }
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergesites = sites.flat(1);
            let sitesforUsers = [];
            const promises = mergesites.map(async (item) => {
              return await API.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
                authMode: "API_KEY",
              });
            });
            Promise.all(promises).then(async (sitesData) => {
              sitesData.forEach((sites) => {
                if (sites.data.getSiteDetails !== null) {
                  sitesforUsers.push(sites.data.getSiteDetails);
                }
              });
              const activesites = sitesforUsers.filter(
                (item) => item.status === "Active"
              );
              for (let site of activesites) {
                const result = await API.graphql({
                  query: queries.testingsBySitedetailsID,
                  variables: {
                    sitedetailsID: site.id,
                    filter: {
                      testResult: {
                        eq: "Pass",
                      },
                    },
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (result.data.testingsBySitedetailsID.items.length > 0) {
                  let assetDatas = result.data.testingsBySitedetailsID.items;
                  nextToken = result.data.testingsBySitedetailsID.nextToken;
                  console.log(
                    "first token count",
                    result.data.testingsBySitedetailsID.items.length
                  );
                  console.log("nextToken 1", nextToken);
                  assets.push(assetDatas);
                  while (nextToken !== null) {
                    const results = await API.graphql({
                      query: queries.testingsBySitedetailsID,
                      variables: {
                        sitedetailsID: site.id,
                        filter: {
                          testResult: {
                            eq: "Pass",
                          },
                        },
                        limit: limit,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    });
                    if (results.data.testingsBySitedetailsID.items.length > 0) {
                      nextToken =
                        results.data.testingsBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(
                        results.data.testingsBySitedetailsID.items.length
                      );
                      if (
                        results.data.testingsBySitedetailsID.items.length > 0
                      ) {
                        assets.push(results.data.testingsBySitedetailsID.items);
                      }
                    }
                  }
                  console.log(assets);
                  if (assets.length > 0) {
                    const mergeAssets = assets.flat(1);
                    console.log(mergeAssets);
                    const filteredItems = mergeAssets.filter((item) => {
                      const testOnDate = moment(item.testOn).format(
                        "YYYY-MM-DD"
                      );
                      const nextTestScheduleDate = moment(
                        item.nextTestSchedule
                      ).format("YYYY-MM-DD");
                      const monthsDifference = differenceInMonths(
                        parseISO(nextTestScheduleDate),
                        parseISO(testOnDate)
                      );
                      return monthsDifference <= 6;
                    });
                    console.log(filteredItems);

                    if (filteredItems.length > 0) {
                      await Promise.all(
                        filteredItems.map(async (test) => {
                          //console.log(assetsss);
                          //console.log(test.assetsID);
                          const site = testReport2.filter(
                            (item) => item.id === test.assetsID
                          );
                          console.log(site);
                          // const sitess = asset.filter(
                          //   (item) => item.id === test.logbookID
                          // );
                          // console.log(sitess);
                          const catId =
                            site &&
                            site[0] &&
                            assetTemplate.filter(
                              (item) => item.id === site[0].assettemplateID
                            );
                          console.log(catId);
                          //const categorydata = catId && catId[0] && await getcategory(catId);
                          const assetdata = await getAssets1(test.assetsID);

                          testReporttest.push({
                            id: test.id,
                            assetsID: assetdata,
                            assetsId: test.assetsID,
                            createdAt: test.createdAt,
                            siteId: test.siteId,
                            categoriesID:
                              catId && catId[0] && catId[0].categoriesID,
                            testOn: test.testOn,
                            testResult: test.testResult,
                            nextTest: test.nextTestSchedule,
                            customfields: test.customfields,
                            additionalInformation: test.additionalInformation,
                          });
                        })
                      );
                      //--
                      const sortedItems = testReporttest.sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      });
                      console.log(sortedItems);

                      const tasksNew = sortedItems;
                      if (DateRange === "This Week") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return (
                            createdAtDate.isSameOrAfter(
                              moment(startOfWeek, "DD/MM/YYYY"),
                              "day"
                            ) &&
                            createdAtDate.isSameOrBefore(
                              moment(endOfWeek, "DD/MM/YYYY"),
                              "day"
                            )
                          );
                        });
                      } else if (DateRange === "This Month") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfMonth, "DD/MM/YYYY"),
                            moment(endOfMonth, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Today") {
                        tasksOpen = tasksNew.filter(
                          (item) =>
                            moment(item.createdAt).format("DD/MM/YYYY") ===
                            moment(new Date()).format("DD/MM/YYYY")
                        );
                      } else if (DateRange === "This Year") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfThisYear, "DD/MM/YYYY"),
                            moment(endOfThisYear, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Custom") {
                        tasksOpen = tasksNew.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startDate, "DD/MM/YYYY"),
                            moment(endDate, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "All Time") {
                        tasksOpen = tasksNew;
                      }
                      settasksExportNonAssigned(tasksOpen);
                      setPassTestDemo(tasksOpen);
                      setNonAssignedCatFilter(tasksOpen);
                      setPassTest(cloneDeep(tasksOpen.slice(0, countPerPage)));
                      setIsLoading(false);
                    }
                    //--
                  } else {
                    setIsLoading(false);
                  }
                }
              }
            });
          }
        }
      }
    }

    // await API.graphql({
    //   query: queries.siteDetailsByAssetownerID,
    //   variables: {
    //     assetownerID: LoginUserDetails.id,
    //     limit: limit,
    //   },
    //   authMode: "API_KEY",
    // })
    //   .then(async (sites) => {
    //     console.log(sites);
    //     let siteIds = [];
    //     const datasite = sites.data.siteDetailsByAssetownerID.items;
    //     const filtersites = datasite.filter(
    //       (item) => item.id === selectedSiteGlobal
    //     );
    //     console.log(filtersites);
    //     const filteredsites = selectedSiteGlobal ? filtersites : datasite;
    //     console.log(filteredsites);
    //     if (filteredsites.length > 0) {
    //       filteredsites.map((item) => {
    //         siteIds.push(item.id);
    //       });
    //       //---
    //       const promises = sites.data.siteDetailsByAssetownerID.items.map(
    //         async (siteee) => {
    //           return API.graphql({
    //             query: queries.assetsBySitedetailsID,
    //             variables: {
    //               sitedetailsID: siteee.id,
    //               filter: {
    //                 status: {
    //                   ne: "delete",
    //                 },
    //               },
    //               limit: limit,
    //             },
    //           });
    //         }
    //       );
    //       Promise.all(promises)
    //         .then(async (results) => {
    //           const testReport2 = [];
    //           let assetFilter = [];
    //           results.forEach((result1) => {
    //             const assetList = result1.data.assetsBySitedetailsID.items;
    //             console.log(assetList);
    //             if (assetList.length > 0) {
    //               console.log(assetList);
    //               testReport2.push(...assetList);
    //             }
    //           });
    //           console.log("final data");
    //           console.log(testReport2);

    //           //---
    //           await API.graphql({
    //             query: queries.listLogBooks,
    //             variables: {
    //               limit: limit,
    //             },
    //             authMode: "API_KEY",
    //           })
    //             .then(async (response) => {
    //               console.log(response);
    //               const asset = response.data.listLogBooks.items.filter(
    //                 (item) => {
    //                   const _matchSites = siteIds?.find(
    //                     (id) => id === item?.siteId
    //                   );
    //                   if (_matchSites) {
    //                     return item;
    //                   }
    //                 }
    //               );
    //               console.log(asset);
    //               //setPassAssets(asset);
    //               const testReport = [];
    //               if (asset.length > 0) {
    //                 //----
    //                 const promises = asset.map(async (item) => {
    //                   return API.graphql({
    //                     query: queries.testingsByLogbookID,
    //                     variables: {
    //                       logbookID: item.id,
    //                       filter: {
    //                         testResult: {
    //                           eq: "Pass",
    //                         },
    //                       },
    //                       limit: limit,
    //                     },
    //                   });
    //                 });
    //                 console.log(promises);
    //                 Promise.all(promises)
    //                   .then(async (results) => {
    //                     const testReport = [];
    //                     const testReporttest = [];
    //                     results.forEach((result1) => {
    //                       const assetList =
    //                         result1.data.testingsByLogbookID.items;
    //                       //console.log(assetList);
    //                       testReport.push(...assetList);
    //                     });
    //                     console.log("final data");
    //                     console.log(testReport);
    //                     const filteredItems = testReport.filter((item) => {
    //                       const testOnDate = moment(item.testOn).format(
    //                         "YYYY-MM-DD"
    //                       );
    //                       const nextTestScheduleDate = moment(
    //                         item.nextTestSchedule
    //                       ).format("YYYY-MM-DD");
    //                       const monthsDifference = differenceInMonths(
    //                         parseISO(nextTestScheduleDate),
    //                         parseISO(testOnDate)
    //                       );
    //                       return monthsDifference <= 6;
    //                     });
    //                     console.log(filteredItems);

    //                     if (filteredItems.length > 0) {
    //                       {
    //                         /*
    //                       filteredItems.map((test) => {
    //                         console.log(assetsss);
    //                         console.log(test.assetsID);
    //                         const site = assetsss.filter(
    //                           (item) => item.id === test.assetsID
    //                         );
    //                         console.log(site);
    //                         const sitess = asset.filter(
    //                           (item) => item.id === test.logbookID
    //                         );
    //                         console.log(sitess);
    //                         const catId =
    //                           site &&
    //                           site[0] &&
    //                           assetTemplate.filter(
    //                             (item) => item.id === site[0].assettemplateID
    //                           );
    //                         console.log(catId);
    //                         testReporttest.push({
    //                           id: test.id,
    //                           assetsID: test?.assetsID,
    //                           createdAt: test.createdAt,
    //                           siteId: sitess && sitess[0] && sitess[0].siteId,
    //                           categoriesID:
    //                             catId && catId[0] && catId[0].categoriesID,
    //                           testOn: test.testOn,
    //                           testResult: test.testResult,
    //                           nextTest: test.nextTestSchedule,
    //                           customfields: test.customfields,
    //                           additionalInformation: test.additionalInformation,
    //                         });
    //                       });
    //                     */
    //                       }
    //                       //---
    // await Promise.all(
    //   filteredItems.map(async (test) => {
    //     //console.log(assetsss);
    //     //console.log(test.assetsID);
    //     const site = testReport2.filter(
    //       (item) => item.id === test.assetsID
    //     );
    //     console.log(site);
    //     const sitess = asset.filter(
    //       (item) => item.id === test.logbookID
    //     );
    //     console.log(sitess);
    //     const catId =
    //       site &&
    //       site[0] &&
    //       assetTemplate.filter(
    //         (item) => item.id === site[0].assettemplateID
    //       );
    //     console.log(catId);
    //     //const categorydata = catId && catId[0] && await getcategory(catId);
    //     const assetdata = await getAssets1(test.assetsID);

    //     testReporttest.push({
    //       id: test.id,
    //       assetsID: assetdata,
    //       assetsId: test.assetsID,
    //       createdAt: test.createdAt,
    //       siteId: sitess && sitess[0] && sitess[0].siteId,
    //       categoriesID:
    //         catId && catId[0] && catId[0].categoriesID,
    //       testOn: test.testOn,
    //       testResult: test.testResult,
    //       nextTest: test.nextTestSchedule,
    //       customfields: test.customfields,
    //       additionalInformation:
    //         test.additionalInformation,
    //     });
    //   })
    // );
    // //--
    // const sortedItems = testReporttest.sort((a, b) => {
    //   return (
    //     new Date(b.createdAt) - new Date(a.createdAt)
    //   );
    // });
    // console.log(sortedItems);
    //                       if (DateRange === "This Week") {
    //                         {
    //                           /*
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                             startOfWeek &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                             endOfWeek
    //                         );
    //                         */
    //                         }
    //                         tasksCompleted = sortedItems.filter((item) => {
    //                           const createdAtDate = moment(item.createdAt);
    //                           return (
    //                             createdAtDate.isSameOrAfter(
    //                               moment(startOfWeek, "DD/MM/YYYY"),
    //                               "day"
    //                             ) &&
    //                             createdAtDate.isSameOrBefore(
    //                               moment(endOfWeek, "DD/MM/YYYY"),
    //                               "day"
    //                             )
    //                           );
    //                         });
    //                       } else if (DateRange === "This Month") {
    //                         {
    //                           /*
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                             startOfMonth &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                             endOfMonth
    //                         );
    //                         */
    //                         }
    //                         tasksCompleted = sortedItems.filter((item) => {
    //                           const createdAtDate = moment(item.createdAt);
    //                           return createdAtDate.isBetween(
    //                             moment(startOfMonth, "DD/MM/YYYY"),
    //                             moment(endOfMonth, "DD/MM/YYYY"),
    //                             null,
    //                             "[]"
    //                           );
    //                         });
    //                       } else if (DateRange === "Today") {
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") ===
    //                             moment(new Date()).format("DD/MM/YYYY")
    //                         );
    //                       } else if (DateRange === "This Year") {
    //                         {
    //                           /*
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                             startOfThisYear &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                             endOfThisYear
    //                         );
    //                         */
    //                         }
    //                         tasksCompleted = sortedItems.filter((item) => {
    //                           const createdAtDate = moment(item.createdAt);
    //                           return createdAtDate.isBetween(
    //                             moment(startOfThisYear, "DD/MM/YYYY"),
    //                             moment(endOfThisYear, "DD/MM/YYYY"),
    //                             null,
    //                             "[]"
    //                           );
    //                         });
    //                       } else if (DateRange === "Custom") {
    //                         {
    //                           /*
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                             moment(startDate).format("DD/MM/YYYY") &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                             moment(endDate).format("DD/MM/YYYY")
    //                         );
    //                         */
    //                         }
    //                         tasksCompleted = sortedItems.filter((item) => {
    //                           const createdAtDate = moment(item.createdAt);
    //                           return createdAtDate.isBetween(
    //                             moment(startDate, "DD/MM/YYYY"),
    //                             moment(endDate, "DD/MM/YYYY"),
    //                             null,
    //                             "[]"
    //                           );
    //                         });
    //                       } else if (DateRange === "All Time") {
    //                         tasksCompleted = sortedItems;
    //                       }
    //                       settasksExportNonAssigned(tasksCompleted);
    //                       setPassTestDemo(tasksCompleted);
    //                       console.log(testReport);
    //                       setNonAssignedCatFilter(tasksCompleted);
    //                       setPassTest(
    //                         cloneDeep(tasksCompleted.slice(0, countPerPage))
    //                       );
    //                       setIsLoading(false);
    //                     } else {
    //                       settasksExportNonAssigned([]);
    //                       setPassTestDemo([]);
    //                       console.log(testReport);
    //                       setNonAssignedCatFilter([]);
    //                       setPassTest(cloneDeep([].slice(0, countPerPage)));
    //                       setIsLoading(false);
    //                     }
    //                   })
    //                   .catch((error) => {
    //                     console.log(error);
    //                     setErrorMessage("Unable to fetch the list");
    //                     setIsLoading(false);
    //                   });

    //                 //---

    //                 {
    //                   /*
    //                 API.graphql({
    //                   query: queries.testingsByLogbookID,
    //                   variables: {
    //                     logbookID: "",
    //                     //filter: {
    //                     //  testResult: {
    //                     //    eq: "Pass",
    //                     //  },
    //                     //},
    //                     limit: 200000,
    //                   },
    //                   authMode: "API_KEY",
    //                 })
    //                   .then((response) => {
    //                     console.log(response);
    //                     const testing = response.data.testingsByLogbookID.items;
    //                     console.log(testing);
    //                     const filteredItems = testing.filter((item) => {
    //                       const testOnDate = moment(item.testOn).format(
    //                         "YYYY-MM-DD"
    //                       );
    //                       const nextTestScheduleDate = moment(
    //                         item.nextTestSchedule
    //                       ).format("DD/MM/YYYY");
    //                       const monthsDifference = differenceInMonths(
    //                         parseISO(nextTestScheduleDate),
    //                         parseISO(testOnDate)
    //                       );
    //                       return monthsDifference === 6;
    //                     });
    //                     console.log(filteredItems);

    //                     if (filteredItems.length > 0) {
    //                       filteredItems.map((test) => {
    //                         const site = asset.filter(
    //                           (item) => item.id === test.logbookID
    //                         );
    //                         //console.log(site)
    //                         const catId = assetTemplate.filter(
    //                           (item) => item.id === site[0].assettemplateID
    //                         );
    //                         testReport.push({
    //                           id: test.id,
    //                           assetsID: test?.assetsID,
    //                           createdAt: test.createdAt,
    //                           siteId: site[0].siteId,
    //                           categoriesID: catId[0].categoriesID,
    //                           testOn: test.testOn,
    //                           testResult: test.testResult,
    //                           nextTest: test.nextTestSchedule,
    //                           customfields: test.customfields,
    //                           additionalInformation: test.additionalInformation,
    //                         });
    //                       });
    //                       const sortedItems = testReport.sort((a, b) => {
    //                         return new Date(b.createdAt) - new Date(a.createdAt);
    //                       });
    //                       console.log(sortedItems);
    //                       if (DateRange === "This Week") {
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                               startOfWeek &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                               endOfWeek
    //                         );
    //                       } else if (DateRange === "This Month") {
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                               startOfMonth &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                               endOfMonth
    //                         );
    //                       } else if (DateRange === "Today") {
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") ===
    //                             moment(new Date()).format("DD/MM/YYYY")
    //                         );
    //                       } else if (DateRange === "This Year") {
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                               startOfThisYear &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                               endOfThisYear
    //                         );
    //                       } else if (DateRange === "Custom") {
    //                         tasksCompleted = sortedItems.filter(
    //                           (item) =>
    //                             moment(item.createdAt).format("DD/MM/YYYY") >=
    //                               moment(startDate).format("DD/MM/YYYY") &&
    //                             moment(item.createdAt).format("DD/MM/YYYY") <=
    //                               moment(endDate).format("DD/MM/YYYY")
    //                         );
    //                       } else if (DateRange === "All Time") {
    //                         tasksCompleted = sortedItems;
    //                       }
    //                       settasksExportNonAssigned(tasksCompleted);
    //                       setPassTestDemo(tasksCompleted);
    //                       console.log(testReport);
    //                       setNonAssignedCatFilter(tasksCompleted);
    //                       setPassTest(
    //                         cloneDeep(tasksCompleted.slice(0, countPerPage))
    //                       );
    //                     }
    //                   })
    //                   .then((error) => {
    //                     console.log(error);
    //                   });
    //                 */
    //                 }
    //               }
    //             })
    //             .catch((error) => {
    //               console.log(error);
    //               setErrorMessage("Unable to fetch the list");
    //               setIsLoading(false);
    //             });
    //           //---
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //           setErrorMessage("Unable to fetch the list");
    //           setIsLoading(false);
    //         });
    //     } else {
    //       settasksExportNonAssigned([]);
    //       setPassTestDemo([]);
    //       setNonAssignedCatFilter([]);
    //       setPassTest(cloneDeep([].slice(0, countPerPage)));
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsLoading(false);
    //   });
  }

  async function fetchSiteDetailsAO() {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoggedInUserId,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoggedInUserId,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSites(mergeSites.filter((item) => item.status === "Active"));
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSites([]);
      //setIsLoading(false);
    }
  }

  //---assettemplare for ao/sp/su
  async function fetchAssetTemplate() {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let assetTemplates = [];
      const dataDetails = await API.graphql({
        query: queries.listAssetTemplates,
        authMode: "API_KEY",
        variables: {
          limit: 200000,
        },
      });
      if (dataDetails.data.listAssetTemplates.items.length > 0) {
        let assetDatas = dataDetails.data.listAssetTemplates.items;
        nextToken = dataDetails.data.listAssetTemplates.nextToken;
        if (dataDetails.data.listAssetTemplates.items.length > 0) {
          assetTemplates.push(assetDatas);
        }
        while (nextToken !== null) {
          const dataDetailss = await API.graphql({
            query: queries.listAssetTemplates,
            variables: {
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (dataDetailss.data.listAssetTemplates.items.length > 0) {
            nextToken = dataDetailss.data.listAssetTemplates.nextToken;
            if (dataDetailss.data.listAssetTemplates.items.length > 0) {
              assetTemplates.push(dataDetailss.data.listAssetTemplates.items);
            }
          }
        }
        console.log(assetTemplates);
        if (assetTemplates.length > 0) {
          const mergeAssetTemplates = assetTemplates.flat(1);
          setAssetTemplateData(mergeAssetTemplates);
          AssetTemplate.setAssetTemplate(mergeAssetTemplates);
          localStorage.setItem(
            "assetTemp",
            base64.encode(JSON.stringify(mergeAssetTemplates))
          );
          //setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setAssetTemplateData([]);
      AssetTemplate.setAssetTemplate([]);
    }
  }

  //--category for ao/sp/su
  async function fetchCategory() {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let categories = [];
      const dataDetails = await API.graphql({
        query: queries.listCategories,
        authMode: "API_KEY",
        variables: {
          limit: 200000,
        },
      });
      if (dataDetails.data.listCategories.items.length > 0) {
        let assetDatas = dataDetails.data.listCategories.items;
        nextToken = dataDetails.data.listCategories.nextToken;
        if (dataDetails.data.listCategories.items.length > 0) {
          categories.push(assetDatas);
        }
        while (nextToken !== null) {
          const dataDetailss = await API.graphql({
            query: queries.listCategories,
            variables: {
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (dataDetailss.data.listCategories.items.length > 0) {
            nextToken = dataDetailss.data.listCategories.nextToken;
            if (dataDetailss.data.listCategories.items.length > 0) {
              categories.push(dataDetailss.data.listCategories.items);
            }
          }
        }
        console.log(categories);
        if (categories.length > 0) {
          const mergeCategories = categories.flat(1);
          setCategoryDetails(mergeCategories);
          CategoryData.setCategoryData(mergeCategories);
          localStorage.setItem(
            "category",
            base64.encode(JSON.stringify(mergeCategories))
          );
          //setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setCategoryDetails([]);
      CategoryData.setCategoryData([]);
      //setIsLoading(false);
    }
  }

  //---sp company for su/sp
  async function fetchSPCompanySU(userId) {
    try {
      //setIsLoading(true);
      const dataDetails = await API.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: userId,
          limit: 10000,
        },
      });
      if (dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        let userIds = [];
        let nextToken1 = "";
        for (let site of siteDetails) {
          const getSiteIds = await API.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: site.sitedetailsID,
            },
          });
          if (
            getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length > 0
          ) {
            nextToken1 =
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
            userIds.push(
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
            );
            while (nextToken1 !== null) {
              const getSiteIds1 = await API.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.sitedetailsID,
                  nextToken: nextToken1,
                },
              });
              if (
                getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                );
              }
            }
            if (userIds.length > 0) {
              let comp = [];
              let users = [];
              const mergeUserIds = userIds.flat(1);
              console.log(mergeUserIds);
              const processedCompanyIds = new Set();

              for (let userId of mergeUserIds) {
                const getUserIds = await API.graphql({
                  query: queries.getUserDetails,
                  authMode: "API_KEY",
                  variables: {
                    id: userId.userdetailsID,
                  },
                });

                console.log(getUserIds);

                if (getUserIds.data.getUserDetails !== null) {
                  const userDetails = getUserIds.data.getUserDetails;
                  if (
                    userDetails.companyDetailsId !== null &&
                    userDetails.companyDetailsId !== "" &&
                    !processedCompanyIds.has(userDetails.companyDetailsId)
                  ) {
                    processedCompanyIds.add(userDetails.companyDetailsId);

                    const getSPComp = await API.graphql({
                      query: queries.getSPCompanyDetails,
                      authMode: "API_KEY",
                      variables: {
                        id: userDetails.companyDetailsId,
                      },
                    });

                    console.log(getSPComp);

                    if (getSPComp.data.getSPCompanyDetails !== null) {
                      comp.push(getSPComp.data.getSPCompanyDetails);
                    }
                  }
                }
              }

              console.log(comp);

              if (comp.length > 0) {
                setSpCompany(comp);
                console.log(comp);
                SPCompany.setSPCompany(comp);
                localStorage.setItem(
                  "spCompany",
                  base64.encode(JSON.stringify(comp))
                );
                //setIsLoading(false);
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  const updatePage1 = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const data =
      selectedAssetNew !== "" || selectedStatus !== ""
        ? taskFilterAssetStatus
        : tasksDemo;
    setTasks(cloneDeep(data.slice(from, to)));
  };

  const updatePageHold = (p) => {
    setCurrentPage4(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const data =
      selectedAssetNew !== "" || selectedStatus !== ""
        ? taskHoldFilterAssetStatus
        : holdtasksdemo;
    setHoldTasks(cloneDeep(data.slice(from, to)));
  };

  const updatePage2 = (p) => {
    setCurrentPage1(p);
    const to = countPerPage1 * p;
    const from = to - countPerPage1;
    const data =
      selectedAssetNew !== "" || selectedStatus !== ""
        ? taskCompletedFilterAssetStatus
        : tasksCompletedDemo;
    setTasksCompleted(cloneDeep(data.slice(from, to)));
  };

  const updatePage3 = (p) => {
    setCurrentPage2(p);
    const to = countPerPage2 * p;
    const from = to - countPerPage2;
    const data =
      selectedAssetNew !== "" || selectedStatus !== ""
        ? nonAssignedFilter
        : passTestDemo;
    setPassTest(cloneDeep(data.slice(from, to)));
  };

  function getCategory(catid) {
    const category = categoryDetails.find((item) => item.id === catid);
    return category ? category.categoryName : "";
  }

  async function fetchTasksSPLogin(DateRange) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let tasksOpen = [];
      let tasksSPData = [];
      if (LoginUserGroup === "spao") {
        const tasksData = await API.graphql({
          query: queries.tasksBySpcompanydetailsID,
          variables: {
            spcompanydetailsID: LoginUserDetails.companyDetailsId,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        console.log("tasksData", tasksData);
        if (tasksData.data.tasksBySpcompanydetailsID.items.length > 0) {
          nextToken = tasksData.data.tasksBySpcompanydetailsID.nextToken;
          tasksSPData.push(tasksData.data.tasksBySpcompanydetailsID.items);
          while (nextToken !== null) {
            const tasksDataNextToken = await API.graphql({
              query: queries.tasksBySpcompanydetailsID,
              variables: {
                spcompanydetailsID: LoginUserDetails.companyDetailsId,
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (
              tasksDataNextToken.data.tasksBySpcompanydetailsID.items.length > 0
            ) {
              nextToken =
                tasksDataNextToken.data.tasksBySpcompanydetailsID.nextToken;
              tasksSPData.push(tasksData.data.tasksBySpcompanydetailsID.items);
            }
          }
        }
        if (tasksSPData.length > 0) {
          const mergeTasks = tasksSPData.flat(1);
          console.log(mergeTasks);
          //---
          const sortItemsnew = mergeTasks.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortItemsnew);
          const filterTasks = mergeTasks.filter((item) => {
            const siteArray = JSON.parse(item.taskCompletionLink).map(
              (link) => link.site
            );
            return siteArray.includes(selectedSiteGlobal);
          });
          const sortItems = selectedSiteGlobal ? filterTasks : sortItemsnew;
          console.log(sortItems);
          const tasksNew = sortItems;
          if (DateRange === "This Week") {
            tasksOpen = tasksNew.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return (
                createdAtDate.isSameOrAfter(
                  moment(startOfWeek, "DD/MM/YYYY"),
                  "day"
                ) &&
                createdAtDate.isSameOrBefore(
                  moment(endOfWeek, "DD/MM/YYYY"),
                  "day"
                )
              );
            });
          } else if (DateRange === "This Month") {
            tasksOpen = tasksNew.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfMonth, "DD/MM/YYYY"),
                moment(endOfMonth, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Today") {
            tasksOpen = tasksNew.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
          } else if (DateRange === "This Year") {
            tasksOpen = tasksNew.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfThisYear, "DD/MM/YYYY"),
                moment(endOfThisYear, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Custom") {
            tasksOpen = tasksNew.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startDate, "DD/MM/YYYY"),
                moment(endDate, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "All Time") {
            tasksOpen = tasksNew;
          }

          console.log(tasksOpen);
          const tasksopen = tasksOpen.filter(
            (item) => item.taskStatus !== "Completed"
          );
          setTasksdemo1(tasksopen);
          setTasks(cloneDeep(tasksopen.slice(0, countPerPage)));
          setOpentaskFilter(tasksopen);
          setTasksExport(tasksopen);
          setTasksDemo(tasksopen);
          setIsLoading(false);
          //-- hold task
          const holdTasks = tasksOpen.filter(
            (item) => item.taskStatus === "Hold"
          );
          setTaskExportHold(holdTasks);
          setHoldtaskFilter(holdTasks);
          setHoldTasksDemo(holdTasks);
          setHoldTasks(cloneDeep(holdTasks.slice(0, countPerPage)));
          //---completed task
          const taskscompleted = tasksOpen.filter(
            (item) => item.taskStatus === "Completed"
          );
          setTaskExportCompleted(taskscompleted);
          setCompletedtaskFilter(taskscompleted);
          setTasksCompleted(cloneDeep(taskscompleted.slice(0, countPerPage)));
          setTasksCompletedDemo(taskscompleted);
          //--
        } else {
          setTasksdemo1([]);
          setOpentaskFilter([]);
          setTasksExport([]);
          setTasksDemo([]);
          setTasks(cloneDeep([].slice(0, countPerPage)));
          setIsLoading(false);
          //--hold
          setTaskExportHold([]);
          setHoldtaskFilter([]);
          setHoldTasksDemo([]);
          setHoldTasks(cloneDeep([].slice(0, countPerPage)));
          //--completed
          setTaskExportCompleted([]);
          setCompletedtaskFilter([]);
          setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
          setTasksCompletedDemo([]);
        }
      } else {
        console.log(sites);
        if (sites.length > 0) {
          for (let site of sites) {
            const result = await API.graphql({
              query: queries.tasksBySitedetailsID,
              variables: {
                sitedetailsID: site.id,
                // filter: {
                //   taskStatus: {
                //     ne: "Completed",
                //   },
                // },
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (result.data.tasksBySitedetailsID.items.length > 0) {
              let assetDatas = result.data.tasksBySitedetailsID.items;
              nextToken = result.data.tasksBySitedetailsID.nextToken;
              console.log(
                "first token count",
                result.data.tasksBySitedetailsID.items.length
              );
              console.log("nextToken 1", nextToken);
              if (result.data.tasksBySitedetailsID.items.length > 0) {
                tasksSPData.push(assetDatas);
              }
              while (nextToken !== null) {
                const results = await API.graphql({
                  query: queries.tasksBySitedetailsID,
                  variables: {
                    sitedetailsID: site.id,
                    // filter: {
                    //   taskStatus: {
                    //     ne: "Completed",
                    //   },
                    // },
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
                if (results.data.tasksBySitedetailsID.items.length > 0) {
                  nextToken = results.data.tasksBySitedetailsID.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(results.data.tasksBySitedetailsID.items.length);
                  if (results.data.tasksBySitedetailsID.items.length > 0) {
                    tasksSPData.push(results.data.tasksBySitedetailsID.items);
                  }
                }
              }
              console.log(tasksSPData);
              if (tasksSPData.length > 0) {
                const mergeAssets = tasksSPData.flat(1);
                console.log(mergeAssets);
                //---
                const sortItemsnew = mergeAssets.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortItemsnew);
                const filterTasks = mergeAssets.filter((item) => {
                  const siteArray = JSON.parse(item.taskCompletionLink).map(
                    (link) => link.site
                  );
                  return siteArray.includes(selectedSiteGlobal);
                });
                const sortItems = selectedSiteGlobal
                  ? filterTasks
                  : sortItemsnew;
                console.log(sortItems);
                const tasksNew = sortItems;
                if (DateRange === "This Week") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return (
                      createdAtDate.isSameOrAfter(
                        moment(startOfWeek, "DD/MM/YYYY"),
                        "day"
                      ) &&
                      createdAtDate.isSameOrBefore(
                        moment(endOfWeek, "DD/MM/YYYY"),
                        "day"
                      )
                    );
                  });
                } else if (DateRange === "This Month") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfMonth, "DD/MM/YYYY"),
                      moment(endOfMonth, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Today") {
                  tasksOpen = tasksNew.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") ===
                      moment(new Date()).format("DD/MM/YYYY")
                  );
                } else if (DateRange === "This Year") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfThisYear, "DD/MM/YYYY"),
                      moment(endOfThisYear, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Custom") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startDate, "DD/MM/YYYY"),
                      moment(endDate, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "All Time") {
                  tasksOpen = tasksNew;
                }
                console.log(tasksOpen);
                //---open task
                const tasksopen = tasksOpen.filter(
                  (item) => item.taskStatus !== "Completed"
                );
                setTasksdemo1(tasksopen);
                setTasks(cloneDeep(tasksopen.slice(0, countPerPage)));
                setOpentaskFilter(tasksopen);
                setTasksExport(tasksopen);
                setTasksDemo(tasksopen);
                setIsLoading(false);
                //-- hold task
                const holdTasks = tasksOpen.filter(
                  (item) => item.taskStatus === "Hold"
                );
                setTaskExportHold(holdTasks);
                setHoldtaskFilter(holdTasks);
                setHoldTasksDemo(holdTasks);
                setHoldTasks(cloneDeep(holdTasks.slice(0, countPerPage)));
                //---completed task
                const taskscompleted = tasksOpen.filter(
                  (item) => item.taskStatus === "Completed"
                );
                setTaskExportCompleted(taskscompleted);
                setCompletedtaskFilter(taskscompleted);
                setTasksCompleted(
                  cloneDeep(taskscompleted.slice(0, countPerPage))
                );
                setTasksCompletedDemo(taskscompleted);
                //--
              } else {
                setTasksdemo1([]);
                setOpentaskFilter([]);
                setTasksExport([]);
                setTasksDemo([]);
                setTasks(cloneDeep([].slice(0, countPerPage)));
                setIsLoading(false);
                //---hold
                setTaskExportHold([]);
                setHoldtaskFilter([]);
                setHoldTasksDemo([]);
                setHoldTasks(cloneDeep([].slice(0, countPerPage)));
                //--completed
                setTaskExportCompleted([]);
                setCompletedtaskFilter([]);
                setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
                setTasksCompletedDemo([]);
              }
            }
          }
        } else {
          let nextToken1 = "";
          let sites = [];
          const result = await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: LoginUserDetails.id,
              limit: limit,
            },
          });
          if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
            nextToken1 = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
            sites.push(assetDatas);
            while (nextToken1 !== null) {
              const results = await API.graphql({
                query: queries.linkUsersAndSitesByUserdetailsID,
                authMode: "API_KEY",
                variables: {
                  userdetailsID: LoginUserDetails.id,
                  limit: limit,
                  nextToken: nextToken1,
                },
              });
              nextToken1 =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
              }
            }
            console.log(sites);
            if (sites.length > 0) {
              const mergesites = sites.flat(1);
              console.log(mergesites);
              //---
              for (let site of mergesites) {
                const result = await API.graphql({
                  query: queries.tasksBySitedetailsID,
                  variables: {
                    sitedetailsID: site.sitedetailsID,
                    // filter: {
                    //   taskStatus: {
                    //     ne: "Completed",
                    //   },
                    // },
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (result.data.tasksBySitedetailsID.items.length > 0) {
                  let assetDatas = result.data.tasksBySitedetailsID.items;
                  nextToken = result.data.tasksBySitedetailsID.nextToken;
                  console.log(
                    "first token count",
                    result.data.tasksBySitedetailsID.items.length
                  );
                  console.log("nextToken 1", nextToken);
                  if (result.data.tasksBySitedetailsID.items.length > 0) {
                    tasksSPData.push(assetDatas);
                  }
                  while (nextToken !== null) {
                    const results = await API.graphql({
                      query: queries.tasksBySitedetailsID,
                      variables: {
                        sitedetailsID: site.sitedetailsID,
                        // filter: {
                        //   taskStatus: {
                        //     ne: "Completed",
                        //   },
                        // },
                        limit: limit,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    });
                    if (results.data.tasksBySitedetailsID.items.length > 0) {
                      nextToken = results.data.tasksBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(
                        results.data.tasksBySitedetailsID.items.length
                      );
                      if (results.data.tasksBySitedetailsID.items.length > 0) {
                        tasksSPData.push(
                          results.data.tasksBySitedetailsID.items
                        );
                      }
                    }
                  }
                  console.log(tasksSPData);
                  if (tasksSPData.length > 0) {
                    const mergeAssets = tasksSPData.flat(1);
                    console.log(mergeAssets);
                    //---
                    const sortItemsnew = mergeAssets.sort((a, b) => {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    console.log(sortItemsnew);
                    const filterTasks = mergeAssets.filter((item) => {
                      const siteArray = JSON.parse(item.taskCompletionLink).map(
                        (link) => link.site
                      );
                      return siteArray.includes(selectedSiteGlobal);
                    });
                    const sortItems = selectedSiteGlobal
                      ? filterTasks
                      : sortItemsnew;
                    console.log(sortItems);
                    const tasksNew = sortItems;
                    if (DateRange === "This Week") {
                      tasksOpen = tasksNew.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return (
                          createdAtDate.isSameOrAfter(
                            moment(startOfWeek, "DD/MM/YYYY"),
                            "day"
                          ) &&
                          createdAtDate.isSameOrBefore(
                            moment(endOfWeek, "DD/MM/YYYY"),
                            "day"
                          )
                        );
                      });
                    } else if (DateRange === "This Month") {
                      tasksOpen = tasksNew.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return createdAtDate.isBetween(
                          moment(startOfMonth, "DD/MM/YYYY"),
                          moment(endOfMonth, "DD/MM/YYYY"),
                          null,
                          "[]"
                        );
                      });
                    } else if (DateRange === "Today") {
                      tasksOpen = tasksNew.filter(
                        (item) =>
                          moment(item.createdAt).format("DD/MM/YYYY") ===
                          moment(new Date()).format("DD/MM/YYYY")
                      );
                    } else if (DateRange === "This Year") {
                      tasksOpen = tasksNew.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return createdAtDate.isBetween(
                          moment(startOfThisYear, "DD/MM/YYYY"),
                          moment(endOfThisYear, "DD/MM/YYYY"),
                          null,
                          "[]"
                        );
                      });
                    } else if (DateRange === "Custom") {
                      tasksOpen = tasksNew.filter((item) => {
                        const createdAtDate = moment(item.createdAt);
                        return createdAtDate.isBetween(
                          moment(startDate, "DD/MM/YYYY"),
                          moment(endDate, "DD/MM/YYYY"),
                          null,
                          "[]"
                        );
                      });
                    } else if (DateRange === "All Time") {
                      tasksOpen = tasksNew;
                    }
                    console.log(tasksOpen);
                    //---open task
                    const tasksopen = tasksOpen.filter(
                      (item) => item.taskStatus !== "Completed"
                    );
                    setTasksdemo1(tasksopen);
                    setTasks(cloneDeep(tasksopen.slice(0, countPerPage)));
                    setOpentaskFilter(tasksopen);
                    setTasksExport(tasksopen);
                    setTasksDemo(tasksopen);
                    setIsLoading(false);
                    //-- hold task
                    const holdTasks = tasksOpen.filter(
                      (item) => item.taskStatus === "Hold"
                    );
                    setTaskExportHold(holdTasks);
                    setHoldtaskFilter(holdTasks);
                    setHoldTasksDemo(holdTasks);
                    setHoldTasks(cloneDeep(holdTasks.slice(0, countPerPage)));
                    //---completed task
                    const taskscompleted = tasksOpen.filter(
                      (item) => item.taskStatus === "Completed"
                    );
                    setTaskExportCompleted(taskscompleted);
                    setCompletedtaskFilter(taskscompleted);
                    setTasksCompleted(
                      cloneDeep(taskscompleted.slice(0, countPerPage))
                    );
                    setTasksCompletedDemo(taskscompleted);
                    //--
                  } else {
                    setTasksdemo1([]);
                    setOpentaskFilter([]);
                    setTasksExport([]);
                    setTasksDemo([]);
                    setTasks(cloneDeep([].slice(0, countPerPage)));
                    setIsLoading(false);
                    //---hold
                    setTaskExportHold([]);
                    setHoldtaskFilter([]);
                    setHoldTasksDemo([]);
                    setHoldTasks(cloneDeep([].slice(0, countPerPage)));
                    //--completed
                    setTaskExportCompleted([]);
                    setCompletedtaskFilter([]);
                    setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
                    setTasksCompletedDemo([]);
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    /*setIsLoading(true);
    await API.graphql({
      query: queries.listUserDetails,
      authMode: "API_KEY",
      variables: {
        filter: {
          id: {
            eq: LoggedInUserId,
          },
        },
        limit: limit,
      },
    })
      .then(async (response3) => {
        console.log(response3);
        const siteDetails = response3.data.listUserDetails.items;
        let siteIds = [];
        if (siteDetails.length > 0) {
          siteDetails.map((item) => {
            siteIds.push(item.companyDetailsId);
          });
          //---

          let nextToken = "";
          let assets = [];
          await API.graphql({
            query: queries.tasksBySpcompanydetailsID,
            variables: {
              spcompanydetailsID: siteDetails[0].companyDetailsId,
              // filter: {
              //   taskStatus: {
              //     ne: "Completed",
              //   },
              // },
              limit: limit,
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas = result.data.tasksBySpcompanydetailsID.items;
              nextToken = result.data.tasksBySpcompanydetailsID.nextToken;
              console.log(
                "first token count",
                result.data.tasksBySpcompanydetailsID.items.length
              );
              console.log("nextToken 1", nextToken);
              if (result.data.tasksBySpcompanydetailsID.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await API.graphql({
                  query: queries.tasksBySpcompanydetailsID,
                  variables: {
                    spcompanydetailsID: siteDetails[0].companyDetailsId,
                    // filter: {
                    //   taskStatus: {
                    //     ne: "Completed",
                    //   },
                    // },
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken =
                      results.data.tasksBySpcompanydetailsID.nextToken;
                    console.log("nextToken", nextToken);
                    console.log(
                      results.data.tasksBySpcompanydetailsID.items.length
                    );
                    if (
                      results.data.tasksBySpcompanydetailsID.items.length > 0
                    ) {
                      assets.push(results.data.tasksBySpcompanydetailsID.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets);
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //---
                const sortItemsnew = mergeAssets.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortItemsnew);
                const filterTasks = mergeAssets.filter((item) => {
                  const siteArray = JSON.parse(item.taskCompletionLink).map(
                    (link) => link.site
                  );
                  return siteArray.includes(selectedSiteGlobal);
                });
                const sortItems = selectedSiteGlobal
                  ? filterTasks
                  : sortItemsnew;
                console.log(sortItems);
                const tasksNew = sortItems;
                if (DateRange === "This Week") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return (
                      createdAtDate.isSameOrAfter(
                        moment(startOfWeek, "DD/MM/YYYY"),
                        "day"
                      ) &&
                      createdAtDate.isSameOrBefore(
                        moment(endOfWeek, "DD/MM/YYYY"),
                        "day"
                      )
                    );
                  });
                } else if (DateRange === "This Month") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfMonth, "DD/MM/YYYY"),
                      moment(endOfMonth, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Today") {
                  tasksOpen = tasksNew.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") ===
                      moment(new Date()).format("DD/MM/YYYY")
                  );
                } else if (DateRange === "This Year") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfThisYear, "DD/MM/YYYY"),
                      moment(endOfThisYear, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Custom") {
                  tasksOpen = tasksNew.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startDate, "DD/MM/YYYY"),
                      moment(endDate, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "All Time") {
                  tasksOpen = tasksNew;
                }

                console.log(tasksOpen);
                const tasksopen = tasksOpen.filter(
                  (item) => item.taskStatus !== "Completed"
                );
                setTasksdemo1(tasksopen);
                setTasks(cloneDeep(tasksopen.slice(0, countPerPage)));
                setOpentaskFilter(tasksopen);
                setTasksExport(tasksopen);
                setTasksDemo(tasksopen);
                setIsLoading(false);
                //-- hold task
                const holdTasks = tasksOpen.filter(
                  (item) => item.taskStatus === "Hold"
                );
                setTaskExportHold(holdTasks);
                setHoldtaskFilter(holdTasks);
                setHoldTasksDemo(holdTasks);
                setHoldTasks(cloneDeep(holdTasks.slice(0, countPerPage)));
                //---completed task
                const taskscompleted = tasksOpen.filter(
                  (item) => item.taskStatus === "Completed"
                );
                setTaskExportCompleted(taskscompleted);
                setCompletedtaskFilter(taskscompleted);
                setTasksCompleted(
                  cloneDeep(taskscompleted.slice(0, countPerPage))
                );
                setTasksCompletedDemo(taskscompleted);
                //--
              } else {
                setTasksdemo1([]);
                setOpentaskFilter([]);
                setTasksExport([]);
                setTasksDemo([]);
                setTasks(cloneDeep([].slice(0, countPerPage)));
                setIsLoading(false);
                //--hold
                setTaskExportHold([]);
                setHoldtaskFilter([]);
                setHoldTasksDemo([]);
                setHoldTasks(cloneDeep([].slice(0, countPerPage)));
                //--completed
                setTaskExportCompleted([]);
                setCompletedtaskFilter([]);
                setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
                setTasksCompletedDemo([]);
              }
            })
            .catch((error) => {
              console.log(error);
              setTasksdemo1([]);
              setOpentaskFilter([]);
              setTasksExport([]);
              setTasksDemo([]);
              setTasks(cloneDeep([].slice(0, countPerPage)));
              setIsLoading(false);
              //--hold
              setTaskExportHold([]);
              setHoldtaskFilter([]);
              setHoldTasksDemo([]);
              setHoldTasks(cloneDeep([].slice(0, countPerPage)));
              //--completed
              setTaskExportCompleted([]);
              setCompletedtaskFilter([]);
              setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
              setTasksCompletedDemo([]);
            });
        } else {
          setTasksdemo1([]);
          setOpentaskFilter([]);
          setTasksExport([]);
          setTasksDemo([]);
          setTasks(cloneDeep([].slice(0, countPerPage)));
          setIsLoading(false);
          //--hold
          setTaskExportHold([]);
          setHoldtaskFilter([]);
          setHoldTasksDemo([]);
          setHoldTasks(cloneDeep([].slice(0, countPerPage)));
          //--completed
          setTaskExportCompleted([]);
          setCompletedtaskFilter([]);
          setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
          setTasksCompletedDemo([]);
        }
      })
      .catch((error4) => {
        console.log(error4);
        setTasksdemo1([]);
        setOpentaskFilter([]);
        setTasksExport([]);
        setTasksDemo([]);
        setTasks(cloneDeep([].slice(0, countPerPage)));
        setIsLoading(false);
        //--hold
        setTaskExportHold([]);
        setHoldtaskFilter([]);
        setHoldTasksDemo([]);
        setHoldTasks(cloneDeep([].slice(0, countPerPage)));
        //--completed
        setTaskExportCompleted([]);
        setCompletedtaskFilter([]);
        setTasksCompleted(cloneDeep([].slice(0, countPerPage)));
        setTasksCompletedDemo([]);
      });*/
  }

  //---Task Assignee Name---
  async function fetchSPUser() {
    //--
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.listSPUserDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSPUserDetails.items.length > 0) {
      let assetDatas = result.data.listSPUserDetails.items;
      nextToken = result.data.listSPUserDetails.nextToken;
      console.log(
        "first token count",
        result.data.listSPUserDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listSPUserDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.listSPUserDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listSPUserDetails.items.length > 0) {
          nextToken = results.data.listSPUserDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listSPUserDetails.items.length);
          if (results.data.listSPUserDetails.items.length > 0) {
            assets.push(results.data.listSPUserDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        setSPUserNamename(mergeAssets);
      } else {
        setSPUserNamename([]);
      }
    }
  }

  function getTaskAssigneeName(sPId) {
    const TaskAssigneeName = sPUserNamename.find((item) => item.id === sPId);

    return TaskAssigneeName ? TaskAssigneeName.email : "";
  }

  function getTaskAssigneeNameemail(sPId) {
    const TaskAssigneeName = sPUserNamename.find((item) => item.id === sPId);

    return TaskAssigneeName ? TaskAssigneeName.email : "";
  }

  //---Task Assigned By----
  async function fetchUserDetails() {
    const response = await API.graphql({
      query: queries.listAssetOwners,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (response.data.listAssetOwners.items.length > 0) {
      setUserName(response.data.listAssetOwners.items);
      setAOwner(response.data.listAssetOwners.items);
      console.log(response.data.listAssetOwners.items);
    }
  }

  function getTaskAssigneeBy(uId) {
    const TaskAssigneeByName = userName.find((item) => item.id === uId);
    if (TaskAssigneeByName) {
      return TaskAssigneeByName
        ? TaskAssigneeByName.firstName + " " + TaskAssigneeByName.lastName
        : "";
    } else {
      const TaskAssigneeName = sPUserNamename.find((item) => item.id === uId);
      return TaskAssigneeName
        ? TaskAssigneeName.fname +
            " " +
            (TaskAssigneeName.lname ? TaskAssigneeName.lname : " ")
        : "";
    }
  }

  // logbook ----
  async function fetchLogbook() {
    let nextToken = "";
    let assets = [];
    const result = await API.graphql({
      query: queries.listLogBooks,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listLogBooks.items.length > 0) {
      let assetDatas = result.data.listLogBooks.items;
      nextToken = result.data.listLogBooks.nextToken;
      console.log("first token count", result.data.listLogBooks.items.length);
      console.log("nextToken 1", nextToken);
      if (result.data.listLogBooks.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await API.graphql({
          query: queries.listLogBooks,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listLogBooks.items.length > 0) {
          nextToken = results.data.listLogBooks.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listLogBooks.items.length);
          if (results.data.listLogBooks.items.length > 0) {
            assets.push(results.data.listLogBooks.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //--
        setLogbook(mergeAssets);
      } else {
        setLogbook([]);
      }
    }
  }

  function getlogbookname(logbookId) {
    const assetNew = logbook.find((item) => item.id === logbookId);
    return assetNew ? assetNew.logbookName : "";
  }

  function getSiteUnitNumber(siteid) {
    const unitNumber = sitedetails.find((item) => item.id === siteid);
    return unitNumber
      ? unitNumber.siteName + "(" + unitNumber.unitNumber + ")"
      : "";
  }

  function getDBName(dbId) {
    const assetNew = distributionBoxList.find((item) => item.id === dbId);
    return assetNew ? assetNew.distributionBoxName : "";
  }

  async function fetchAssetsPassTest() {
    if (LoginUserGroup === "electrician") {
      // let nextToken = "";
      // let assets = [];
      // const result = await API.graphql({
      //   query: queries.linkUsersAndSitesByUserdetailsID,
      //   variables: {
      //     userdetailsID: LoggedInUserId,
      //     limit: limit,
      //   },
      //   authMode: "API_KEY",
      // });
      // if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      //     let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      //     nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
      //     console.log(
      //       "first token count",
      //       result.data.linkUsersAndSitesByUserdetailsID.items.length
      //     );
      //     console.log("nextToken 1", nextToken);
      //     if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      //       assets.push(assetDatas);
      //     }
      //     while (nextToken !== null) {
      //       await API.graphql({
      //         query: queries.linkUsersAndSitesByUserdetailsID,
      //         variables: {
      //           userdetailsID: LoggedInUserId,
      //           limit: limit,
      //           nextToken: nextToken,
      //         },
      //         authMode: "API_KEY",
      //       })
      //         .then((results) => {
      //           nextToken =
      //             results.data.linkUsersAndSitesByUserdetailsID.nextToken;
      //           console.log("nextToken", nextToken);
      //           console.log(
      //             results.data.linkUsersAndSitesByUserdetailsID.items.length
      //           );
      //           if (
      //             results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
      //           ) {
      //             assets.push(
      //               results.data.linkUsersAndSitesByUserdetailsID.items
      //             );
      //           }
      //         })
      //         .catch((error) => {
      //           console.log(error);
      //         });
      //     }
      //     console.log(assets);
      //     if (assets.length > 0) {
      //       const mergeAssets = assets.flat(1);
      //       console.log(mergeAssets);
      //       const siteDetails = mergeAssets;

      //--
      let nextToken1 = "";
      let assets1 = [];
      for (let siteee of sites) {
        const result = await API.graphql({
          query: queries.assetsBySitedetailsID,
          variables: {
            sitedetailsID: siteee.id,
            filter: {
              status: {
                ne: "delete",
              },
            },
            limit: limit,
          },
        });
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.assetsBySitedetailsID.items;
          nextToken1 = result.data.assetsBySitedetailsID.nextToken;
          console.log(
            "first token count",
            result.data.assetsBySitedetailsID.items.length
          );
          console.log("nextToken 1", nextToken1);
          if (result.data.assetsBySitedetailsID.items.length > 0) {
            assets1.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: siteee.id,
                filter: {
                  status: {
                    ne: "delete",
                  },
                },
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.assetsBySitedetailsID.items.length > 0) {
              nextToken1 = results.data.assetsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken1);
              console.log(results.data.assetsBySitedetailsID.items.length);
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets1.push(results.data.assetsBySitedetailsID.items);
              }
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            console.log(mergeAssets1);
            //--
            const filterAssets = mergeAssets1.filter(
              (item) => item.siteId === selectedSiteGlobal
            );
            console.log(filterAssets);
            const dataasset = selectedSiteGlobal ? filterAssets : mergeAssets1;
            const sort = dataasset.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            console.log(sort);
            setAssetsPassTest(sort);
          } else {
            setAssetsPassTest([]);
          }
        }
      }
      //   } else {
      //     setAssetsPassTest([]);
      //   }
      //  }
    } else {
      //---
      //--
      let nextToken1 = "";
      let assets1 = [];
      for (let siteee of sites) {
        const result = await API.graphql({
          query: queries.assetsBySitedetailsID,
          variables: {
            sitedetailsID: siteee.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
        });
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.assetsBySitedetailsID.items;
          nextToken1 = result.data.assetsBySitedetailsID.nextToken;
          console.log(
            "first token count",
            result.data.assetsBySitedetailsID.items.length
          );
          console.log("nextToken 1", nextToken1);
          if (result.data.assetsBySitedetailsID.items.length > 0) {
            assets1.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: siteee.id,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.assetsBySitedetailsID.items.length > 0) {
              nextToken1 = results.data.assetsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken1);
              console.log(results.data.assetsBySitedetailsID.items.length);
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets1.push(results.data.assetsBySitedetailsID.items);
              }
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            console.log(mergeAssets1);
            //--
            const filterAssets = mergeAssets1.filter(
              (item) => item.siteId === selectedSiteGlobal
            );
            console.log(filterAssets);
            const dataasset = selectedSiteGlobal ? filterAssets : mergeAssets1;
            const sort = dataasset.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            console.log(sort);
            setAssetsPassTest(sort);
          } else {
            setAssetsPassTest([]);
          }
        }
      }
    }
  }
  // date range start-----
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  //---Week------------------------------------------

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    )
  ).format("DD/MM/YYYY");
  const endOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    )
  ).format("DD/MM/YYYY");

  // ---Month---------------------------

  const startOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  ).format("DD/MM/YYYY");
  const endOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("DD/MM/YYYY");

  // ---year------------------------------

  const startOfThisYear = moment(
    new Date(currentDate.getFullYear(), 0, 1)
  ).format("DD/MM/YYYY");
  const endOfThisYear = moment(
    new Date(currentDate.getFullYear(), 11, 31)
  ).format("DD/MM/YYYY");

  //---custom -------------------------------------------------

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //----------------------------------------------------------------------

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    // const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);
      console.log(event.target.value);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());
          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());
          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());
          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());
          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());
          break;
        case "Custom":
          // onDateRangeSelected(getCustomRange());
          break;
        default:
          break;
      }
    };

    const getTodayRange = () => {
      fetchTasksSPLogin("Today");
      //fetchTasksCompleted("Today");
      //fetchTasksHold("Today");
      fetchTestingSP("Today");
    };

    const getCustomRange = () => {
      // fetchTasks("Custom");
      // fetchTasksCompleted("Custom");
      // fetchTasksHold("Custom");
    };

    const getAllTimeRange = () => {
      fetchTasksSPLogin("All Time");
      //fetchTasksCompleted("All Time");
      //fetchTasksHold("All Time");
      fetchTestingSP("All Time");
    };

    const getWeekRange = () => {
      fetchTasksSPLogin("This Week");
      //fetchTasksCompleted("This Week");
      //fetchTasksHold("This Week");
      fetchTestingSP("This Week");
    };

    const getMonthRange = () => {
      console.log("inside the month range function........");
      fetchTasksSPLogin("This Month");
      // fetchTasksCompleted("This Month");
      // fetchTasksHold("This Month");
      fetchTestingSP("This Month");
    };

    const getThisYearRange = () => {
      fetchTasksSPLogin("This Year");
      //fetchTasksCompleted("This Year");
      //fetchTasksHold("This Year");
      fetchTestingSP("This Year");
    };

    //----------------------------------------------------------------------------------------

    return (
      <div className="daterange-card">
        <Row className="m-0">
          <Col xs={selectedOption === "Custom" ? "6" : "12"} className="p-0">
            <FormGroup className="mb-0">
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="All Time">All Time</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Year">This Year</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
          </Col>
          {selectedOption === "Custom" && (
            <Col xs="6" className="pe-0">
              <FormGroup>
                <Fragment>
                  <Row className="m-0">
                    <Col xl="6" className="w-100 p-0">
                      <div className="daterange-card">
                        <div className="mb-0">
                          <FormGroup>
                            <DatePicker
                              className="form-control digits"
                              selectsRange={true}
                              startDate={startDateCustom}
                              endDate={endDateCustom}
                              onChange={(d) => {
                                setDateRange(d);
                                console.log(d);
                                console.log(startDate);
                                console.log(endDate);
                                fetchTasksSPLogin("Custom");
                                // fetchTasksCompleted("Custom");
                                // fetchTasksHold("Custom");
                                fetchTestingSP("Custom");
                              }}
                              isClearable={true}
                              placeholderText="Enter Date Range"
                              dateFormat="dd-MM-yyyy"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              </FormGroup>
            </Col>
          )}
        </Row>
      </div>
    );
  }

  //----Date Range End ------
  const handleAssets = (event) => {
    try {
      console.log(event);
      console.log(event.value);
      setSelectedAssetNew(event.value);
      const selectedAsset = event.value;
      if (selectedAsset === "") {
        if (selectedStatus !== "") {
          const filteredTasks1 = tasksDemo.filter(
            (sp) => sp.taskStatus === selectedStatus
          );
          setTaskFilterAssetStatus(filteredTasks1);
          setTasks(cloneDeep(filteredTasks1.slice(0, countPerPage)));
          const filteredTasksCompletedDemo1 = tasksCompletedDemo.filter(
            (sp) => sp.taskStatus === selectedStatus
          );
          settaskCompletedFilterAssetStatus(filteredTasksCompletedDemo1);
          setTasksCompleted(
            cloneDeep(filteredTasksCompletedDemo1.slice(0, countPerPage))
          );
          const filteredTaskshold = holdtasksdemo.filter(
            (sp) => sp.taskStatus === selectedStatus
          );
          console.log(holdtasksdemo);
          console.log(filteredTaskshold);
          setHoldTasks(cloneDeep(filteredTaskshold.slice(0, countPerPage)));
          settaskHoldFilterAssetStatus(filteredTaskshold);
          setTaskExportHold(filteredTaskshold);
          setTasksExport(filteredTasks1);
          setTaskExportCompleted(filteredTasksCompletedDemo1);
        } else {
          setTasks(cloneDeep(tasksDemo.slice(0, countPerPage)));
          setTasksCompleted(
            cloneDeep(tasksCompletedDemo.slice(0, countPerPage))
          );
          setHoldTasks(cloneDeep(holdtasksdemo.slice(0, countPerPage)));
          setTaskExportHold(holdtasksdemo);
          setTasksExport(tasksDemo);
          setTaskExportCompleted(tasksCompletedDemo);
        }
        setPassTest(cloneDeep(passTestDemo.slice(0, countPerPage)));
        settasksExportNonAssigned(passTestDemo);
      } else {
        const filteredTasks = tasksDemo.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAsset);
        });
        console.log(filteredTasks);
        setTasks(cloneDeep(filteredTasks.slice(0, countPerPage)));
        setTasksExport(filteredTasks);
        setTaskFilterAssetStatus(filteredTasks);

        //--hold tasks
        const filteredTasksHold = holdtasksdemo.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAsset);
        });
        console.log(filteredTasksHold);
        setHoldTasks(cloneDeep(filteredTasksHold.slice(0, countPerPage)));
        setTaskExportHold(filteredTasksHold);
        settaskHoldFilterAssetStatus(filteredTasksHold);

        const filteredTasksCompleted = tasksCompletedDemo.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAsset);
        });
        const filteredTasksPassTest = passTestDemo.filter((sp) => {
          if (sp.assetsID === selectedAsset) {
            return sp;
          }
        });
        console.log(filteredTasksCompleted);
        settaskCompletedFilterAssetStatus(filteredTasksCompleted);
        setTasksCompleted(
          cloneDeep(filteredTasksCompleted.slice(0, countPerPage))
        );
        setTaskExportCompleted(filteredTasksCompleted);
        setPassTest(cloneDeep(filteredTasksPassTest.slice(0, countPerPage)));
        settasksExportNonAssigned(filteredTasksPassTest);
        setNonAssignedFilter(filteredTasksPassTest);
        const TaskNew =
          filteredTasks.length > 0 ? filteredTasks : filteredTasksCompleted;
        //setTasksExport(TaskNew);
        if (selectedStatus !== "") {
          const filteredTasks1 = filteredTasks.filter(
            (sp) => sp.taskStatus === selectedStatus
          );

          setTaskFilterAssetStatus(filteredTasks1);
          setTasks(cloneDeep(filteredTasks1.slice(0, countPerPage)));
          const filteredTasksCompletedDemo1 = filteredTasksCompleted.filter(
            (sp) => sp.taskStatus === selectedStatus
          );
          setTasksExport(filteredTasks1);
          settaskCompletedFilterAssetStatus(filteredTasksCompletedDemo1);
          setTasksCompleted(
            cloneDeep(filteredTasksCompletedDemo1.slice(0, countPerPage))
          );
          setTaskExportCompleted(filteredTasksCompletedDemo1);

          //---hold tasks
          const filteredTasksHold2 = filteredTasksHold.filter(
            (sp) => sp.taskStatus === selectedStatus
          );
          setHoldTasks(cloneDeep(filteredTasksHold2.slice(0, countPerPage)));
          setTaskExportHold(filteredTasksHold2);
          settaskHoldFilterAssetStatus(filteredTasksHold2);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //---task status dropdown---
  const handleTaskStatus = (event) => {
    console.log(event.value);
    const selectedStatus1 = event.value;
    setSelectedStatus(selectedStatus1);
    if (selectedStatus1 === "") {
      if (selectedAssetNew !== "") {
        const filteredTasks1 = tasksDemo.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAssetNew);
        });
        console.log(filteredTasks1);
        setTaskFilterAssetStatus(filteredTasks1);
        setTasks(cloneDeep(filteredTasks1.slice(0, countPerPage)));
        setTasksExport(filteredTasks1);

        const filteredTasksCompleted = tasksCompletedDemo.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAssetNew);
        });
        console.log(filteredTasksCompleted);
        settaskCompletedFilterAssetStatus(filteredTasksCompleted);
        setTasksCompleted(
          cloneDeep(filteredTasksCompleted.slice(0, countPerPage))
        );
        setTaskExportCompleted(filteredTasksCompleted);

        //---hold task
        const filteredTaskshold = holdtasksdemo.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAssetNew);
        });
        console.log(filteredTaskshold);
        setHoldTasks(cloneDeep(filteredTaskshold.slice(0, countPerPage)));
        setTaskExportHold(filteredTaskshold);
        settaskHoldFilterAssetStatus(filteredTaskshold);
      } else {
        console.log("no status selected");
        setTasks(cloneDeep(tasksDemo.slice(0, countPerPage)));
        setTasksCompleted(cloneDeep(tasksCompletedDemo.slice(0, countPerPage)));
        setTaskExportCompleted(tasksCompletedDemo);
        setTasksExport(tasksDemo);
        setHoldTasks(cloneDeep(holdtasksdemo.slice(0, countPerPage)));
        setTaskExportHold(holdtasksdemo);
      }
    } else {
      const filteredTasks = tasksDemo.filter(
        (sp) => sp.taskStatus === selectedStatus1
      );
      const filteredTaskComp = tasksCompletedDemo.filter(
        (sp) => sp.taskStatus === selectedStatus1
      );
      const filteredTaskholdd = holdtasksdemo.filter(
        (sp) => sp.taskStatus === selectedStatus1
      );
      setHoldTasks(cloneDeep(filteredTaskholdd.slice(0, countPerPage)));
      setTaskExportHold(filteredTaskholdd);
      settaskHoldFilterAssetStatus(filteredTaskholdd);
      console.log(filteredTasks);
      setTasks(cloneDeep(filteredTasks.slice(0, countPerPage)));
      setTaskFilterAssetStatus(filteredTasks);
      setTasksExport(filteredTasks);
      setTasksCompleted(cloneDeep(filteredTaskComp.slice(0, countPerPage)));
      settaskCompletedFilterAssetStatus(filteredTaskComp);
      setTaskExportCompleted(filteredTaskComp);
      console.log(selectedAssetNew);
      if (selectedAssetNew !== "") {
        const filteredTasks1 = filteredTasks.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAssetNew);
        });
        console.log(filteredTasks1);
        setTasks(cloneDeep(filteredTasks1.slice(0, countPerPage)));
        setTaskFilterAssetStatus(filteredTasks1);
        setTasksExport(filteredTasks1);

        const filteredTasksCompleted = filteredTaskComp.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAssetNew);
        });
        console.log(filteredTasksCompleted);
        setTasksCompleted(
          cloneDeep(filteredTasksCompleted.slice(0, countPerPage))
        );
        settaskCompletedFilterAssetStatus(filteredTasksCompleted);
        setTaskExportCompleted(filteredTasksCompleted);

        const filteredTasksholddd = filteredTaskholdd.filter((sp) => {
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0].assets;
          if (assets !== undefined) return assets.includes(selectedAssetNew);
        });
        console.log(filteredTasksholddd);
        setHoldTasks(cloneDeep(filteredTasksholddd.slice(0, countPerPage)));
        setTaskExportHold(filteredTasksholddd);
        settaskHoldFilterAssetStatus(filteredTasksholddd);
        // setPassTest(filteredTasksPassTest);
        // const TaskNew =
        //   filteredTasks.length > 0 ? filteredTasks : filteredTasksCompleted;
        // setTasksExport(TaskNew);
      }
    }
  };
  // ---export ----
  const link = document.createElement("a");
  const handleExport = async () => {
    if (activeTab === "non_assigned") {
      let csvContent = "";

      const headers = [
        "Added On",
        "Test Result",
        "Assets",
        "Site",
        "Category",
        "Test On",
        "Next Test Schedule",
        "Additional Information",
      ];
      csvContent += headers.join(",") + "\n";

      const replaceHash = (value) => {
        if (value && typeof value === "string") {
          return value.replace(/#/g, '""');
        } else {
          return value;
        }
      };

      let exportData = tasksExportNonAssigned;

      exportData.forEach((item) => {
        const row = [
          '"' + moment(item.createdAt).format("DD/MM/YYYY") + '"',
          '"' + item.testResult + '"',
          '"' + item.assetsID + '"',
          '"' + getSiteUnitNumber(item.siteId) + '"',
          '"' + getCategory(item.categoriesID) + '"',
          '"' + moment(item.testOn).format("DD/MM/YYYY") + '"',
          '"' + moment(item.nextTest).format("DD/MM/YYYY") + '"',
          '"' + item.additionalInformation + '"',
        ];
        csvContent += row.join(",") + "\n";
      });

      const encodedUri =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tasks.csv");

      link.click();

      // Revert the placeholder back to "#" after exporting
      const revertHash = (value) => {
        if (value) {
          return value.replace(/{{HASH}}/g, "#");
        } else {
          return value;
        }
      };

      tasksExport.forEach((item) => {
        item.assetsID = revertHash(getAssets(item.assetsID));
        item.siteId = revertHash(getSiteUnitNumber(item.siteId));
        item.categoriesID = revertHash(getCategory(item.categoriesID));
        item.additionalInformation = revertHash(item.additionalInformation);
      });
    } else {
      let csvContent = "";

      const headers = [
        "Added On",
        "Task Type",
        "Site",
        "Category",
        "Task Status",
        "Task Assignee",
        "Task Assigned By",
        "Task Assigned Date",
        "Due Date",
      ];
      csvContent += headers.join(",") + "\n";

      const replaceHash = (value) => {
        if (value && typeof value === "string") {
          return value.replace(/#/g, '""');
        } else {
          return value;
        }
      };

      let exportData = [];

      if (activeTab === "open_tasks") {
        exportData = tasksExport;
      } else if (activeTab === "tasks-completed") {
        exportData = taskExportCompleted;
      } else if (activeTab === "hold_tasks") {
        exportData = tasksExportHold;
      }

      exportData.forEach((item) => {
        const row = [
          '"' + moment(item.createdAt).format("DD/MM/YYYY") + '"',
          '"' + item.taskType + '"',
          '"' +
            getSiteUnitNumber(JSON.parse(item.taskCompletionLink)[0].site) +
            '"',
          '"' +
            getCategory(JSON.parse(item.taskCompletionLink)[0].category) +
            '"',
          '"' + item.taskStatus + '"',
          '"' + getTaskAssigneeName(item.taskAssignee) + '"',
          '"' + getTaskAssigneeBy(item.assignedBy) + '"',
          '"' + moment(item.assignedDate).format("DD/MM/YYYY") + '"',
          item.dueDate !== null
            ? '"' + moment(item.dueDate).format("DD/MM/YYYY") + '"'
            : "-",
        ];
        csvContent += row.join(",") + "\n";
      });

      const encodedUri =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tasks.csv");

      link.click();

      // Revert the placeholder back to "#" after exporting
      const revertHash = (value) => {
        if (value) {
          return value.replace(/{{HASH}}/g, "#");
        } else {
          return value;
        }
      };

      tasksExport.forEach((item) => {
        item.taskType = revertHash(item.taskType);
        item.taskStatus = revertHash(item.taskStatus);
        item.taskAssignee = revertHash(getTaskAssigneeName(item.taskAssignee));
        item.assignedBy = revertHash(getTaskAssigneeBy(item.assignedBy));
      });
    }
  };

  document.body.appendChild(link);
  document.body.appendChild(link);

  // ---- Pass Test Non Assigned Tasks For Service provider Login ----
  async function fetchTestingSP(DateRange) {
    let tasksCompleted = [];
    //setIsLoading(true);
    const result = await API.graphql({
      query: queries.listAssetTemplates,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    const assetTemplate = result.data.listAssetTemplates.items;
    console.log(assetTemplate);

    const data = sites.filter(
      (item) => item.assetownerID === selectedAssetOwnerGlobal
    );
    const filterdata = selectedAssetOwnerGlobal ? data : sites;
    console.log(filterdata);
    console.log(sites);
    //--
    //const datasite = sites.data.siteDetailsByAssetownerID.items;
    const filtersites = filterdata.filter(
      (item) => item.id === selectedSiteGlobal
    );
    console.log(filtersites);
    const filteredsites = selectedSiteGlobal ? filtersites : filterdata;
    console.log(filteredsites);
    //--
    let siteIds = [];
    filteredsites.map((item) => {
      siteIds.push(item.id);
    });
    //---
    const promises = filteredsites.map(async (siteee) => {
      return API.graphql({
        query: queries.assetsBySitedetailsID,
        variables: {
          sitedetailsID: siteee.id,
          filter: {
            status: {
              ne: "delete",
            },
          },
          limit: limit,
        },
      });
    });
    Promise.all(promises)
      .then(async (results) => {
        const testReport2 = [];
        let assetFilter = [];
        results.forEach((result1) => {
          const assetList = result1.data.assetsBySitedetailsID.items;
          console.log(assetList);
          if (assetList.length > 0) {
            console.log(assetList);
            testReport2.push(...assetList);
          }
        });
        console.log("final data");
        console.log(testReport2);
        //---
        const response = await API.graphql({
          query: queries.listLogBooks,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (response.data.listLogBooks.items.length > 0) {
          console.log(response);
          const asset = response.data.listLogBooks.items.filter((item) => {
            const _matchSites = siteIds?.find((id) => id === item?.siteId);
            if (_matchSites) {
              return item;
            }
          });
          console.log(asset);
          const testReport = [];
          if (asset.length > 0) {
            const promises = asset.map(async (item) => {
              return API.graphql({
                query: queries.testingsByLogbookID,
                variables: {
                  logbookID: item.id,
                  filter: {
                    testResult: {
                      eq: "Pass",
                    },
                  },
                  limit: limit,
                },
              });
            });
            console.log(promises);
            Promise.all(promises)
              .then(async (results) => {
                const testReport = [];
                const testReporttest = [];
                results.forEach((result1) => {
                  const assetList = result1.data.testingsByLogbookID.items;
                  console.log(assetList);
                  testReport.push(...assetList);
                });
                console.log("final data");
                console.log(testReport);
                const filteredItems = testReport.filter((item) => {
                  const testOnDate = moment(item.testOn).format("YYYY-MM-DD");
                  const nextTestScheduleDate = moment(
                    item.nextTestSchedule
                  ).format("YYYY-MM-DD");
                  const monthsDifference = differenceInMonths(
                    parseISO(nextTestScheduleDate),
                    parseISO(testOnDate)
                  );
                  return monthsDifference <= 6;
                });
                console.log(filteredItems);

                if (filteredItems.length > 0) {
                  await Promise.all(
                    filteredItems.map(async (test) => {
                      //console.log(assetsss);
                      console.log(test.assetsID);
                      const site = testReport2.filter(
                        (item) => item.id === test.assetsID
                      );
                      console.log(site);
                      const sitess = asset.filter(
                        (item) => item.id === test.logbookID
                      );
                      console.log(sitess);
                      const catId =
                        site &&
                        site[0] &&
                        assetTemplate.filter(
                          (item) => item.id === site[0].assettemplateID
                        );
                      console.log(catId);
                      //const categorydata = catId && catId[0] && await getcategory(catId);
                      const assetdata = await getAssets1(test.assetsID);

                      testReporttest.push({
                        id: test.id,
                        assetsID: assetdata,
                        assetsId: test.assetsID,
                        createdAt: test.createdAt,
                        siteId: sitess && sitess[0] && sitess[0].siteId,
                        categoriesID:
                          catId && catId[0] && catId[0].categoriesID,
                        testOn: test.testOn,
                        testResult: test.testResult,
                        nextTest: test.nextTestSchedule,
                        customfields: test.customfields,
                        additionalInformation: test.additionalInformation,
                      });
                    })
                  );
                  const sortedItems = testReporttest.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });

                  console.log(sortedItems);
                  if (DateRange === "This Week") {
                    tasksCompleted = sortedItems.filter((item) => {
                      const createdAtDate = moment(item.createdAt);
                      return (
                        createdAtDate.isSameOrAfter(
                          moment(startOfWeek, "DD/MM/YYYY"),
                          "day"
                        ) &&
                        createdAtDate.isSameOrBefore(
                          moment(endOfWeek, "DD/MM/YYYY"),
                          "day"
                        )
                      );
                    });
                  } else if (DateRange === "This Month") {
                    tasksCompleted = sortedItems.filter((item) => {
                      const createdAtDate = moment(item.createdAt);
                      return createdAtDate.isBetween(
                        moment(startOfMonth, "DD/MM/YYYY"),
                        moment(endOfMonth, "DD/MM/YYYY"),
                        null,
                        "[]"
                      );
                    });
                  } else if (DateRange === "Today") {
                    tasksCompleted = sortedItems.filter(
                      (item) =>
                        moment(item.createdAt).format("DD/MM/YYYY") ===
                        moment(new Date()).format("DD/MM/YYYY")
                    );
                  } else if (DateRange === "This Year") {
                    tasksCompleted = sortedItems.filter((item) => {
                      const createdAtDate = moment(item.createdAt);
                      return createdAtDate.isBetween(
                        moment(startOfThisYear, "DD/MM/YYYY"),
                        moment(endOfThisYear, "DD/MM/YYYY"),
                        null,
                        "[]"
                      );
                    });
                  } else if (DateRange === "Custom") {
                    tasksCompleted = sortedItems.filter((item) => {
                      const createdAtDate = moment(item.createdAt);
                      return createdAtDate.isBetween(
                        moment(startDate, "DD/MM/YYYY"),
                        moment(endDate, "DD/MM/YYYY"),
                        null,
                        "[]"
                      );
                    });
                  } else if (DateRange === "All Time") {
                    tasksCompleted = sortedItems;
                  }
                  settasksExportNonAssigned(tasksCompleted);
                  setPassTestDemo(tasksCompleted);
                  console.log(testReport);
                  setNonAssignedCatFilter(tasksCompleted);
                  setPassTest(cloneDeep(tasksCompleted.slice(0, countPerPage)));
                  //setIsLoading(false);
                } else {
                  settasksExportNonAssigned([]);
                  setPassTestDemo([]);
                  console.log(testReport);
                  setNonAssignedCatFilter([]);
                  setPassTest(cloneDeep([].slice(0, countPerPage)));
                  //setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setErrorMessage("Unable to fetch the list");
                //setIsLoading(false);
              });
          } else {
            settasksExportNonAssigned([]);
            setPassTestDemo([]);
            console.log(testReport);
            setNonAssignedCatFilter([]);
            setPassTest(cloneDeep([].slice(0, countPerPage)));
            //setIsLoading(false);
          }
        }
        //--
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAssets(aid) {
    const technicianName = assetsDropdown.find((item) => item.id === aid);
    return technicianName ? technicianName.fittingNumber : "";
  }

  // ---- Ao Sp names for dropdown
  async function fetchSPUderDetails() {
    try {
      if (localStorage.getItem("spuser") !== null) {
        let activeAssets = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
        const electrician = activeAssets.filter(
          (item) => item.userGroup === "electrician"
        );
        const sortedItems = electrician.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItems);
        setSPUserName1(sortedItems);
      } else {
        const dataDetails = await API.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: 100000,
          },
        });
        if (
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
        ) {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          console.log(siteDetails);
          const siteglobal = siteDetails.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const sitesss = selectedSiteGlobal ? siteglobal : siteDetails;
          console.log(sitesss);
          let userIds = [];
          let nextToken1 = "";
          if (sitesss.length > 0) {
            for (let site of sitesss) {
              const getSiteIds = await API.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.sitedetailsID,
                },
              });
              console.log(getSiteIds);
              if (
                getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                );
                while (nextToken1 !== null) {
                  const getSiteIds1 = await API.graphql({
                    query: queries.linkUsersAndSitesBySitedetailsID,
                    authMode: "API_KEY",
                    variables: {
                      sitedetailsID: site.sitedetailsID,
                      nextToken: nextToken1,
                    },
                  });
                  console.log(getSiteIds1);
                  if (
                    getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                      .length > 0
                  ) {
                    nextToken1 =
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID
                        .nextToken;
                    userIds.push(
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                    );
                  }
                }
                console.log(userIds);
                if (userIds.length > 0) {
                  const mergeUserIds = userIds.flat(1);
                  const response1 = await API.graphql({
                    query: queries.listSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      filter: {
                        userGroup: {
                          eq: "electrician",
                        },
                      },
                      limit: 200000,
                    },
                  });
                  if (response1.data.listSPUserDetails.items.length > 0) {
                    console.log(LoggedInUserId);
                    const spDetails2 =
                      response1.data.listSPUserDetails.items.filter((item) => {
                        const _matchSites = mergeUserIds?.find(
                          (user) => user.userdetailsID === item?.id
                        );
                        if (_matchSites) {
                          return item;
                        }
                      });
                    console.log(spDetails2);
                    const sortedItems = spDetails2.sort((a, b) => {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    console.log(sortedItems);
                    const spDetails = sortedItems;
                    console.log(spDetails);
                    setSPUserName1(spDetails);
                  }
                } else {
                }
              }
            }
          } else {
          }
        }
      }
      // const data1 = await API.graphql({
      //   query: queries.linkUsersAndSitesByUserdetailsID,
      //   authMode: "API_KEY",
      //   variables: {
      //     userdetailsID: LoginUserDetails.id,
      //     limit: limit,
      //   },
      // });
      // if (data1.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      //   const siteDetails = data1.data.linkUsersAndSitesByUserdetailsID.items;
      //   if (siteDetails.length > 0) {
      //     const siteUserFilterArray = [];
      //     siteDetails.map(async (item) => {
      //       siteUserFilterArray.push({
      //         sitedetailsID: {
      //           eq: item.sitedetailsID,
      //         },
      //       });
      //     });
      //     const dataDetails = API.graphql({
      //       query: queries.listLinkUsersAndSites,
      //       authMode: "API_KEY",
      //       variables: {
      //         filter: {
      //           or: siteUserFilterArray,
      //         },
      //         limit: limit,
      //       },
      //     });
      //     if (dataDetails.data.listLinkUsersAndSites.items.length > 0) {
      //       const spDetails = dataDetails.data.listLinkUsersAndSites.items;
      //       if (spDetails.length > 0) {
      //         const userFilterArray = [];
      //         spDetails.map(async (item) => {
      //           userFilterArray.push({
      //             id: {
      //               eq: item.userdetailsID,
      //             },
      //           });
      //         });
      //         const response1 = API.graphql({
      //           query: queries.listSPUserDetails,
      //           authMode: "API_KEY",
      //           variables: {
      //             filter: {
      //               or: userFilterArray,
      //               userGroup: {
      //                 eq: "electrician",
      //               },
      //             },
      //             limit: limit,
      //           },
      //         });
      //         if (response1.data.listSPUserDetails.items.length > 0) {
      //           const response = API.graphql({
      //             query: queries.listSPUserDetails,
      //             authMode: "API_KEY",
      //             variables: {
      //               filter: {
      //                 userGroup: {
      //                   eq: "electrician",
      //                 },
      //                 invitedBy: {
      //                   eq: LoggedInUserId,
      //                 },
      //               },
      //               limit: limit,
      //             },
      //           });
      //           if (response.data.listSPUserDetails.items.length > 0) {
      //             console.log(response);
      //             console.log(response1);
      //             setSPUserName1(response1.data.listSPUserDetails.items);
      //           }
      //         }
      //       }
      //     }
      //   } else {
      //     setSPUserName1([]);
      //     setIsLoading(false);
      //   }
      // }
    } catch (error) {
      console.log(error);
    }
  }

  // ---checkbox----change
  const [isAllSelected, setIsAllSelected] = useState(false);
  const handleCheckboxChange = (event, item) => {
    console.log(item);
    const isChecked = event.target.checked;

    if (item === "selectAll") {
      setIsAllSelected(isChecked);

      if (isChecked) {
        const allItems = passTestDemo.map((item) => item);
        setSelectedItems(allItems);
      } else {
        setSelectedItems([]);
      }
    } else {
      if (isChecked) {
        setIsCheckboxChecked(item);
        setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
      } else {
        setIsCheckboxChecked(null);
        setSelectedItems((prevSelectedItems) =>
          prevSelectedItems.filter((selectedItem) => selectedItem !== item)
        );
      }
    }
  };

  const [isAllSelectedOpen, setIsAllSelectedOpen] = useState(false);
  let itemss = [];

  const handleCheckboxChangeOpen = (event, item) => {
    const isChecked = event.target.checked;

    if (item === "selectAll") {
      setIsAllSelectedOpen(isChecked);

      if (isChecked) {
        const allItems = tasksDemo.map((item) => item);
        setSelectedItemsOpen(allItems);
      } else {
        setSelectedItemsOpen([]);
      }
    } else {
      if (isChecked) {
        setIsCheckboxCheckedOpen(item);
        setSelectedItemsOpen((prevSelectedItems) => [
          ...prevSelectedItems,
          item,
        ]);
      } else {
        setIsCheckboxCheckedOpen(null);
        setSelectedItemsOpen((prevSelectedItems) =>
          prevSelectedItems.filter((selectedItem) => selectedItem !== item)
        );
      }

      // Check if all items are selected based on updated state
      const allSelected = tasksDemo.every((task) =>
        selectedItemsOpen.includes(task)
      );
      setIsAllSelectedOpen(allSelected);
    }
  };

  useEffect(() => {
    setCheckedItemCountOpen(selectedItemsOpen.length);
    console.log(selectedItemsOpen);
    console.log(checkedItemCountOpen);
  }, [selectedItemsOpen]);

  const [checkedItemCountOpen, setCheckedItemCountOpen] = useState(0);

  useEffect(() => {
    setCheckedItemCount(selectedItems.length);
    console.log(selectedItems);
    console.log(checkedItemCount);
  }, [selectedItems]);

  const [checkedItemCount, setCheckedItemCount] = useState(0);

  // ---dropdown
  const handleDropdownChange = (event) => {
    setSelectedAssignee(event.target.value);
  };

  const handleDropdownChangeComp = (event) => {
    setCompId(event.value);
  };

  // ----save button for non assigned task
  const save = async () => {
    try {
      console.log("save ....");
      console.log("Saving selected SpName:", selectedAssignee);
      console.log("Saving selected PassTest:", selectedItems);
      const selectedItemsIds = selectedItems.map((asset) => asset.id);
      console.log("Saving selected CompID:", compID);
      const companyId = LoginUserGroup === "electrician" ? spLoginComp : compID;
      console.log(companyId);

      //const test = await fetchTest(selectedItems);
      //const test = passTest.filter((item) => selectedItems.includes(item.id));
      //console.log(test);
      const Assets = selectedItems.map((asset) => asset.assetsId);
      console.log(Assets);

      //const filtertest = passTest.filter(
      //  (item) => item.id === selectedItems[0]
      //);
      //console.log(filtertest);
      console.log(selectedItems[0].assetsID);
      const assetId = selectedItems[0].assetsId;
      const filterAsset = assetsPassTest.filter((item) => item.id === assetId);
      console.log(assetsDropdown);
      console.log(filterAsset);
      const siteId = filterAsset[0].siteId;
      const distriId = filterAsset[0].distributionboxID;
      console.log(siteId);
      console.log(distriId);
      const assetTemplate = filterAsset[0].assettemplateID;
      console.log(assetTemplate);
      const Filterassettemplate = assettemplate.filter(
        (item) => item.id === assetTemplate
      );
      console.log(Filterassettemplate);
      const catId = Filterassettemplate[0].categoriesID;
      console.log(catId);
      const filtersites = logbook.filter((item) => item.siteId === siteId);
      console.log(filtersites);
      const logbookid = filtersites[0].id;
      console.log(logbookid);
      let taskCompletionLinkData;
      taskCompletionLinkData = JSON.stringify([
        {
          site: siteId,
          category: catId,
          distributionBoard: distriId,
          logbook: logbookid,
          company: companyId,
          assets: Assets,
        },
      ]);
      console.log(taskCompletionLinkData);
      const today = new Date();
      const sevenDaysLater = new Date(today);
      sevenDaysLater.setDate(today.getDate() + 7);
      console.log(sevenDaysLater);
      await API.graphql({
        query: mutations.createTask,
        variables: {
          limit: 10000,
          input: {
            taskType: "Test",
            //taskAssignee: selectedAssignee,
            taskStatus: "Pending",
            assignedDate: new Date().toISOString().split("T")[0],
            dueDate: sevenDaysLater.toISOString().split("T")[0],
            taskCompletionLink: taskCompletionLinkData,
            spcompanydetailsID: companyId,
            sitedetailsID: siteId,
            assignedBy: LoggedInUserId,
          },
        },
        authMode: "API_KEY",
      })
        .then((response) => {
          console.log(response);
          setIsCheckboxChecked(false);
          setSelectedAssignee("");
          setSelectedItems([]);
          setCompId([]);

          if (LoginUserGroup === "site_owner") {
            //fetchTasks("All Time");
            //fetchTasksCompleted("All Time");
          } else {
            fetchTasksSPLogin("All Time");
            //fetchCompletedTasksSPLogin("All Time");
          }

          toast.success(" Created Succesfully!..", {
            position: toast.POSITION.TOP_CENTER,
          });
          if (companyId) {
            const response = API.graphql({
              query: queries.listSPCompanyDetails,
              variables: {
                filter: {
                  id: {
                    eq: companyId,
                  },
                },
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (response.data.listSPCompanyDetails.items.length > 0) {
              console.log(response);
              const company = response.data.listSPCompanyDetails.items;
              const email = company[0].email;
              console.log(email);

              const emailData = {
                assignedBy: getTaskAssigneeBy(LoggedInUserId),
                taskAssignee: getTaskAssigneeName(selectedAssignee),
                TaskType: "Test",
                TaskStatus: "Pending",
                site: getSiteUnitNumber(siteId),
                category: getCategory(catId),
                distributionBoard: getDBName(distriId),
                logbook: getlogbookname(logbookid),
                // company: getCompany(companyId),
                company: company[0].companyName,
                DueDate: moment(
                  sevenDaysLater.toISOString().split("T")[0]
                ).format("DD/MM/YYYY"),
              };

              {
                /*
              emailData.testResult = test.map((item) => item.testResult);
              emailData.testOn = test.map((item) =>
                moment(item.testOn).format("DD/MM/YYYY")
              );
              emailData.nextTestSchedule = test.map((item) =>
                moment(item.nextTestSchedule).format("DD/MM/YYYY")
              );
              emailData.assettest = test.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails ? assetDetails.fittingNumber : null;
              });
            */
              }

              emailData.assets = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails ? assetDetails.fittingNumber : null;
              });
              emailData.AssetModel = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails ? assetDetails.assetModel : null;
              });
              emailData.AssetLocation = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails ? assetDetails.assetLocation : null;
              });
              emailData.AssetDescription = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails ? assetDetails.assetDescription : null;
              });
              emailData.AssetWarrantyStart = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails
                  ? moment(assetDetails.assetWarrantyStart).format("DD/MM/YYYY")
                  : null;
              });
              emailData.AssetWarrantyEnd = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails
                  ? moment(assetDetails.assetWarrantyEnd).format("DD/MM/YYYY")
                  : null;
              });
              emailData.InstalledOn = selectedItems.map((item) => {
                const assetDetails = assetsDropdown.find(
                  (asset) => asset.id === item.assetsId
                );
                return assetDetails
                  ? moment(assetDetails.installedOn).format("DD/MM/YYYY")
                  : null;
              });

              //emailData.assets = emailData.assets.sort((a, b) => a.fittingNumber - b.fittingNumber);
              console.log(emailData);

              sendEmail(email, emailData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error while Creating", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      console.log("error while adding tasks", error);
      toast.error("Error while Creating. No Assets linked", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // ---users dropdown for open task -- sp login
  const handleSpUser = (event) => {
    setOpenTaskSpUser(event.value);
  };

  // ----assign burron for open task
  const assign = async () => {
    try {
      setIsAssignLoad(true);
      console.log(selectedItemsOpen);
      console.log(openTaskSpUser);
      for (let j = 0; j < selectedItemsOpen.length; j++) {
        await API.graphql({
          query: mutations.updateTask,
          variables: {
            limit: limit,
            input: {
              id: selectedItemsOpen[j].id,
              taskAssignee: openTaskSpUser,
            },
          },
          authMode: "API_KEY",
        });
      }
      fetchTasksSPLogin("All Time");
      toast.success("Updated Successfully!..", {
        position: toast.POSITION.TOP_CENTER,
      });
      for (let i = 0; i < selectedItemsOpen.length; i++) {
        console.log(selectedItemsOpen[i]);
        const taskcompletion = JSON.parse(
          selectedItemsOpen[i].taskCompletionLink
        );
        console.log(taskcompletion);
        const taskAssets = taskcompletion[0].assets;
        let selectedAssets = taskcompletion[0].assets
          ? assetsList.filter((item) => taskAssets.includes(item.id))
          : [];
        // for (let assetId of taskAssets) {
        //   const assetsData = await API.graphql({
        //     query: queries.getAssets,
        //     variables: {
        //       id: assetId,
        //     },
        //     authMode: "API_KEY",
        //   });
        //   if (assetsData.data.getAssets !== null) {
        //     selectedAssets.push(assetsData.data.getAssets);
        //   }
        // }
        console.log(selectedAssets);
        const tasktests = taskcompletion[0].test;
        const test = taskcompletion[0].test
          ? testView.filter((item) => tasktests.includes(item.id))
          : [];
        console.log(test);
        console.log(openTaskSpUser);
        if (taskcompletion[0].company) {
          // const response = await API.graphql({
          //   query: queries.listSPCompanyDetails,
          //   variables: {
          //     filter: {
          //       id: {
          //         eq: taskcompletion[0].company,
          //       },
          //     },
          //     limit: limit,
          //   },
          //   authMode: "API_KEY",
          // });
          // if (response.data.listSPCompanyDetails.items.length > 0) {
          // console.log(response);
          const email = spCompany[0].email;
          console.log(email);
          const response2 = await API.graphql({
            query: queries.getSPUserDetails,
            variables: {
              id: openTaskSpUser,
            },
            authMode: "API_KEY",
          });
          if (response2.data.getSPUserDetails !== null) {
            console.log(response2);
            const spuser = response2.data.getSPUserDetails;
            const spemail = spuser.email;

            const emailData = {
              assignedBy: getTaskAssigneeBy(selectedItemsOpen[i].assignedBy),
              taskAssignee: getTaskAssigneeName(openTaskSpUser),
              TaskType: selectedItemsOpen[i].taskType,
              TaskStatus: selectedItemsOpen[i].taskStatus,
              site: getSiteUnitNumber(taskcompletion[0].site),
              category: getCategory(taskcompletion[0].category),
              distributionBoard: getDBName(taskcompletion[0].distributionBoard),
              logbook: getlogbookname(taskcompletion[0].logbook),
              company: spCompany[0].companyName,
              DueDate: moment(selectedItemsOpen[i].dueDate).format(
                "DD/MM/YYYY"
              ),
            };
            console.log(emailData);

            if (taskcompletion[0].assets && selectedAssets.length > 0) {
              emailData.assets = selectedAssets.map(
                (asset) => asset.fittingNumber
              );
              emailData.AssetModel = selectedAssets.map(
                (asset) => asset.assetModel
              );
              emailData.AssetLocation = selectedAssets.map(
                (asset) => asset.assetLocation
              );
              emailData.AssetDescription = selectedAssets.map(
                (asset) => asset.assetDescription
              );
              emailData.AssetWarrantyStart = selectedAssets.map((asset) =>
                moment(asset.assetWarrantyStart).format("DD/MM/YYYY")
              );
              emailData.AssetWarrantyEnd = selectedAssets.map((asset) =>
                moment(asset.assetWarrantyEnd).format("DD/MM/YYYY")
              );
              emailData.InstalledOn = selectedAssets.map((asset) =>
                moment(asset.installedOn).format("DD/MM/YYYY")
              );
            } else {
              emailData.testResult = test.map((item) => item.testResult);
              emailData.testOn = test.map((item) =>
                moment(item.testOn).format("DD/MM/YYYY")
              );
              emailData.nextTestSchedule = test.map((item) =>
                item.nextTestSchedule
                  ? moment(item.nextTestSchedule).format("DD/MM/YYYY")
                  : "-"
              );
              emailData.assetTest = test.map((item) => {
                const assetDetails = assetsList.find(
                  (asset) => asset.id === item.assetsID
                );
                return assetDetails ? assetDetails.fittingNumber : null;
              });
            }
            console.log(emailData);
            sendEmailAssign(email, emailData);
            sendEmailAssign(spemail, emailData);
          }
          // }
        }
      }

      setIsCheckboxCheckedOpen(false);
      setIsAllSelectedOpen(false);
      setOpenTaskSpUser([]);
      setSelectedItemsOpen([]);
      toast.success("Emails Sent Successfully!..", {
        position: toast.POSITION.TOP_CENTER,
      });
      setIsAssignLoad(false);
    } catch (error) {
      console.log("error while updatinging tasks", error);
      toast.error("Error while updatinging. No Assets linked", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // ---tests for mail---
  async function fetchTest(selectedItems) {
    try {
      const response = await API.graphql({
        query: queries.listTestings,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      });
      const allAssets = response.data.listTestings.items;
      const selectedTests = allAssets.filter(
        (item) => item.id === selectedItems
      );
      return selectedTests;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  // mail functioon for sp assign
  const sendEmailAssign = async (recipientEmail, taskData) => {
    console.log("email sending....");
    console.log(taskData);
    const {
      assignedBy,
      taskAssignee,
      TaskType,
      TaskStatus,
      DueDate,
      site,
      category,
      logbook,
      distributionBoard,
      assets,
      AssetModel,
      AssetLocation,
      AssetDescription,
      AssetWarrantyStart,
      AssetWarrantyEnd,
      InstalledOn,
      testOn,
      nextTestSchedule,
      testResult,
      assetTest,
      company,
    } = taskData;
    const senderEmail = "noreply@knowyourasset.com.au";
    const subject = "Task Assigned";

    let message = `
    <html>
    <body>
      <p>Hello,</p>
      <p>You have been assigned a new task:</p>
      <table style="border-collapse: collapse; width: 80%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Assigned By:</strong></td>
          <td>${assignedBy}</td>
        </tr>  
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Assignee:</strong></td>
          <td>${taskAssignee}</td>
        </tr>    
        
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Type:</strong></td>
          <td>${TaskType}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Status:</strong></td>
          <td>${TaskStatus}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Company:</strong></td>
          <td>${company}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Site:</strong></td>
          <td>${site}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Distribution Board:</strong></td>
          <td>${distributionBoard}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Logbook:</strong></td>
          <td>${logbook}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Category:</strong></td>
          <td>${category}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Due Date:</strong></td>
          <td>${DueDate}</td>
        </tr>
      </table>`;

    if (assets && assets.length > 0) {
      message += `
      <p><strong>Assets:</strong></p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Fitting Number</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Model</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Location</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Description</strong></td>
          <td style="border-right: 1px solid black;"><strong>Installed On</strong></td>
          <td style="border-right: 1px solid black;"><strong>Warranty Start</strong></td>
          <td><strong>Warranty End</strong></td>
        </tr>`;
      assets.forEach((asset, index) => {
        message += `
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;">${asset}</td>
          <td style="border-right: 1px solid black;">${AssetModel[index]}</td>
          <td style="border-right: 1px solid black;">${AssetLocation[index]}</td>
          <td style="border-right: 1px solid black;">${AssetDescription[index]}</td>
          <td style="border-right: 1px solid black;">${InstalledOn[index]}</td>
          <td style="border-right: 1px solid black;">${AssetWarrantyStart[index]}</td>
          <td>${AssetWarrantyEnd[index]}</td>
        </tr>`;
      });
      message += `
      </table>`;
    }

    if (testOn && testOn.length > 0) {
      message += `
      <p><strong>Test:</strong></p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
        <td style="border-right: 1px solid black;"><strong>Fitting NO.</strong></td>
          <td style="border-right: 1px solid black;"><strong>Test On</strong></td>
          <td style="border-right: 1px solid black;"><strong>Test Result</strong></td>
          <td><strong>Next Test Schedule Date</strong></td>
        </tr>`;
      assetTest.forEach((assetTest, index) => {
        message += `
        <tr style="border-bottom: 1px solid black;">
        <td style="border-right: 1px solid black;">${assetTest}</td>
          <td style="border-right: 1px solid black;">${testOn[index]}</td>
          <td style="border-right: 1px solid black;">${testResult[index]}</td>
          <td>${nextTestSchedule[index]}</td>
          </tr>`;
      });
      message += `
        </table>`;
    }

    message += `
      <p>Please complete the task by the due date.</p>
      <p>Best regards,</p>
      <p>KYA</p>
    </body>
    </html>`;

    try {
      await axios.post(EMAIL_API, {
        body: JSON.stringify({
          email: recipientEmail,
          subject: "Task Assigned",
          messageBody: message,
        }),
      });
    } catch (error) {
      console.log("error ", error);
    }

    // const params = {
    //   Destination: {
    //     ToAddresses: [recipientEmail],
    //   },
    //   Message: {
    //     Body: {
    //       Html: { Data: message },
    //     },
    //     Subject: { Data: subject },
    //   },
    //   Source: senderEmail,
    // };

    // const sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
    //   .sendEmail(params)
    //   .promise();

    // sendPromise
    //   .then((data) => {
    //     console.log("Email sent:", data.MessageId);
    //   })
    //   .catch((error) => {
    //     console.log("Error sending email:", error);
    //   });
  };

  // mail function----for pass test
  const sendEmail = async (recipientEmail, taskData) => {
    console.log("email sending....");
    console.log(taskData);
    const {
      assignedBy,
      taskAssignee,
      TaskType,
      TaskStatus,
      DueDate,
      site,
      category,
      logbook,
      distributionBoard,
      company,
      assettest,
      testResult,
      testOn,
      nextTestSchedule,
      assets,
      AssetModel,
      AssetLocation,
      AssetDescription,
      AssetWarrantyStart,
      AssetWarrantyEnd,
      InstalledOn,
    } = taskData;

    const senderEmail = "noreply@knowyourasset.com.au";
    const subject = "Task Assigned";

    let message = `
    <html>
    <body>
      <p>Hello,</p>
      <p>You have been assigned a new task:</p>
      <table style="border-collapse: collapse; width: 80%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Assigned By:</strong></td>
          <td>${assignedBy}</td>
        </tr>       
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Type:</strong></td>
          <td>${TaskType}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Status:</strong></td>
          <td>${TaskStatus}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Company:</strong></td>
          <td>${company}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Site:</strong></td>
          <td>${site}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Distribution Board:</strong></td>
          <td>${distributionBoard}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Logbook:</strong></td>
          <td>${logbook}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Category:</strong></td>
          <td>${category}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Due Date:</strong></td>
          <td>${DueDate}</td>
        </tr>
      </table>`;

    if (assets && assets.length > 0) {
      message += `
      <p><strong>Assets:</strong></p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Fitting Number</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Model</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Location</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Description</strong></td>
          <td style="border-right: 1px solid black;"><strong>Installed On</strong></td>
          <td style="border-right: 1px solid black;"><strong>Warranty Start</strong></td>
          <td><strong>Warranty End</strong></td>
        </tr>`;
      assets.forEach((asset, index) => {
        message += `
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;">${asset}</td>
          <td style="border-right: 1px solid black;">${AssetModel[index]}</td>
          <td style="border-right: 1px solid black;">${AssetLocation[index]}</td>
          <td style="border-right: 1px solid black;">${AssetDescription[index]}</td>
          <td style="border-right: 1px solid black;">${InstalledOn[index]}</td>
          <td style="border-right: 1px solid black;">${AssetWarrantyStart[index]}</td>
          <td>${AssetWarrantyEnd[index]}</td>
        </tr>`;
      });
      message += `
      </table>`;
    }

    message += `
      <p>Please complete the task by the due date.</p>
      <p>Best regards,</p>
      <p>KYA</p>
    </body>
    </html>`;

    try {
      await axios.post(EMAIL_API, {
        body: JSON.stringify({
          email: recipientEmail,
          subject: "Task Assigned",
          messageBody: message,
        }),
      });
    } catch (error) {
      console.log("error ", error);
    }

    // const params = {
    //   Destination: {
    //     ToAddresses: [recipientEmail],
    //   },
    //   Message: {
    //     Body: {
    //       Html: { Data: message },
    //     },
    //     Subject: { Data: subject },
    //   },
    //   Source: senderEmail,
    // };

    // const sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
    //   .sendEmail(params)
    //   .promise();

    // sendPromise
    //   .then((data) => {
    //     console.log("Email sent:", data.MessageId);
    //   })
    //   .catch((error) => {
    //     console.log("Error sending email:", error);
    //   });
  };

  //---assets Number
  async function getAssets1(aid) {
    try {
      const data = await API.graphql({
        query: queries.getAssets,
        authMode: "API_KEY",
        variables: {
          id: aid,
          filter: {
            status: {
              ne: "delete",
            },
          },
        },
      });

      //console.log(data)
      const fittingNumber = data.data.getAssets.fittingNumber;

      return fittingNumber;
    } catch (error) {
      //console.error("Error fetching assets:", error);
      return null;
    }
  }

  //--- for sp comapny filtered sp user
  async function fetchSpuserforSpComp(compId) {
    if (compId.length > 0) {
      //---
      if (localStorage.getItem("spuser") !== null) {
        let activeAssets = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
        const electrician = activeAssets.filter(
          (item) => item.userGroup === "electrician"
        );
        const activeuser = electrician.filter(
          (item) => item.status === "Active"
        );
        const activeusercomp = activeuser.filter(
          (item) => item.spcompanydetailsID === compId
        );
        const activeuseradmin = activeusercomp.filter(
          (item) => item.SPGroup === "admin"
        );
        const sortedItems = activeuseradmin.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItems);
        setSPUserName(sortedItems);
      } else {
        let nextToken1 = "";
        let assets1 = [];
        const result = await API.graphql({
          query: queries.listSPUserDetails,
          authMode: "API_KEY",
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
              spcompanydetailsID: {
                eq: compId,
              },
              SPGroup: {
                eq: "admin",
              },
            },
            limit: limit,
          },
        });
        if (result.data.listSPUserDetails.items.length > 0) {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken1 = result.data.listSPUserDetails.nextToken;
          console.log(
            "first token count",
            result.data.listSPUserDetails.items.length
          );
          console.log("nextToken 1", nextToken1);
          if (result.data.listSPUserDetails.items.length > 0) {
            assets1.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await API.graphql({
              query: queries.listSPUserDetails,
              authMode: "API_KEY",
              variables: {
                filter: {
                  status: {
                    eq: "Active",
                  },
                  spcompanydetailsID: {
                    eq: compId,
                  },
                  SPGroup: {
                    eq: "admin",
                  },
                },
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.listSPUserDetails.items.length > 0) {
              nextToken1 = results.data.listSPUserDetails.nextToken;
              console.log("nextToken", nextToken1);
              console.log(results.data.listSPUserDetails.items.length);
              if (results.data.listSPUserDetails.items.length > 0) {
                assets1.push(results.data.listSPUserDetails.items);
              }
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            console.log(mergeAssets1);
            //-
            // const spDetails2 = mergeAssets1.filter((item) => {
            //   const _matchSites = spuser?.find(
            //     (user) => user.id === item?.id
            //   );
            //   if (_matchSites) {
            //     return item;
            //   }
            // // });
            // console.log(mergeAssets1);
            setSPUserName(mergeAssets1);
            //---
          }
        }
      }
    } else {
      setSPUserName([]);
    }
  }

  // sp company filtered sp users for sp login
  async function fetchSpuserforSpCompsp(compId) {
    if (compId.length > 0) {
      //---
      if (localStorage.getItem("spuser") !== null) {
        let activeAssets = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
        const electrician = activeAssets.filter(
          (item) => item.userGroup === "electrician"
        );
        const activeuser = electrician.filter(
          (item) => item.status === "Active"
        );
        const activeusercomp = activeuser.filter(
          (item) => item.spcompanydetailsID === compId
        );
        const sortedItems = activeusercomp.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItems);
        setSPUserName(sortedItems);
      } else {
        let nextToken1 = "";
        let assets1 = [];
        const result = await API.graphql({
          query: queries.listSPUserDetails,
          authMode: "API_KEY",
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
              spcompanydetailsID: {
                eq: compId,
              },
            },
            limit: limit,
          },
        });
        if (result.data.listSPUserDetails.items.length > 0) {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken1 = result.data.listSPUserDetails.nextToken;
          console.log(
            "first token count",
            result.data.listSPUserDetails.items.length
          );
          console.log("nextToken 1", nextToken1);
          if (result.data.listSPUserDetails.items.length > 0) {
            assets1.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await API.graphql({
              query: queries.listSPUserDetails,
              authMode: "API_KEY",
              variables: {
                filter: {
                  status: {
                    eq: "Active",
                  },
                  spcompanydetailsID: {
                    eq: compId,
                  },
                },
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.listSPUserDetails.items.length > 0) {
              nextToken1 = results.data.listSPUserDetails.nextToken;
              console.log("nextToken", nextToken1);
              console.log(results.data.listSPUserDetails.items.length);
              if (results.data.listSPUserDetails.items.length > 0) {
                assets1.push(results.data.listSPUserDetails.items);
              }
            }
          }
          console.log(assets1);
          if (assets1.length > 0) {
            const mergeAssets1 = assets1.flat(1);
            console.log(mergeAssets1);
            //-
            // const spDetails2 = mergeAssets1.filter((item) => {
            //   const _matchSites = spuser?.find(
            //     (user) => user.id === item?.id
            //   );
            //   if (_matchSites) {
            //     return item;
            //   }
            // // });
            // console.log(mergeAssets1);
            setSPUserName(mergeAssets1);
            //---
          }
        }
      }
    } else {
      setSPUserName([]);
    }
  }

  useEffect(() => {
    //fetchSPLogin();
    //fetchTestingDemo();
    if (LoginUserGroup === "electrician") {
      fetchSpuserforSpCompsp(compID);
    } else if (LoginUserGroup === "site_user") {
      fetchSpuserforSpCompsp(compID);
    } else if (LoginUserGroup === "spao") {
      fetchSpuserforSpCompsp(compID);
    } else {
      fetchSpuserforSpComp(compID);
    }
    console.log(compID);
    return () => API.cancel();
  }, [compID]);

  //--- Site Filter For Open Tasks ----
  const handleSiteOpen = (event) => {
    try {
      const selectedsite = event.value;
      setSiteAssets(selectedsite);
      if (selectedsite === "") {
        setTasksDemo(opentaskFilter);
        setTasks(cloneDeep(opentaskFilter.slice(0, countPerPage)));
        //--completed task
        setTasksCompletedDemo(completedtaskFilter);
        setTaskExportCompleted(completedtaskFilter);
        setTasksCompleted(
          cloneDeep(completedtaskFilter.slice(0, countPerPage))
        );
        //--hold---
        setHoldTasksDemo(holdtaskFilter);
        setTaskExportHold(holdtaskFilter);
        setHoldTasks(cloneDeep(holdtaskFilter.slice(0, countPerPage)));
        //---future task
        setPassTestDemo(nonAssignedcatFilter);
        settasksExportNonAssigned(nonAssignedcatFilter);
        setPassTest(cloneDeep(nonAssignedcatFilter.slice(0, countPerPage)));
      } else {
        const filteredTasks = opentaskFilter.filter((sp) => {
          //console.log(sp.taskCompletionLink);
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0]?.site;
          return assets.includes(selectedsite);
        });
        console.log(filteredTasks);
        setTasksDemo(filteredTasks);
        setTasksExport(filteredTasks);
        setTasks(cloneDeep(filteredTasks.slice(0, countPerPage)));
        //---completed task
        const filteredTestCompleted = completedtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === selectedsite
        );
        console.log(filteredTestCompleted);
        setTasksCompletedDemo(filteredTestCompleted);
        setTaskExportCompleted(filteredTestCompleted);
        setTasksCompleted(
          cloneDeep(filteredTestCompleted.slice(0, countPerPage))
        );
        //--hold---
        const filteredTestHold = holdtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === selectedsite
        );
        console.log(filteredTestHold);
        setHoldTasksDemo(filteredTestHold);
        setTaskExportHold(filteredTestHold);
        setHoldTasks(cloneDeep(filteredTestHold.slice(0, countPerPage)));
        //---assets for site
        getAssetsBySite(selectedsite);

        //---future task filter
        const filterNonAssigned = nonAssignedcatFilter.filter(
          (sp) => sp.siteId === selectedsite
        );
        console.log(filterNonAssigned);
        setPassTestDemo(filterNonAssigned);
        settasksExportNonAssigned(filterNonAssigned);
        setPassTest(cloneDeep(filterNonAssigned.slice(0, countPerPage)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getAssetsBySite(siteId) {
    try {
      const response = await API.graphql({
        query: queries.assetsBySitedetailsID,
        authMode: "API_KEY",
        variables: {
          sitedetailsID: siteId,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      });
      if (response.data.assetsBySitedetailsID.items.length > 0) {
        let nextToken = response.data.assetsBySitedetailsID.nextToken;
        let sites = [];
        if (response.data.assetsBySitedetailsID.items.length > 0) {
          sites.push(response.data.assetsBySitedetailsID.items);
        }
        while (nextToken !== null) {
          const assetResponse = await API.graphql({
            query: queries.assetsBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: siteId,
              nextToken: nextToken,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: limit,
            },
          });
          if (assetResponse.data.assetsBySitedetailsID.items.length > 0) {
            nextToken = assetResponse.data.assetsBySitedetailsID.nextToken;
            if (assetResponse.data.assetsBySitedetailsID.items.length > 0) {
              sites.push(assetResponse.data.assetsBySitedetailsID.items);
            }
          }
        }
        if (sites.length > 0) {
          let mergeSites = sites.flat(1);
          console.log(mergeSites);
          setAssetsDropDown(mergeSites);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlecompanyOpenSP = (event) => {
    console.log(event);
    console.log(event.value);
    const selectedcomp = event.value;
    console.log(selectedcomp);
    setCompId(selectedcomp);
  };

  //-- sp login sp users to add open tasks
  async function fetchSPLoginCompany() {
    //--
    const siteglobal = sites.filter((item) => item.id === selectedSiteGlobal);
    const sitesss = selectedSiteGlobal ? siteglobal : sites;
    console.log(sites);
    const data = sitesss.filter(
      (item) => item.assetownerID === selectedAssetOwnerGlobal
    );
    const filterdata = selectedAssetOwnerGlobal ? data : sitesss;
    console.log(filterdata);

    //--
    let nextToken = "";
    let assets = [];
    if (selectedAssetOwnerGlobal || selectedSiteGlobal) {
      for (let item of filterdata) {
        const result = await API.graphql({
          query: queries.linkUsersAndSitesBySitedetailsID,
          authMode: "API_KEY",
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
        });
        if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.linkUsersAndSitesBySitedetailsID.items;
          nextToken = result.data.linkUsersAndSitesBySitedetailsID.nextToken;
          console.log(
            "first token count",
            result.data.linkUsersAndSitesBySitedetailsID.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await API.graphql({
              query: queries.linkUsersAndSitesBySitedetailsID,
              authMode: "API_KEY",
              variables: {
                sitedetailsID: item.id,
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (
              results.data.linkUsersAndSitesBySitedetailsID.items.length > 0
            ) {
              nextToken =
                results.data.linkUsersAndSitesBySitedetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(
                results.data.linkUsersAndSitesBySitedetailsID.items.length
              );
              if (
                results.data.linkUsersAndSitesBySitedetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesBySitedetailsID.items
                );
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            const spDetailss = mergeAssets;
            console.log(spDetailss);
            const response1 = await API.graphql({
              query: queries.listSPUserDetails,
              authMode: "API_KEY",
              variables: {
                filter: {
                  userGroup: {
                    eq: "electrician",
                  },
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
              },
            });
            if (response1.data.listSPUserDetails.items.length > 0) {
              console.log(LoggedInUserId);
              const spDetails2 = response1.data.listSPUserDetails.items.filter(
                (item) => {
                  const _matchSites = spDetailss?.find(
                    (user) => user.userdetailsID === item?.id
                  );
                  if (_matchSites) {
                    return item;
                  }
                }
              );
              console.log(spDetails2);
              const sortedItems = spDetails2.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              console.log(sortedItems);
              setSPUserName3(sortedItems);
            }
          }
        }
        return () => API.cancel(result);
      }
    } else {
      if (localStorage.getItem("spuser") !== null) {
        let activeAssets = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
        console.log(activeAssets);
        const electrician = activeAssets.filter(
          (item) => item.userGroup === "electrician"
        );
        const activeuser = electrician.filter(
          (item) => item.status === "Active"
        );
        const sortedItems = activeuser.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortedItems);
        setSPUserName3(sortedItems);
      }
    }
  }

  const [activeTab, setActiveTab] = useState("open_tasks");
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey.target.value);
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  const optionsAssets = [
    { value: "", label: "Select Assets" },
    ...assetsDropdown.map((item) => ({
      value: item.id,
      label: item.fittingNumber,
    })),
  ];

  const optionsSitesOpen = [
    { value: "", label: "Select Site" },
    ...sites.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const optionsCompOpen = [
    { value: "", label: "Select Company" },
    ...spCompany.map((item) => ({
      value: LoginUserGroup === "spao" ? item.id : item.spcomapnyId,
      label: item.companyName,
    })),
  ];

  const optionsCompNonAssigned = [
    { value: "", label: "Select Company" },
    ...spCompany.map((item) => ({
      value: LoginUserGroup === "spao" ? item.id : item.spcomapnyId,
      label: item.companyName,
    })),
  ];

  const optionsStatus = [
    { value: "", label: "Select Status" },
    { value: "Assigned", label: "Assigned" },
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Hold", label: "Hold" },
  ];

  const optionsSPOpen = [
    { value: "", label: "Select Task Assignee" },
    ...sPUserName.map((item) => ({
      value: item.id,
      //label: item.fname + " " + item.lname,
      label: item.email,
    })),
  ];

  const optionsCategory = [
    { value: "", label: "Select Category" },
    ...categoryDetails.map((item) => ({
      value: item.id,
      label: item.categoryName,
    })),
  ];

  //--- category dropdown
  const handleCategory = (event) => {
    console.log(event.value);
    const cat = event.value;
    if (cat === "") {
      getAssetsBySite(siteassets);
      if (siteassets === "") {
        setTasksDemo(opentaskFilter);
        setTasks(cloneDeep(opentaskFilter.slice(0, countPerPage)));
        //-- completed tasks
        setTasksCompletedDemo(completedtaskFilter);
        setTasksCompleted(
          cloneDeep(completedtaskFilter.slice(0, countPerPage))
        );
        //--hold tasks---
        setHoldTasksDemo(holdtaskFilter);
        setHoldTasks(cloneDeep(holdtaskFilter.slice(0, countPerPage)));
        //--non assigned task
        setPassTestDemo(nonAssignedcatFilter);
        setPassTest(cloneDeep(nonAssignedcatFilter.slice(0, countPerPage)));
      } else {
        const filteredTasks = opentaskFilter.filter((sp) => {
          console.log(sp.taskCompletionLink);
          const completionLink = JSON.parse(sp.taskCompletionLink);
          const assets = completionLink[0]?.site;
          return assets.includes(siteassets);
        });
        console.log(filteredTasks);
        setTasksDemo(filteredTasks);
        setTasksExport(filteredTasks);
        setTasks(cloneDeep(filteredTasks.slice(0, countPerPage)));
        //----completed---
        const filteredTestCompleted = completedtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === siteassets
        );
        console.log(filteredTestCompleted);
        setTasksCompletedDemo(filteredTestCompleted);
        setTaskExportCompleted(filteredTestCompleted);
        setTasksCompleted(
          cloneDeep(filteredTestCompleted.slice(0, countPerPage))
        );
        //--hold---
        const filteredTestHold = holdtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === siteassets
        );
        console.log(filteredTestHold);
        setHoldTasksDemo(filteredTestHold);
        setTaskExportHold(filteredTestHold);
        setHoldTasks(cloneDeep(filteredTestHold.slice(0, countPerPage)));
        //--non assigned task
        //setPassTestDemo(nonAssignedcatFilter);
        //setPassTest(cloneDeep(nonAssignedcatFilter.slice(0, countPerPage)));
        const filterNonAssigned = nonAssignedcatFilter.filter(
          (sp) => sp.siteId === siteassets
        );
        console.log(filterNonAssigned);
        setPassTestDemo(filterNonAssigned);
        settasksExportNonAssigned(filterNonAssigned);
        setPassTest(cloneDeep(filterNonAssigned.slice(0, countPerPage)));
      }
    } else {
      //---
      getAssetsBySiteCat(cat);
      if (siteassets === "") {
        const filteredTest = opentaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].category === cat
        );
        console.log(filteredTest);
        setTasksDemo(filteredTest);
        setTasksExport(filteredTest);
        setTasks(cloneDeep(filteredTest.slice(0, countPerPage)));
        //---completed tasks
        const filteredTestCompleted = completedtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].category === cat
        );
        console.log(filteredTestCompleted);
        setTasksCompletedDemo(filteredTestCompleted);
        setTaskExportCompleted(filteredTestCompleted);
        setTasksCompleted(
          cloneDeep(filteredTestCompleted.slice(0, countPerPage))
        );
        //---hold tasks
        const filteredTestHold = holdtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].category === cat
        );
        console.log(filteredTestHold);
        setHoldTasksDemo(filteredTestHold);
        setTaskExportHold(filteredTestHold);
        setHoldTasks(cloneDeep(filteredTestHold.slice(0, countPerPage)));
        //--Non Assigned
        const filterNonAssigned = nonAssignedcatFilter.filter(
          (sp) => sp.categoriesID === cat
        );
        console.log(filterNonAssigned);
        setPassTestDemo(filterNonAssigned);
        settasksExportNonAssigned(filterNonAssigned);
        setPassTest(cloneDeep(filterNonAssigned.slice(0, countPerPage)));
      } else {
        //---open task
        const filteredTest = opentaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === siteassets
        );
        const catFilter = filteredTest.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].category === cat
        );
        console.log(catFilter);
        setTasksDemo(catFilter);
        setTasksExport(catFilter);
        setTasks(cloneDeep(catFilter.slice(0, countPerPage)));
        //--completed task--
        const filteredTestCompletedsite = completedtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === siteassets
        );
        const filteredTestCompleted = filteredTestCompletedsite.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].category === cat
        );
        console.log(filteredTestCompleted);
        setTasksCompletedDemo(filteredTestCompleted);
        setTaskExportCompleted(filteredTestCompleted);
        setTasksCompleted(
          cloneDeep(filteredTestCompleted.slice(0, countPerPage))
        );
        //--hold tasks---
        const filteredTestHoldsite = holdtaskFilter.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].site === siteassets
        );
        const filteredTestHold = filteredTestHoldsite.filter(
          (sp) => JSON.parse(sp.taskCompletionLink)[0].category === cat
        );
        console.log(filteredTestHold);
        setHoldTasksDemo(filteredTestHold);
        setTaskExportHold(filteredTestHold);
        setHoldTasks(cloneDeep(filteredTestHold.slice(0, countPerPage)));
        //--Non Assigned
        const filterNonAssignedsite = nonAssignedcatFilter.filter(
          (sp) => sp.siteId === siteassets
        );
        const filterNonAssigned = filterNonAssignedsite.filter(
          (sp) => sp.categoriesID === cat
        );
        console.log(filterNonAssigned);
        setPassTestDemo(filterNonAssigned);
        settasksExportNonAssigned(filterNonAssigned);
        setPassTest(cloneDeep(filterNonAssigned.slice(0, countPerPage)));
      }
    }
  };

  //---filtering assets for site and cat--
  async function getAssetsBySiteCat(cat) {
    try {
      let assetTempaltesForCategory = assetTemplateData.filter((item) => {
        return item.categoriesID === cat && item.status === "Active";
      });
      if (assetTempaltesForCategory.length > 0) {
        getAssetsByTemplates(assetTempaltesForCategory, siteassets);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAssetsByTemplates(assetTemplates, siteID) {
    try {
      let nextToken = "";
      let assets = [];
      let count = 0;
      console.log(assetTemplates);
      console.log(siteID);
      for (let assetTemplate of assetTemplates) {
        let tempSiteId = assetTemplate.id + "_" + siteID;
        const result = await API.graphql({
          query: queries.assetsByAssettemplateSiteIDAndAssetNum,
          variables: {
            assettemplateSiteID: tempSiteId,
            limit: limit,
            filter: {
              status: {
                eq: "Active",
              },
            },
          },
          authMode: "API_KEY",
        });
        console.log(result);
        // if (
        //   result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0
        // ) {
        let assetDatas =
          result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
        nextToken =
          result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
        if (
          result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0
        ) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: tempSiteId,
              limit: limit,
              nextToken: nextToken,
              filter: {
                status: {
                  eq: "Active",
                },
              },
            },
            authMode: "API_KEY",
          });
          if (
            results.data.assetsByAssettemplateSiteIDAndAssetNum.items.length > 0
          ) {
            nextToken =
              results.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
            if (
              results.data.assetsByAssettemplateSiteIDAndAssetNum.items.length >
              0
            ) {
              assets.push(
                results.data.assetsByAssettemplateSiteIDAndAssetNum.items
              );
            }
          }
        }

        count++;
        console.log(count);
        console.log(assetTemplates.length);
        if (count === assetTemplates.length) {
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            const sort = mergeAssets.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            console.log(sort);
            setAssetsDropDown(sort);
          } else {
            setAssetsDropDown([]);
          }
        }
        //}
        // else {
        //   setAssetsDropDown([]);
        // }
      }
    } catch (err) {
      console.log(err);
    }
  }

  //-----------------------------------------Display------------------------------------------
  const renderList = (
    <Fragment>
      <Container fluid={true}>
        <br></br>
        <Row>
          <Col md="12" className="p-0 project-list">
            <Row className="bordorBottom">
              <Col md="6" className="d-flex">
                <h3 className="page-title">Manage Tasks</h3>
              </Col>
              <Col
                md="6"
                className="d-flex justify-content-md-end justify-content-center p-0"
              >
                <div className="export-button">
                  {userPermission.includes("Export Tasks") && (
                    <Button onClick={() => handleExport()}>
                      <Download />
                      Export
                    </Button>
                  )}
                </div>
                {/* <div className="task-button">
                  {userPermission.includes("Create Tasks") && (
                    <Button
                      outline
                      color="primary"
                      onClick={() => LargeModaltoggle()}
                    >
                      <PlusCircle />
                      Add Tasks
                    </Button>
                  )}
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="p-0 project-list">
            <Row>
              <Col md="4" className="d-flex align-items-center p-0">
                <select onChange={handleTabSelect} className="form-select">
                  <option value="">Select Task Status Page</option>
                  <option value="open_tasks" key="open_tasks">
                    Open Tasks
                  </option>
                  <option value="tasks-completed" key="tasks-completed">
                    Tasks Completed
                  </option>
                  <option value="non_assigned" key="non_assigned">
                    Future Tasks
                  </option>
                  <option value="hold_tasks" key="hold_tasks">
                    Hold Tasks
                  </option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="p-0">
                <Row className="mx-0">
                  <Col md="3">
                    <DateRangePicker
                      onDateRangeSelected={onDateRangeSelected}
                    ></DateRangePicker>
                  </Col>
                  <Col md="3">
                    {selectedSiteGlobal ? (
                      <select
                        className="form-select"
                        value={selectedSiteGlobal}
                        onChange={handleSiteOpen}
                        disabled={true}
                      >
                        <option value="">Select Site</option>
                        {sites.map((item) => (
                          <option value={item.id} key={item.id}>
                            {(item?.siteName || "") +
                              " (" +
                              (item?.unitNumber || "") +
                              ")"}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <Select
                        options={optionsSitesOpen}
                        isSearchable={true}
                        placeholder="Select Site"
                        onChange={handleSiteOpen}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  </Col>
                  <Col md="3">
                    <Select
                      options={optionsCategory}
                      isSearchable={true}
                      placeholder="Select Category"
                      onChange={handleCategory}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </Col>
                  <Col md="3">
                    <Select
                      options={optionsAssets}
                      isSearchable={true}
                      placeholder="Select Asset"
                      onChange={handleAssets}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </Col>
                  <Col md="3">
                    {activeTab !== "non_assigned" && (
                      <Select
                        options={optionsStatus}
                        isSearchable={true}
                        placeholder="Select Status"
                        onChange={handleTaskStatus}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  </Col>

                  {activeTab === "open_tasks" && (
                    <>
                      <Col md="3">
                        {LoginUserGroup === "site_user" && (
                          <Select
                            options={optionsCompOpen}
                            isSearchable={true}
                            placeholder="Select Company"
                            onChange={handlecompanyOpenSP}
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                        {LoginUserGroup === "spao" && (
                          <Select
                            options={optionsCompOpen}
                            isSearchable={true}
                            placeholder="Select Company"
                            onChange={handlecompanyOpenSP}
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      </Col>
                      <Col md="3">
                        {LoginUserGroup === "site_user" && (
                          <Select
                            options={optionsSPOpen}
                            isSearchable={true}
                            value={
                              checkedItemCountOpen > 0
                                ? {
                                    value:
                                      openTaskSpUser.length > 0
                                        ? openTaskSpUser
                                        : "",
                                    label:
                                      openTaskSpUser.length > 0
                                        ? getTaskAssigneeNameemail(
                                            openTaskSpUser
                                          )
                                        : "Select Task Assignee",
                                  }
                                : {
                                    value:
                                      sPUserName2.length > 0
                                        ? sPUserName2[0].id
                                        : null,
                                    label:
                                      sPUserName2.length > 0
                                        ? sPUserName2[0].email
                                        : "Select Task Assignee",
                                  }
                            }
                            onChange={handleSpUser}
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                        {LoginUserGroup === "spao" && (
                          <Select
                            options={optionsSPOpen}
                            isSearchable={true}
                            value={
                              checkedItemCountOpen > 0
                                ? {
                                    value:
                                      openTaskSpUser.length > 0
                                        ? openTaskSpUser
                                        : "",
                                    label:
                                      openTaskSpUser.length > 0
                                        ? getTaskAssigneeNameemail(
                                            openTaskSpUser
                                          )
                                        : "Select Task Assignee",
                                  }
                                : {
                                    value:
                                      sPUserName2.length > 0
                                        ? sPUserName2[0].id
                                        : null,
                                    label:
                                      sPUserName2.length > 0
                                        ? sPUserName2[0].email
                                        : "Select Task Assignee",
                                  }
                            }
                            onChange={handleSpUser}
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      </Col>
                      {(LoginUserGroup === "site_user" ||
                        LoginUserGroup === "spao") && (
                        <Col md="2" className="ps-0 pe-2 mb-2">
                          {isCheckboxCheckedOpen &&
                            openTaskSpUser.length > 0 &&
                            userPermission.includes("Assign Tasks") && (
                              <Btn
                                attrBtn={{
                                  color: "primary",
                                  onClick: assign,
                                }}
                                disabled={isAssignLoad}
                              >
                                {isAssignLoad ? "Assigning..." : "Assign"}
                              </Btn>
                            )}
                        </Col>
                      )}
                      <Col md="1" className="ps-0 pe-2 mb-2"></Col>
                    </>
                  )}
                  {activeTab === "non_assigned" && (
                    <>
                      <Row>
                        {(LoginUserGroup === "site_owner" ||
                          LoginUserGroup === "site_user") && (
                          <>
                            <Col md="3" className="ps-0 pe-2 mb-2">
                              {checkedItemCount > 0 && (
                                <Select
                                  options={optionsCompNonAssigned}
                                  isSearchable={true}
                                  placeholder="Select Company"
                                  onChange={handleDropdownChangeComp}
                                  menuPlacement="auto"
                                  menuPosition="fixed"
                                />
                              )}
                            </Col>
                            <Col md="3" className="ps-0 pe-2 mb-2">
                              {isCheckboxChecked && compID.length > 0 && (
                                <Btn
                                  attrBtn={{ color: "primary", onClick: save }}
                                >
                                  Save
                                </Btn>
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        {(LoginUserGroup === "electrician" ||
                          LoginUserGroup === "spao") && (
                          <>
                            <Col md="3" className="ps-0 pe-2 mb-2">
                              {checkedItemCount > 0 && (
                                <select
                                  className="form-control"
                                  name="taskAssignee"
                                  placeholder="Choose Task Assignee"
                                  value={selectedAssignee}
                                  {...register("taskAssignee", {
                                    required: true,
                                  })}
                                  onChange={handleDropdownChange}
                                >
                                  <option value="">Select Task Assignee</option>
                                  {sPUserName3.map((item) => (
                                    <option value={item.id} key={item.id}>
                                      {item.email}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </Col>
                            <Col md="3" className="ps-0 pe-2 mb-2">
                              {isCheckboxChecked && selectedAssignee && (
                                <Btn
                                  attrBtn={{ color: "primary", onClick: save }}
                                >
                                  Save
                                </Btn>
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                </Row>
                {activeTab === "open_tasks" && (
                  <div>
                    <br></br>
                    <Card className="custom-card pd5">
                      <h3>Open Tasks</h3>
                      <br></br>
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage1}
                        current={currentPage}
                        total={
                          selectedAssetNew !== "" || selectedStatus !== ""
                            ? taskFilterAssetStatus.length
                            : tasksDemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                      <br></br>
                      <div className="table-responsive">
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            <tr>
                              {(LoginUserGroup === "site_user" ||
                                LoginUserGroup === "spao") && (
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    checked={isAllSelectedOpen}
                                    onChange={(event) =>
                                      handleCheckboxChangeOpen(
                                        event,
                                        "selectAll"
                                      )
                                    }
                                  />
                                </th>
                              )}
                              <th scope="col">Added On</th>
                              <th scope="col">Task Type</th>
                              <th scope="col">Site</th>
                              <th scope="col">Category</th>
                              <th scope="col">Task Status</th>
                              <th scope="col">Task Assignee</th>
                              <th scope="col">Task Assigned By</th>
                              <th scope="col">Task Assigned Date</th>
                              <th scope="col">Due Date</th>
                              {(LoginUserGroup === "site_owner" ||
                                LoginUserGroup === "site_user") && (
                                <th scope="col">Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {tasks.length > 0 ? (
                              tasks
                                .sort(
                                  (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                                )
                                .map((item) => (
                                  <tr key={item.id}>
                                    {(LoginUserGroup === "site_user" ||
                                      LoginUserGroup === "spao") && (
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="testPass"
                                          checked={
                                            isAllSelectedOpen ||
                                            selectedItemsOpen.includes(item)
                                          }
                                          onChange={(event) =>
                                            handleCheckboxChangeOpen(
                                              event,
                                              item
                                            )
                                          }
                                        />
                                      </td>
                                    )}
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.taskType}</td>
                                    <td>
                                      {getSiteUnitNumber(
                                        JSON.parse(item.taskCompletionLink)[0]
                                          .site
                                      )}
                                    </td>
                                    <td>
                                      {getCategory(
                                        JSON.parse(item.taskCompletionLink)[0]
                                          .category
                                      )}
                                    </td>
                                    <td>
                                      <p className="open_tasks_status">
                                        {item.taskStatus}
                                      </p>
                                    </td>
                                    <td>
                                      {getTaskAssigneeName(item.taskAssignee)}
                                    </td>
                                    <td>
                                      {getTaskAssigneeBy(item.assignedBy)}
                                    </td>
                                    <td>
                                      {moment(item.assignedDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {item.dueDate !== null
                                        ? moment(item.dueDate).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      {(LoginUserGroup === "site_owner" ||
                                        LoginUserGroup === "site_user") && (
                                        <Btn
                                          attrBtn={{
                                            className:
                                              "btn-outline-* btn-xs m-r-5",
                                            color: "info",
                                            title: "Edit",
                                            onClick: () => handleEdit(item),
                                          }}
                                        >
                                          <i className="fa fa-edit "></i>
                                        </Btn>
                                      )}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No Open Tasks Available.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </tbody>
                        </Table>
                        {/* {hasMore && <div>Loading more items...</div>} */}
                      </div>
                    </Card>
                    <CardFooter className="row text-right"></CardFooter>
                  </div>
                )}
                {activeTab === "tasks-completed" && (
                  <div>
                    <br></br>
                    <Card className="custom-card pd5">
                      <h3>Completed Tasks</h3>
                      <br></br>
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage2}
                        current={currentPage1}
                        total={
                          selectedAssetNew !== "" || selectedStatus !== ""
                            ? taskCompletedFilterAssetStatus.length
                            : tasksCompletedDemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                      <br></br>
                      <div className="table-responsive">
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            {" "}
                            <tr>
                              <th scope="col">Added On</th>
                              <th scope="col">Task Type</th>
                              <th scope="col">Site</th>
                              <th scope="col">Category</th>
                              <th scope="col">Task Status</th>
                              <th scope="col">Task Assignee</th>
                              <th scope="col">Task Assigned By</th>
                              <th scope="col">Task Assigned Date</th>
                              <th scope="col">Due Date</th>
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {tasksCompleted && tasksCompleted.length > 0 ? (
                              tasksCompleted
                                .sort(
                                  (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                                )
                                .map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.taskType}</td>
                                    <td>
                                      {getSiteUnitNumber(
                                        JSON.parse(item.taskCompletionLink)[0]
                                          .site
                                      )}
                                    </td>
                                    <td>
                                      {getCategory(
                                        JSON.parse(item.taskCompletionLink)[0]
                                          .category
                                      )}
                                    </td>
                                    <td>
                                      <p className="completed_tasks_status">
                                        {item.taskStatus}
                                      </p>
                                    </td>
                                    <td>
                                      {getTaskAssigneeName(item.taskAssignee)}
                                    </td>
                                    <td>
                                      {getTaskAssigneeBy(item.assignedBy)}
                                    </td>
                                    <td>
                                      {moment(item.assignedDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.dueDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No Completed Tasks Available.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </tbody>
                        </Table>
                        {/* {hasMore && <div>Loading more items...</div>} */}
                      </div>
                    </Card>
                    <CardFooter className="row text-center"></CardFooter>
                  </div>
                )}
                {activeTab === "non_assigned" && (
                  <div>
                    <br></br>
                    <Card className="custom-card pd5">
                      <h3>Future Tasks</h3>
                      <br></br>
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage3}
                        current={currentPage2}
                        total={
                          selectedAssetNew !== ""
                            ? nonAssignedFilter.length
                            : passTestDemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                      <br></br>
                      <div className="table-responsive">
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            <tr>
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  checked={isAllSelected}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, "selectAll")
                                  }
                                />
                              </th>
                              <th scope="col">Added On</th>
                              <th scope="col">Test Result</th>
                              <th scope="col">Fitting Number</th>
                              <th scope="col">Site</th>
                              <th scope="col">Category</th>
                              <th scope="col">Test On</th>
                              <th scope="col">Next Test Schedule Date</th>
                              <th scope="col">Additional Information</th>
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {passTest.length > 0 ? (
                              passTest.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="testPass"
                                      checked={
                                        isAllSelected ||
                                        selectedItems.includes(item)
                                      }
                                      onChange={(event) =>
                                        handleCheckboxChange(event, item)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>{item.testResult}</td>
                                  <td>{item.assetsID}</td>
                                  <td>{getSiteUnitNumber(item.siteId)}</td>
                                  <td>{getCategory(item.categoriesID)}</td>
                                  <td>
                                    {moment(item.testOn).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {moment(item.nextTest).format("DD/MM/YYYY")}
                                  </td>
                                  <td>{item.additionalInformation}</td>
                                </tr>
                              ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No Test Data Available.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Card>
                    <CardFooter className="row text-center"></CardFooter>
                  </div>
                )}
                {activeTab === "hold_tasks" && (
                  <div>
                    <br></br>
                    <Card className="custom-card pd5">
                      <h3>Hold Tasks</h3>
                      <br></br>
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePageHold}
                        current={currentPage4}
                        total={
                          selectedAssetNew !== "" || selectedStatus !== ""
                            ? taskHoldFilterAssetStatus.length
                            : holdtasksdemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                      <br></br>
                      <div className="table-responsive">
                        <Table className="table-striped  table-de">
                          <thead className="custom-table-head">
                            <tr>
                              <th scope="col">Added On</th>
                              <th scope="col">Task Type</th>
                              <th scope="col">Site</th>
                              <th scope="col">Category</th>
                              <th scope="col">Task Status</th>
                              <th scope="col">Task Assignee</th>
                              <th scope="col">Task Assigned By</th>
                              <th scope="col">Task Assigned Date</th>
                              <th scope="col">Due Date</th>
                              {(LoginUserGroup === "site_owner" ||
                                LoginUserGroup === "site_user") && (
                                <th scope="col">Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {holdTasks && holdTasks.length > 0 ? (
                              holdTasks
                                .sort(
                                  (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                                )
                                .map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.taskType}</td>
                                    <td>
                                      {getSiteUnitNumber(
                                        JSON.parse(item.taskCompletionLink)[0]
                                          .site
                                      )}
                                    </td>
                                    <td>
                                      {getCategory(
                                        JSON.parse(item.taskCompletionLink)[0]
                                          .category
                                      )}
                                    </td>
                                    <td>
                                      <p className="hold_tasks_status">
                                        {item.taskStatus}
                                      </p>
                                    </td>
                                    <td>
                                      {getTaskAssigneeName(item.taskAssignee)}
                                    </td>
                                    <td>
                                      {getTaskAssigneeBy(item.assignedBy)}
                                    </td>
                                    <td>
                                      {moment(item.assignedDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.dueDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {(LoginUserGroup === "site_owner" ||
                                        LoginUserGroup === "site_user") && (
                                        <Btn
                                          attrBtn={{
                                            className:
                                              "btn-outline-* btn-xs m-r-5",
                                            color: "info",
                                            title: "Edit",
                                            onClick: () => handleEditHold(item),
                                          }}
                                        >
                                          <i className="fa fa-edit "></i>
                                        </Btn>
                                      )}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No Hold Tasks Available.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </tbody>
                        </Table>
                        {/* {hasMore && <div>Loading more items...</div>} */}
                      </div>
                    </Card>
                    <CardFooter className="row text-center"></CardFooter>
                  </div>
                )}
              </Col>
            </Row>
            {state.state && state.state.site && state.state.category && (
              <Row>
                <Col md="6"></Col>
                <Col md="6" className="text-end">
                  <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                    Back
                  </Btn>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
export default TasksContainSp;
