import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Media,
  Label,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { Btn, H4, H1, Badges, H6 } from "../../../AbstractElements";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { SiteContext } from "../../../App";
import { Grid } from "react-feather";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { limit } from "../../../Config/Config";
import useInfiniteLoading from "./infineteloop";
import SiteDBData from "../../../Data/DistributionBoard/index";
import SiteData from "../../../Data/Sites/index";
import CategoryData from "../../../Data/Category/index";
import LoginForm from "../../Pages/Auth/LoginForm";
var base64 = require("base-64");

const AssetsListDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [fullAssets, setFullAssets] = useState([]);
  const [value, setValue] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [sites, setSites] = useState();
  const [siteDetails, setSiteDetails] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
    LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [assetView, setAssetView] = useState("list");
  const LoginUserGroup = localStorage.getItem("groups");
  const countPerPageCard = 8;
  const [currentPageCard, setCurrentPageCard] = useState(1);
  const [assetDemo, setAssetsDemo] = React.useState([]);
  const [assetDemoCard, setAssetsDemoCard] = React.useState([]);
  const [collectionCard, setCollectionCard] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 10;
  const [filteredData, setFilteredData] = React.useState([]);
  const [assetTemplatData, setAssetTemplatData] = React.useState([]);
  const [activedata, setactiveData] = React.useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [deleteAsset, setDelete] = useState(false);
  const [deleteAllAsset, setDeleteAllAssets] = useState(false);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [assetsCount, setAssetsCount] = useState([]);
  const [subscriptionPlan, setsubscriptionplan] = useState([]);
  const [addOn, setAddOn] = useState([]);
  const [assetPlancount, setAssetplancount] = useState([]);
  const [searchAssets, setSearchAssets] = useState(null);
  const [searchItems, setSearchItems] = useState([]);
  const [fullItems, setFullItems] = useState([]);
  //const [limit, setLimit] = useState(100000);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [isSecoundSearch, setIsSecoundSearch] = useState(false);
  const [oldSiteId, setOldSiteId] = useState(false);
  const [assetscountbysite, setAssetscountbysite] = useState([]);
  const [searchText, setSearchtext] = useState(false);
  //let assetRecords = [];
  const assetRecordsRef = useRef([]);
  const assetRecordsSearchRef = useRef([]);
  const deleteToggle = () => {
    console.log(selectedItemsAsset);
    selectedItemsAsset.length > 0
      ? setDeleteModal(!deleteModal)
      : setDeleteModal(false);
  };
  const deleteAllToggle = () => {
    setDeleteAllModal(!deleteAllModal);
  };
  const [assetsCountBySite, setAssetsCountBySite] = useState(
    CategoryData.getCategoryAssetCounts()
  );
  console.log(assetsCountBySite);
  const catid =
    state.state && state.state.category ? state.state.category.id : "";
  const [items, setItems] = useState([]);
  const [siteIndex, setsSiteIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const nextTokenRef = useRef(null);
  const isDataLoading = useRef(false);
  var isReset = false;
  useEffect(() => {
    try {
      if (LoginUserGroup === "site_user") {
        fetchSubscriptionListSUAssetsCount();
      } else {
        if (localStorage.getItem("categoryAssetCount") !== null) {
          let activeAssets = JSON.parse(
            base64.decode(localStorage.getItem("categoryAssetCount"))
          );
          console.log(activeAssets);

          if (assetsCountBySite.length > 0) {
            const totalAssetCount = assetsCountBySite.reduce(
              (acc, item) => acc + item.assetCount,
              0
            );
            console.log(totalAssetCount);
            setAssetsCount(totalAssetCount);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [localStorage.getItem("categoryAssetCount")]);

  console.log(assetsCount);
  const containerRef = useRef(null);
  const handleScroll = () => {
    if (searchAssets) {
      if (!containerRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore) {
        //searchAssetsMore();
        getAssetsLoopSearch();
      }
    } else {
      if (!containerRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore) {
        getAssetsLoop();
      }
    }
  };
  const updatePageCard = (p) => {
    setCurrentPageCard(p);
    const to = countPerPageCard * p;
    const from = to - countPerPageCard;
    const data =
      value ||
      (state.state && state.state.site ? false : selectedSite) ||
      selectedLocation ||
      selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
        ? assetDemoCard
        : assetDemoCard;
    setCollectionCard(cloneDeep(data.slice(from, to)));
  };
  async function fetchSiteDetailsAO() {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let sites = [];
      await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await API.graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: LoginUserDetails.id,
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            setSiteDetails(
              mergeSites.filter((item) => item.status === "Active")
            );
            SiteData.setSiteData(mergeSites);
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(mergeSites))
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setSiteDetails([]);
          SiteData.setSiteData([]);
          //setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
      //setIsLoading(false);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSPSU(assetOwnerId) {
    //setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: assetOwnerId,
        limit: limit,
      },
    })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: assetOwnerId,
              limit: limit,
              nextToken: nextToken,
            },
          })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          const siteDetails = mergeAssets;
          let nextToken1 = "";
          let assets1 = [];
          await API.graphql({
            query: queries.listSiteDetails,
            variables: {
              limit: limit,
            },
            authMode: "API_KEY",
          })
            .then(async (result) => {
              let assetDatas = result.data.listSiteDetails.items;
              nextToken1 = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await API.graphql({
                  query: queries.listSiteDetails,
                  variables: {
                    limit: limit,
                    nextToken: nextToken1,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken1 = results.data.listSiteDetails.nextToken;
                    if (results.data.listSiteDetails.items.length > 0) {
                      assets1.push(results.data.listSiteDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                const siteDetail = mergeAssets1.filter((item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                //fetchlistDistributionBox(siteDetail);
                setSiteDetails(
                  siteDetail.filter((item) => item.status === "Active")
                );
                localStorage.setItem(
                  "site",
                  base64.encode(JSON.stringify(siteDetail))
                );
                SiteData.setSiteData(siteDetail);
              }
            })
            .catch((error) => {
              setSiteDetails([]);
              console.log(error);
              //setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        setSiteDetails([]);
        console.log(error);
        //setIsLoading(false);
      });
  }
  // --- site user assets ---
  async function fetchSitesDetailsSU() {
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: 100000,
      },
      authMode: "API_KEY",
    }).then(async (result) => {
      const sites = result.data.linkUsersAndSitesByUserdetailsID.items;
      if (sites.length > 0) {
        const promises = sites.map(async (item) => {
          return API.graphql({
            query: queries.getSiteDetails,
            variables: {
              id: item.sitedetailsID,
            },
          });
        });
        Promise.all(promises)
          .then((results) => {
            console.log(results);
            let siteData = [];
            if (results.length > 0) {
              results.map((site) => {
                if (site.data.getSiteDetails !== null)
                  siteData.push(site.data.getSiteDetails);
              });
              if (siteData.length > 0) {
                const siteOptions = [];
                if (state.state) {
                  getAssetTemplate(siteData);
                }
                let activeSites = siteData.filter(
                  (item) => item.status === "Active"
                );
                console.log("activeSites", activeSites);
                setSiteDetails(activeSites);
                for (let site of activeSites) {
                  siteOptions.push({
                    value: site.id,
                    label:
                      site.siteName +
                      " " +
                      (site.unitNumber ? site.unitNumber : " "),
                  });
                }
                setSites(siteOptions);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSites([]);
        setIsLoading(false);
        setErrorMessage("No Assets to fetch");
      }
    });
  }
  async function fetchSitesDetails() {
    console.log(limit);
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    }).then(async (result) => {
      const sites = result.data.siteDetailsByAssetownerID.items;
      let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
      let siteData = [];
      if (sites.length > 0) {
        siteData.push(sites);
      }
      while (nextToken !== null) {
        await API.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        })
          .then((result) => {
            nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            if (result.data.siteDetailsByAssetownerID.items.length > 0) {
              siteData.push(result.data.siteDetailsByAssetownerID.items);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (siteData.length > 0) {
        let mergeSites = siteData.flat(1);
        let activeSites = mergeSites.filter((item) => item.status === "Active");
        const siteFilterArray = [];
        const siteOptions = [];
        let siteFilter = [];
        if (state.state && state.state.site) {
          siteFilter = activeSites.filter(
            (site) => site.id === state.state.site.id
          );
        }
        const globalsite = activeSites.filter(
          (site) => site.id === selectedSiteGlobal
        );
        console.log(globalsite);
        const dataFilter = selectedSiteGlobal ? globalsite : siteFilter;
        if (state.state) {
          dataFilter.length > 0
            ? getAssetTemplate(dataFilter)
            : getAssetTemplate(activeSites);
        }
        dataFilter.length > 0
          ? setSiteDetails(dataFilter)
          : setSiteDetails(activeSites);
        activeSites.map(async (item) => {
          siteFilterArray.push(item.id);
          siteOptions.push({
            value: item.id,
            label:
              item.siteName + " " + (item.unitNumber ? item.unitNumber : " "),
          });
        });
        setSites(siteOptions);
      } else {
        setIsLoading(false);
        setErrorMessage("No Assets to fetch");
      }
    });
  }
  const getAssetTemplate = async (siteData) => {
    console.log(siteData);
    await API.graphql({
      query: queries.assetTemplatesByCategoriesID,
      variables: {
        categoriesID: state.state.category.id,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result.data.assetTemplatesByCategoriesID.items);
        if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
          setAssetTemplatData(result.data.assetTemplatesByCategoriesID.items);
          // getAssetsByTemplates(
          //   result.data.assetTemplatesByCategoriesID.items,
          //   siteData
          // );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log("select global Site");
    async function fetchlistDistributionBoxSU() {
      try {
        let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
        let activeSites = sites.filter((item) => item.status === "Active");
        if (activeSites.length > 0) {
          let nextToken = "";
          let db = [];
          for (let site of activeSites) {
            const DBResult = await API.graphql({
              query: queries.distributionBoxesBySitedetailsID,
              variables: {
                sitedetailsID: site.id,
              },
              authMode: "API_KEY",
            });
            let assetDatas =
              DBResult.data.distributionBoxesBySitedetailsID.items;
            nextToken =
              DBResult.data.distributionBoxesBySitedetailsID.nextToken;
            if (
              DBResult.data.distributionBoxesBySitedetailsID.items.length > 0
            ) {
              db.push(assetDatas);
            }
            while (nextToken !== null) {
              const DBResult1 = await API.graphql({
                query: queries.distributionBoxesBySitedetailsID,
                variables: {
                  sitedetailsID: site.id,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              nextToken =
                DBResult1.data.distributionBoxesBySitedetailsID.nextToken;
              if (
                DBResult1.data.distributionBoxesBySitedetailsID.items.length > 0
              ) {
                db.push(DBResult1.data.distributionBoxesBySitedetailsID.items);
              }
            }
          }
          if (db.length > 0) {
            const mergeDB = db.flat(1);
            console.log(mergeDB);
            setDistributionBoxList(mergeDB);
            setDistributionBoxfilter(mergeDB);
            SiteDBData.setSiteDBData(mergeDB);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(mergeDB))
            );
            optionsDB.push({ value: "", label: "All Distribution Board" });
            mergeDB.map(async (item) => {
              optionsDB.push({
                value: item.id,
                label: item.distributionBoxName,
              });
            });
          }
        } else {
          setDistributionBoxList([]);
          setDistributionBoxfilter([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (localStorage.getItem("site") === null && sites.length === 0) {
      LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
        ? fetchSitesDetailsSU()
        : fetchSitesDetails();
    } else {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      setSiteDetails(activeSites);
      let siteOptions = [];
      for (let site of activeSites) {
        siteOptions.push({
          value: site.id,
          label:
            site.siteName + " " + (site.unitNumber ? site.unitNumber : " "),
        });
      }
      setSites(siteOptions);
    }
    if (
      localStorage.getItem("siteDB") === null &&
      (distributionBoxList.length === 0 || distributionBoxFilter.length === 0)
    ) {
      fetchlistDistributionBoxSU();
    } else {
      let DB = JSON.parse(base64.decode(localStorage.getItem("siteDB")));
      setDistributionBoxList(DB);
      setDistributionBoxfilter(DB);
      optionsDB.push({ value: "", label: "All Distribution Board" });
      DB.map(async (item) => {
        optionsDB.push({
          value: item.id,
          label: item.distributionBoxName,
        });
      });
    }
  }, []);
  useEffect(() => {
    if (selectedSiteGlobal) {
      console.log("selectedSiteGlobal onchange", selectedSiteGlobal);
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSiteGlobal;
        })
      );
      getAssets();
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSiteGlobal]);
  useEffect(() => {
    if (selectedSite !== "") {
      console.log("selected Site", selectedSite);
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSite;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSite]);
  useEffect(() => {
    if (siteDetails.length > 0) {
      if (state.state && state.state.site && state.state.site.id) {
        setSelectedSite(state.state.site.id);
        searchAssetDataSiteView();
      } else {
        getAssets();
      }
    }
  }, [siteDetails]);
  async function getAssets() {
    setHasMore(true);
    if (isReset) {
      isReset = false;
      setItems([]);
      setFullAssets([]);
      if (
        selectedSiteGlobal ||
        (state.state && state.state.site && state.state.site.id)
      ) {
        if (assetTemplatData.length > 0) {
          // if (isDataLoading.current || !hasMore) return;
          // isDataLoading.current = true;
          try {
            let assets = [];
            let count = 0;
            let nextToken = "";
            for (let item of assetTemplatData) {
              try {
                count = count + 1;
                let assetSiteTemp =
                  item.id + "_" + selectedSiteGlobal
                    ? selectedSiteGlobal
                    : state.state.site.id;
                const assetsData = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 1000,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: nextTokenRef.current,
                  },
                  authMode: "API_KEY",
                });

                console.log(assetsData);
                const data =
                  assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextToken = data.nextToken;
                if (nextToken !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: data.nextToken,
                  });
                  console.log(assetRecordsRef.current);
                }
                if (data.items.length > 0) {
                  assets.push(data.items);
                }

                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  nextTokenRef.current = nextToken;
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  isDataLoading.current = false;
                  if (nextToken === null) {
                    console.log(siteIndex);
                    setsSiteIndex(siteIndex + 1);
                  } else {
                    setHasMore(nextToken !== null);
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
                isDataLoading.current = false;
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            isDataLoading.current = false;
          }
        }
      } else {
        let siteId = siteDetails[0].id;
        if (assetTemplatData.length > 0) {
          // if (isDataLoading.current || hasMore) return;
          // isDataLoading.current = true;
          try {
            let assets = [];
            let count = 0;
            let nextToken = "";
            for (let item of assetTemplatData) {
              try {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + siteId;
                const assetsData = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 1000,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: nextTokenRef.current,
                  },
                  authMode: "API_KEY",
                });

                console.log(assetsData);
                const data =
                  assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextToken = data.nextToken;
                if (nextToken !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: data.nextToken,
                  });
                  console.log(assetRecordsRef.current);
                }
                if (data.items.length > 0) {
                  assets.push(data.items);
                }

                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  nextTokenRef.current = nextToken;
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  isDataLoading.current = false;
                  if (nextToken === null) {
                    console.log(siteIndex);
                    setsSiteIndex(siteIndex + 1);
                  } else {
                    setHasMore(nextToken !== null);
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
                isDataLoading.current = false;
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            isDataLoading.current = false;
          }
        }
      }
      setIsLoading(false);
    } else {
      if (selectedSiteGlobal) {
        console.log("selectedSiteGlobal", selectedSiteGlobal);
        if (assetTemplatData.length > 0) {
          if (isDataLoading.current || !hasMore) return;
          isDataLoading.current = true;
          try {
            let assets = [];
            let count = 0;
            let nextToken = "";
            //siteDetails.map(async (site) => {
            for (let item of assetTemplatData) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + selectedSiteGlobal;
              const assetsData = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 1000,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                  nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });
              console.log(assetsData);
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              if (count === assetTemplatData.length) {
                const mergeAssets = assets.flat(1);
                nextTokenRef.current = nextToken;
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                isDataLoading.current = false;
                setHasMore(nextToken !== null);
              }
            }
            //});
          } catch (error) {
            console.error("Error fetching data:", error);
            isDataLoading.current = false;
          }
        } else {
          const assetTemplateResults = await API.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 1000,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            if (isDataLoading.current || !hasMore) return;
            isDataLoading.current = true;
            try {
              let assets = [];
              let count = 0;
              let nextToken = "";
              for (let item of assetTemplateResults.data
                .assetTemplatesByCategoriesID.items) {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + selectedSiteGlobal;
                var assetResults = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 1000,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: nextTokenRef.current,
                  },
                  authMode: "API_KEY",
                });

                console.log(assetResults);
                const assetData =
                  assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextToken = assetData.nextToken;
                if (assetData.items.length > 0) {
                  assets.push(assetData.items);
                }
                if (
                  count ===
                  assetTemplateResults.data.assetTemplatesByCategoriesID.items
                    .length
                ) {
                  console.log(assets);
                  const mergeAssets = assets.flat(1);
                  nextTokenRef.current = nextToken;
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  isDataLoading.current = false;
                  setHasMore(nextToken !== null);
                }
              }
            } catch (error) {
              console.error("Error fetching asset data:", error);
              isDataLoading.current = false;
            }
          }
        }
      } else {
        if (siteIndex < siteDetails.length) {
          let siteId = siteDetails[siteIndex].id;
          if (assetTemplatData.length > 0) {
            if (isDataLoading.current || !hasMore) return;
            isDataLoading.current = true;
            try {
              let assets = [];
              let count = 0;
              let nextToken = "";
              for (let item of assetTemplatData) {
                try {
                  count = count + 1;
                  let assetSiteTemp = item.id + "_" + siteId;
                  const assetsData = await API.graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: assetSiteTemp,
                      limit: 1000,
                      filter: {
                        status: {
                          ne: "delete",
                        },
                      },
                      nextToken: nextTokenRef.current,
                    },
                    authMode: "API_KEY",
                  });

                  console.log(assetsData);
                  const data =
                    assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                  nextToken = data.nextToken;
                  if (nextToken !== null) {
                    assetRecordsRef.current.push({
                      assettemplsiteid: assetSiteTemp,
                      nextToken: data.nextToken,
                    });
                    console.log(assetRecordsRef.current);
                  }
                  if (data.items.length > 0) {
                    assets.push(data.items);
                  }

                  if (count === assetTemplatData.length) {
                    const mergeAssets = assets.flat(1);
                    nextTokenRef.current = nextToken;
                    setItems((prevItems) => [...prevItems, ...mergeAssets]);
                    isDataLoading.current = false;
                    if (nextToken === null) {
                      console.log(siteIndex);
                      setsSiteIndex(siteIndex + 1);
                    } else {
                      setHasMore(nextToken !== null);
                    }
                  }
                } catch (error) {
                  console.error("Error fetching data:", error);
                  isDataLoading.current = false;
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
              isDataLoading.current = false;
            }
          } else {
            const assetTemplateResults = await API.graphql({
              query: queries.assetTemplatesByCategoriesID,
              variables: {
                categoriesID: catid,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: 1000,
              },
              authMode: "API_KEY",
            });

            if (
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
                .length > 0
            ) {
              setAssetTemplatData(
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
              );
              if (isDataLoading.current || !hasMore) return;
              isDataLoading.current = true;
              try {
                let assets = [];
                let count = 0;
                let nextToken = "";
                for (let item of assetTemplateResults.data
                  .assetTemplatesByCategoriesID.items) {
                  try {
                    count = count + 1;
                    if (siteIndex < siteDetails.length) {
                      let siteId = siteDetails[siteIndex].id;
                      let assetSiteTemp = item.id + "_" + siteId;
                      console.log(assetSiteTemp);

                      var assetResults = await API.graphql({
                        query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                        variables: {
                          assettemplateSiteID: assetSiteTemp,
                          limit: 1000,
                          filter: {
                            status: {
                              ne: "delete",
                            },
                          },
                          nextToken: nextTokenRef.current,
                        },
                        authMode: "API_KEY",
                      });
                      const assetData =
                        assetResults.data
                          .assetsByAssettemplateSiteIDAndAssetNum;
                      nextToken = assetData.nextToken;
                      if (nextToken !== null) {
                        assetRecordsRef.current.push({
                          assettemplsiteid: assetSiteTemp,
                          nextToken: nextToken,
                        });
                      }
                      if (assetData.items.length > 0) {
                        assets.push(assetData.items);
                      }
                      if (
                        count ===
                        assetTemplateResults.data.assetTemplatesByCategoriesID
                          .items.length
                      ) {
                        const mergeAssets = assets.flat(1);
                        let record = assetRecordsRef.current.find(
                          (item) => item.nextToken !== null
                        );
                        let nextToken1 = record ? record.nextToken : null;
                        nextTokenRef.current = nextToken1;
                        setItems((prevItems) => {
                          const updatedItems = [...prevItems, ...mergeAssets];
                          if (
                            updatedItems.length < 800 ||
                            nextToken1 === null
                          ) {
                            console.log("sites length> ");
                            console.log(siteIndex);
                            setsSiteIndex(siteIndex + 1);
                          } else {
                            console.log("hasmore");
                            setHasMore(nextToken1 !== null);
                          }

                          isDataLoading.current = false;
                          return updatedItems;
                        });
                      }
                    } else {
                      console.log(
                        "site Index",
                        siteIndex + ",length " + siteDetails.length
                      );
                      setHasMore(!hasMore);
                      isDataLoading.current = false;
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                    isDataLoading.current = false;
                  }
                }
              } catch (error) {
                console.error("Error fetching asset data:", error);
                isDataLoading.current = false;
              }
            }
          }
        } else {
          console.log(
            "site Index",
            siteIndex + ",length " + siteDetails.length
          );
          setHasMore(!hasMore);
          isDataLoading.current = false;
        }
      }
    }
    setIsLoading(false);
  }
  async function getAssetsLoop() {
    setHasMore(true);
    console.log(assetRecordsRef.current);
    try {
      let assets = [];
      let count = 0;
      let allNextTokensNull = true;
      for (let item of assetRecordsRef.current) {
        try {
          count += 1;
          let assetSiteTemp = item.assettemplsiteid;

          const assetsData = await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: assetSiteTemp,
              limit: 1000,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              nextToken: item.nextToken,
            },
            authMode: "API_KEY",
          });

          const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
          const nextToken = data.nextToken;

          if (data.items.length > 0) {
            assets.push(data.items);
          }

          const existingIndex = assetRecordsRef.current.findIndex(
            (record) => record.assettemplsiteid === item.assettemplsiteid
          );

          if (existingIndex !== -1) {
            assetRecordsRef.current[existingIndex].nextToken = nextToken;
          } else {
            assetRecordsRef.current.push({
              assettemplsiteid: item.assettemplsiteid,
              nextToken: nextToken,
            });
          }

          if (nextToken !== null) {
            allNextTokensNull = false;
          }

          if (count === assetRecordsRef.current.length) {
            const mergeAssets = assets.flat(1);
            nextTokenRef.current = nextToken;
            setItems((prevItems) => [...prevItems, ...mergeAssets]);
            isDataLoading.current = false;

            setHasMore(!allNextTokensNull);

            if (allNextTokensNull) {
              setsSiteIndex(siteIndex + 1);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          isDataLoading.current = false;
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      isDataLoading.current = false;
    }
  }

  async function getAssetsLoopSearch() {
    setHasMore(true);
    console.log(assetRecordsSearchRef.current);
    try {
      let assets = [];
      let count = 0;
      let allNextTokensNull = true;
      for (let item of assetRecordsSearchRef.current) {
        try {
          count += 1;
          let assetSiteTemp = item.assettemplsiteid;
          const assetsData = await API.graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: assetSiteTemp,
              limit: 1000,
              filter: {
                status: {
                  ne: "delete",
                },
              },
              nextToken: item.nextToken,
            },
            authMode: "API_KEY",
          });
          const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
          const nextToken = data.nextToken;
          if (data.items.length > 0) {
            assets.push(data.items);
          }
          const existingIndex = assetRecordsSearchRef.current.findIndex(
            (record) => record.assettemplsiteid === item.assettemplsiteid
          );
          if (existingIndex !== -1) {
            assetRecordsSearchRef.current[existingIndex].nextToken = nextToken;
          } else {
            assetRecordsSearchRef.current.push({
              assettemplsiteid: item.assettemplsiteid,
              nextToken: nextToken,
            });
          }
          if (nextToken !== null) {
            allNextTokensNull = false;
          }
          if (count === assetRecordsSearchRef.current.length) {
            const mergeAssets = assets.flat(1);
            nextTokenRef.current = nextToken;
            setItems((prevItems) => [...prevItems, ...mergeAssets]);
            isDataLoading.current = false;
            setHasMore(!allNextTokensNull);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          isDataLoading.current = false;
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      isDataLoading.current = false;
    }
  }

  useEffect(() => {
    if (siteIndex > 0 && items.length < 800) {
      getAssets();
    }
  }, [siteIndex]);
  const handleSearch = () => {
    try {
      setIsSearch(true);
      setSearchAssets(true);
    } catch (error) {
      console.log("asset search", error);
    }
  };
  const searchAssetsMore = () => {
    try {
      setIsSearch(true);
      if (oldSiteId !== selectedSite) {
        setIsSecoundSearch(false);
      } else {
        setIsSecoundSearch(true);
      }
      setSearchItems([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    console.log(items);
    if (items.length > 0 && hasMore) {
      if (state.state && state.state.site && state.state.site.id) {
        console.log(state.state.site.id);
        const assets = items.filter(
          (item) => item.sitedetailsID === state.state.site.id
        );
        const assetsFilter =
          state.state && state.state.site && state.state.site.id
            ? assets
            : items;
        setFullItems(assetsFilter);
      }
      setFullItems(items);
    } else if (isSearch && items.length === 0) {
      setFullItems([]);
    }
  }, [items]);
  useEffect(() => {
    if (isReset) {
      state.state && state.state.site && state.state.site.id
        ? searchAssetDataSiteView()
        : getAssets();
    }
  }, [isReset]);

  async function searchAssetData() {
    setHasMore(true);
    if (isSearch && !isSecoundSearch) {
      setItems([]);
    }
    let siteId = selectedSite;
    console.log(selectedBox);
    console.log(siteId);
    console.log(assetTemplatData);
    if (assetTemplatData.length > 0) {
      // if (isDataLoading.current || !hasMore) return;
      // isDataLoading.current = true;
      try {
        let assets = [];
        let count = 0;
        let nextToken = "";
        //siteDetails.map(async (site) => {
        for (let item of assetTemplatData) {
          count = count + 1;
          let assetSiteTemp = item.id + "_" + siteId;
          console.log(assetSiteTemp);
          console.log(selectedBox);
          let assetsData = [];
          if (selectedBox) {
            assetsData = await API.graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: assetSiteTemp,
                limit: 1000,
                filter: {
                  distributionboxID: { eq: selectedBox },
                  status: {
                    ne: "delete",
                  },
                },
                //nextToken: nextTokenRef.current,
              },
              authMode: "API_KEY",
            });
          } else {
            assetsData = await API.graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: assetSiteTemp,
                limit: 1000,
                filter: {
                  status: {
                    ne: "delete",
                  },
                },
                //nextToken: nextTokenRef.current,
              },
              authMode: "API_KEY",
            });
          }
          console.log(assetsData);
          const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
          nextToken = data.nextToken;
          if (nextToken !== null) {
            assetRecordsSearchRef.current.push({
              assettemplsiteid: assetSiteTemp,
              nextToken: nextToken,
            });
          }
          if (data.items.length > 0) {
            assets.push(data.items);
          }

          console.log(assetRecordsSearchRef.current);
          if (count === assetTemplatData.length) {
            const mergeAssets = assets.flat(1);
            nextTokenRef.current = nextToken;
            setItems((prevItems) => [...prevItems, ...mergeAssets]);
            isDataLoading.current = false;
            //--
            console.log(assetSiteTemp);
            let record = assetRecordsSearchRef.current.find(
              (item) => item.nextToken !== null
            );
            console.log(record);
            let nextToken1 = record ? record.nextToken : null;
            setHasMore(nextToken1 !== null);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        isDataLoading.current = false;
      }
    } else {
      const assetTemplateResults = await API.graphql({
        query: queries.assetTemplatesByCategoriesID,
        variables: {
          categoriesID: catid,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: 1000,
        },
        authMode: "API_KEY",
      });

      if (
        assetTemplateResults.data.assetTemplatesByCategoriesID.items.length > 0
      ) {
        setAssetTemplatData(
          assetTemplateResults.data.assetTemplatesByCategoriesID.items
        );
        // if (isDataLoading.current || !hasMore) return;
        // isDataLoading.current = true;
        try {
          let assets = [];
          let count = 0;
          let nextToken = "";

          for (let item of assetTemplateResults.data
            .assetTemplatesByCategoriesID.items) {
            var assetResults = [];
            count = count + 1;
            let assetSiteTemp = item.id + "_" + siteId;
            console.log(selectedBox);
            if (selectedBox) {
              assetResults = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 1000,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                    status: {
                      ne: "delete",
                    },
                  },
                  //nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });
            } else {
              assetResults = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 1000,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                  //nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });
            }

            console.log(assetResults);
            const assetData =
              assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextToken = assetData.nextToken;
            if (nextToken !== null) {
              assetRecordsSearchRef.current.push({
                assettemplsiteid: assetSiteTemp,
                nextToken: nextToken,
              });
            }
            if (assetData.items.length > 0) {
              assets.push(assetData.items);
            }

            console.log(assetRecordsSearchRef.current);
            if (
              count ===
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
                .length
            ) {
              const mergeAssets = assets.flat(1);
              //nextTokenRef.current = nextToken;
              setItems((prevItems) => [...prevItems, ...mergeAssets]);
              isDataLoading.current = false;
              //--
              console.log(assetSiteTemp);
              let record = assetRecordsSearchRef.current.find(
                (item) => item.nextToken !== null
              );
              console.log(record);
              let nextToken1 = record ? record.nextToken : null;
              console.log(nextToken1);
              setHasMore(nextToken1 !== null);
            }
          }
        } catch (error) {
          console.error("Error fetching asset data:", error);
          isDataLoading.current = false;
        }
      }
    }
  }

  async function searchAssetDataSiteView() {
    setHasMore(true);

    let siteId = state.state.site.id;
    console.log(siteId);
    console.log(assetTemplatData);
    if (isReset) {
      isReset = false;
      setItems([]);
      setFullAssets([]);
      if (state.state && state.state.site && state.state.site.id) {
        if (assetTemplatData.length > 0) {
          if (isDataLoading.current || !hasMore) return;
          isDataLoading.current = true;
          try {
            let assets = [];
            let count = 0;
            let nextToken = "";
            //siteDetails.map(async (site) => {
            for (let item of assetTemplatData) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + state.state.site.id;
              const assetsData = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 1000,
                  nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });
              console.log(assetsData);
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              if (count === assetTemplatData.length) {
                const mergeAssets = assets.flat(1);
                nextTokenRef.current = nextToken;
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                isDataLoading.current = false;
                setHasMore(nextToken !== null);
              }
            }
            //});
          } catch (error) {
            console.error("Error fetching data:", error);
            isDataLoading.current = false;
          }
        } else {
          const assetTemplateResults = await API.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 1000,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            if (isDataLoading.current || !hasMore) return;
            isDataLoading.current = true;
            try {
              let assets = [];
              let count = 0;
              let nextToken = "";
              for (let item of assetTemplateResults.data
                .assetTemplatesByCategoriesID.items) {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + state.state.site.id;
                var assetResults = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 1000,
                    nextToken: nextTokenRef.current,
                  },
                  authMode: "API_KEY",
                });

                console.log(assetResults);
                const assetData =
                  assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextToken = assetData.nextToken;
                if (assetData.items.length > 0) {
                  assets.push(assetData.items);
                }
                if (
                  count ===
                  assetTemplateResults.data.assetTemplatesByCategoriesID.items
                    .length
                ) {
                  console.log(assets);
                  const mergeAssets = assets.flat(1);
                  nextTokenRef.current = nextToken;
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  isDataLoading.current = false;
                  setHasMore(nextToken !== null);
                }
              }
            } catch (error) {
              console.error("Error fetching asset data:", error);
              isDataLoading.current = false;
            }
          }
        }
      } else {
        nextTokenRef.current = null;
        setItems((prevItems) => [...prevItems, ...[]]);
        isDataLoading.current = false;
        setHasMore(!hasMore);
      }
    } else {
      if (assetTemplatData.length > 0) {
        // if (isDataLoading.current || !hasMore) return;
        // isDataLoading.current = true;
        try {
          let assets = [];
          let count = 0;
          let nextToken = "";
          //siteDetails.map(async (site) => {
          for (let item of assetTemplatData) {
            count = count + 1;
            let assetSiteTemp = item.id + "_" + siteId;
            console.log(assetSiteTemp);
            let assetsData = [];
            if (selectedBox) {
              assetsData = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 1000,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                  },
                  nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });
            } else {
              assetsData = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 1000,
                  nextToken: nextTokenRef.current,
                },
                authMode: "API_KEY",
              });
            }
            console.log(assetsData);
            const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextToken = data.nextToken;
            if (data.items.length > 0) {
              assets.push(data.items);
            }
            if (count === assetTemplatData.length) {
              const mergeAssets = assets.flat(1);
              nextTokenRef.current = nextToken;
              setItems((prevItems) => [...prevItems, ...mergeAssets]);
              isDataLoading.current = false;
              // if (items.length < 800 || nextToken === null) {
              //   console.log(siteIndex);
              //   setsSiteIndex(siteIndex + 1);
              //   // if (items.length < 800) {
              //   //   getAssets();
              //   // }
              // } else {
              setHasMore(nextToken !== null);
              // }
            }
          }
          //});
        } catch (error) {
          console.error("Error fetching data:", error);
          isDataLoading.current = false;
        }
      } else {
        const assetTemplateResults = await API.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: state.state.category.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 1000,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          // if (isDataLoading.current || !hasMore) return;
          // isDataLoading.current = true;
          try {
            let assets = [];
            let count = 0;
            let nextToken = "";
            for (let item of assetTemplateResults.data
              .assetTemplatesByCategoriesID.items) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + siteId;
              let assetResults = [];
              if (selectedBox) {
                assetResults = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 1000,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                    },
                    nextToken: nextTokenRef.current,
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetResults = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 1000,
                    nextToken: nextTokenRef.current,
                  },
                  authMode: "API_KEY",
                });
              }

              console.log(assetResults);
              const assetData =
                assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = assetData.nextToken;
              if (assetData.items.length > 0) {
                assets.push(assetData.items);
              }
              if (
                count ===
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
                  .length
              ) {
                console.log(assets);
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                nextTokenRef.current = nextToken;
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                isDataLoading.current = false;
                setHasMore(nextToken !== null);
              }
            }
          } catch (error) {
            console.error("Error fetching asset data:", error);
            isDataLoading.current = false;
          }
        }
      }
    }
  }
  useEffect(() => {
    if (isSearch) {
      searchAssetData();
    }
  }, [isSearch]);

  const activeInactive = () => {
    setIsActive(!isActive);
    console.log(isActive);
    console.log(activedata);
    const data = isActive
      ? activedata.filter((item) => item.status === "Inactive")
      : activedata.filter((item) => item.status === "Active");
    console.log(data);
    if (
      value ||
      selectedSite ||
      selectedBox ||
      (selectedLocation && selectedLocation.trim())
    ) {
      let filteredAssets = data;
      if (value) {
        filteredAssets = filteredAssets.filter((asset) => {
          let siteName = getSiteName(asset.sitedetailsID);
          let dbName =
            asset.distributionBox !== null &&
            asset.distributionBox !== undefined
              ? getDBName(asset.distributionboxID)
              : "";
          let assetTemp = assetTemplatData.filter((item) => {
            return (item.id = asset.assettemplateID);
          });
          let assetfittingnumbers =
            asset.fittingNumber.match(/^[0-9]+$/) !== null
              ? asset.fittingNumber
              : asset.fittingNumber.toLowerCase();
          let searchfittingnumbers =
            value.match(/^[0-9]+$/) !== null ? value : value.toLowerCase();
          return (
            (asset.assetModel !== null &&
              asset.assetModel.toLowerCase().includes(value.toLowerCase())) ||
            (dbName !== "" &&
              dbName.toLowerCase().includes(value.toLowerCase())) ||
            siteName.toLowerCase().includes(value.toLowerCase()) ||
            (assetfittingnumbers !== null &&
              assetfittingnumbers.includes(searchfittingnumbers)) ||
            moment(asset.createdAt)
              .format("DD/MM/YYYY")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            (assetTemp.length > 0 &&
              assetTemp[0].templatename
                .toLowerCase()
                .includes(value.toLowerCase()))
          );
          // return (
          //   (asset.assetModel !== null &&
          //     asset.assetModel.toLowerCase().includes(value.toLowerCase())) ||
          //   (asset.distributionBox !== null &&
          //     asset.distributionBox
          //       .toLowerCase()
          //       .includes(value.toLowerCase())) ||
          //   (asset.site !== null &&
          //     asset.site.toLowerCase().includes(value.toLowerCase())) ||
          //   (asset.fittingNumber !== null &&
          //     asset.fittingNumber
          //       .toLowerCase()
          //       .includes(value.toLowerCase())) ||
          //   moment(asset.createdAt)
          //     .format("DD/MM/YYYY")
          //     .toString()
          //     .toLowerCase()
          //     .includes(value.toLowerCase()) ||
          //   (asset.assetName !== null &&
          //     asset.assetName.toLowerCase().includes(value.toLowerCase()))
          // );
        });
      }
      if (selectedSite) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.siteId === selectedSite
        );
        setDistributionBoxfilter(
          distributionBoxList.filter(
            (item) => item.sitedetailsID === selectedSite
          )
        );
      } else {
        setDistributionBoxfilter(distributionBoxList);
      }
      if (selectedBox) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.distributionboxID === selectedBox
        );
      }
      if (selectedLocation && selectedLocation.trim()) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.assetLocation === selectedLocation
        );
      }
      console.log(filteredAssets);
      const sort = filteredAssets.sort(
        (a, b) => a.fittingNumber - b.fittingNumber
      );
      setFilteredData(sort);
      //setAssetsDemo(sort);
      setCollectionCard(cloneDeep(sort.slice(0, countPerPageCard)));
    } else {
      setAssetsDemo(data);
      setAssetsDemoCard(data);
      setCollectionCard(cloneDeep(data.slice(0, countPerPageCard)));
    }
  };
  const cancelForm = () => {
    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/catassetlist`);
    }
  };
  const handleChange = async (item, e) => {
    //console.log(e);
    try {
      var statusSp;
      if (item.status.toLowerCase() === "active") {
        statusSp = "Inactive";
        toast.success("Deactivated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        statusSp = "Active";
        toast.success("Activated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      //console.log(item);
      await API.graphql(
        graphqlOperation(mutations.updateAssets, {
          input: { id: item.id, status: statusSp },
        })
      )
        .then((result) => {
          //console.log(result);
          getAssets();
          if (LoginUserGroup === "site_user") {
            fetchSitesDetailsSU();
          } else {
            fetchSitesDetails();
          }
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Error while Updating ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (e) {
      toast.error("Error while Updating ", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const ApproveStatus = (status) => {
    return status?.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };
  const navigatePage = (type) => {
    //console.log(type);
    type === "add"
      ? navigate(`${process.env.PUBLIC_URL}/asset/add`, {
          state: { category: state.state.category, site: state.state.site },
        })
      : navigate(`${process.env.PUBLIC_URL}/bulkimport`, {
          state: { category: state.state.category, site: state.state.site },
        });
  };
  const handleSiteDDChange = async (selectedOption) => {
    try {
      //setIsSearch(false);
      setOldSiteId(selectedSite);
      setSelectedSearchDBData("");
      setSelectedSite(selectedOption.value);
      const siteID = selectedOption.value;
      setSelectedSearchSiteData(selectedOption);
      console.log(fullAssets);
      //---assetscount by sitedetailsID
      // const result = await API.graphql({
      //   query: queries.assetCountsBySitedetailsID,
      //   variables: {
      //     sitedetailsID: siteID,
      //     filter: {
      //       categoriesID: {
      //         eq: state.state.category.id
      //       }
      //     },
      //     limit: limit,
      //   },
      // });
      const assetscount = assetsCountBySite.filter(
        (item) => item.categoriesID === state.state.category.id
      );
      console.log(assetscount);
      const result = assetscount.filter(
        (item) => item.sitedetailsID === siteID
      );
      console.log(`assetscount result:`, result);
      setAssetscountbysite(result);
    } catch (error) {
      console.log(error);
    }
  };
  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];
  const handleSiteDB = (selectedOption) => {
    setIsSearch(false);
    setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
  };
  const getSiteName = (siteId) => {
    try {
      const sites = siteDetails.filter((site) => {
        return site.id === siteId;
      });
      //console.log("display site name", sites);
      return sites.length > 0 ? sites[0].siteName : "";
    } catch (error) {
      console.log("Error while displaying site name", error);
      return "";
    }
  };
  const getDBName = (dbId) => {
    try {
      const db = distributionBoxList.filter((db) => {
        return db.id === dbId;
      });
      return db.length > 0 ? db[0].distributionBoxName : "";
    } catch (error) {
      console.log("display site name", error);
      return "";
    }
  };
  const handleCheckboxAssetChange = (event, itemId) => {
    let assets = isSearch ? filteredData : fullAssets;
    console.log("assest", fullItems);
    const isChecked = event.target.checked;
    if (itemId === "selectAll") {
      setIsAllSelected(isChecked);
      if (isChecked) {
        const allItemIds = fullItems.map((item) => item.id);
        setSelectedItemsAsset(allItemIds);
      } else {
        setSelectedItemsAsset([]);
      }
    } else {
      setSelectedItemsAsset((prevSelectedItems) => {
        if (isChecked && !prevSelectedItems.includes(itemId)) {
          return [...prevSelectedItems, itemId];
        } else if (!isChecked && prevSelectedItems.includes(itemId)) {
          return prevSelectedItems.filter((item) => item !== itemId);
        }
        return prevSelectedItems;
      });
      const allItemIds = assets.map((item) => item.id);
      setIsAllSelected(selectedItemsAsset.length === allItemIds.length);
    }
  };
  const handleDeleteAssets = () => {
    try {
      let counter = 0;
      selectedItemsAsset.map(async (item, i) => {
        await sleep(Math.random() * i * 17); // wait
        await API.graphql(
          graphqlOperation(mutations.updateAssets, {
            input: { id: item, status: "delete" },
          })
        )
          .then((result) => {
            console.log(result);
            counter = counter + 1;
            if (counter === selectedItemsAsset.length) {
              toast.success("Selected Assets Deleted Successfully.", {
                position: toast.POSITION.TOP_CENTER,
              });
              navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                state: {
                  category: state.state.category,
                  site: state.state.site,
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      console.log(error);
    }
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  //----subcription limit changes
  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSubscriptionListSU();
      fetchSubscriptionListSUAssetsCount();
    } else {
      fetchSubscriptionList();
    }
  }, []);
  async function fetchSubscriptionListSUAssetsCount() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await API.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.assetOwnerId,
          limit: 200000,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
        authMode: "API_KEY",
      });
      console.log(result);
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.assetOwnerId,
              limit: 200000,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          console.log(results);
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          //fetchSpuser(mergeSites);
          SiteData.setSiteData(mergeSites);
          const promises1 = mergeSites.map(async (item) => {
            return API.graphql({
              query: queries.assetCountsBySitedetailsID,
              variables: {
                sitedetailsID: item.id,
                limit: 10000,
              },
            });
          });
          Promise.all(promises1).then(async (results) => {
            console.log(results);
            let assetCount = [];
            if (results.length > 0) {
              results.forEach((result1) => {
                let counts = result1.data.assetCountsBySitedetailsID;
                if (counts !== null) {
                  if (counts.items.length > 0) {
                    counts.items.map((item) => {
                      assetCount.push(item);
                    });
                  }
                }
              });
              console.log(assetCount);
              if (
                state.state &&
                state.state.category &&
                state.state.category.id
              ) {
                const catsitecount = assetCount.filter(
                  (item) => item.categoriesID === state.state.category.id
                );
                console.log(state.state.category.id);
                console.log(catsitecount);
                const totalAssetCount = assetCount.reduce(
                  (accumulator, current) => {
                    return accumulator + current.assetCount;
                  },
                  0
                );
                console.log(totalAssetCount);
                console.log(localStorage.getItem("categoryAssetCount"));
                if (localStorage.getItem("categoryAssetCount") !== null) {
                  let activeAssets = JSON.parse(
                    base64.decode(localStorage.getItem("categoryAssetCount"))
                  );
                  console.log(activeAssets);

                  if (assetsCountBySite.length > 0) {
                    const totalAssetCountsu = assetsCountBySite.reduce(
                      (acc, item) => acc + item.assetCount,
                      0
                    );
                    console.log(totalAssetCountsu);
                    console.log(totalAssetCount);
                    setAssetsCount(totalAssetCount + totalAssetCountsu);
                  }
                } else {
                  setAssetsCount(totalAssetCount);
                }
              }
            }
          });
        }
      }
      return () => API.cancel();
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchSubscriptionListSU() {
    const response3 = await API.graphql({
      query: queries.listUserDetails,
      authMode: "API_KEY",
      variables: {
        limit: limit,
        filter: {
          id: {
            eq: LoginUserDetails.assetOwnerId,
          },
        },
      },
    });
    const assetowner = response3.data.listUserDetails.items;
    console.log(assetowner);
    if (assetowner.length > 0) {
      const response = await API.graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (response.data.listSubscriptionLimits.items.length > 0) {
        let setdata = [];
        setdata = response.data.listSubscriptionLimits.items;
        let assetLimitdata = setdata.filter(
          (item) => item.subscriptionId === assetowner[0].paymentId
        );
        console.log(assetLimitdata);
        let userdata = setdata.filter(
          (item) => item.SubscriptionName === "Asset Add-On"
        );
        console.log(userdata);
        setsubscriptionplan(assetLimitdata);
        if (assetLimitdata[0].subscriptionId !== "5") {
          try {
            const response1 = await API.graphql({
              query: queries.listSubscriptionAdds,
              authMode: "API_KEY",
              variables: {
                limit: limit,
              },
            });
            if (response1.data.listSubscriptionAdds.items.length > 0) {
              const addons = response1.data.listSubscriptionAdds.items;
              let addonsdata = addons.filter(
                (item) => item.userId === LoginUserDetails.id
              );
              console.log(addonsdata);
              const AssetAddOn = addonsdata.filter(
                (item) => item.subscriptionId === "6"
              );
              console.log(AssetAddOn);
              if (AssetAddOn.length > 0) {
                const sort = AssetAddOn.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const activeuser = AssetAddOn.filter(
                  (item) => item.status === "Active"
                );
                console.log(activeuser);
                if (activeuser.length > 0) {
                  setAddOn(sort);
                  const totalQuantity = activeuser.reduce(
                    (acc, user) => acc + user.quantity,
                    0
                  );
                  console.log(totalQuantity);
                  const assetcount =
                    assetLimitdata[0].AssetLimit + totalQuantity;
                  setAssetplancount(assetcount);
                } else {
                  const assetcount = assetLimitdata[0].AssetLimit;
                  console.log(assetcount);
                  setAssetplancount(assetcount);
                }
                // setAddOn(sort);
                // const assetcount =
                //   assetLimitdata[0].AssetLimit + sort.length * 5000;
                // setAssetplancount(assetcount);
              } else {
                const assetcount = assetLimitdata[0].AssetLimit;
                console.log(assetcount);
                setAssetplancount(assetcount);
              }
            } else {
              const assetcount = assetLimitdata[0].AssetLimit;
              console.log(assetcount);
              setAssetplancount(assetcount);
            }
          } catch (error) {
            console.error("GraphQL query error:", error);
            setAssetplancount(assetLimitdata[0].AssetLimit);
          }
        } else {
          setAssetplancount(assetLimitdata[0].AssetLimit);
        }
      }
    }
  }

  async function fetchSubscriptionList() {
    const response = await API.graphql({
      query: queries.listSubscriptionLimits,
      authMode: "API_KEY",
      variables: {
        limit: limit,
      },
    });
    if (response.data.listSubscriptionLimits.items.length > 0) {
      let setdata = [];
      setdata = response.data.listSubscriptionLimits.items;
      let assetLimitdata = setdata.filter(
        (item) => item.subscriptionId === LoginUserDetails.paymentId
      );
      console.log(assetLimitdata);
      let userdata = setdata.filter(
        (item) => item.SubscriptionName === "Asset Add-On"
      );
      console.log(userdata);
      setsubscriptionplan(assetLimitdata);
      if (assetLimitdata[0].subscriptionId !== 5) {
        try {
          const response1 = await API.graphql({
            query: queries.listSubscriptionAdds,
            authMode: "API_KEY",
            variables: {
              limit: limit,
              filter: {
                status: {
                  eq: "Active",
                },
              },
            },
          });
          if (response1.data.listSubscriptionAdds.items.length > 0) {
            const addons = response1.data.listSubscriptionAdds.items;
            let addonsdata = addons.filter(
              (item) => item.userId === LoginUserDetails.id
            );
            console.log(addonsdata);
            const AssetAddOn = addonsdata.filter(
              (item) => item.subscriptionId === "6"
            );
            console.log(AssetAddOn);
            if (AssetAddOn.length > 0) {
              const sort = AssetAddOn.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              const activeuser = AssetAddOn.filter(
                (item) => item.status === "Active"
              );
              console.log(activeuser);
              if (activeuser.length > 0) {
                setAddOn(sort);
                const totalQuantity = activeuser.reduce(
                  (acc, user) => acc + user.quantity,
                  0
                );
                console.log(totalQuantity);
                const assetcount = assetLimitdata[0].AssetLimit + totalQuantity;
                setAssetplancount(assetcount);
              } else {
                const assetcount = assetLimitdata[0].AssetLimit;
                console.log(assetcount);
                setAssetplancount(assetcount);
              }
              // setAddOn(sort);
              // const assetcount =
              //   assetLimitdata[0].AssetLimit + sort.length * 5000;
              // setAssetplancount(assetcount);
            } else {
              const assetcount = assetLimitdata[0].AssetLimit;
              console.log(assetcount);
              setAssetplancount(assetcount);
            }
          } else {
            const assetcount = assetLimitdata[0].AssetLimit;
            console.log(assetcount);
            setAssetplancount(assetcount);
          }
        } catch (error) {
          console.error("GraphQL query error:", error);
          setAssetplancount(assetLimitdata[0].AssetLimit);
        }
      } else {
        setAssetplancount(assetLimitdata[0].AssetLimit);
      }
    }
  }

  const handleDeleteAllAssets = async () => {
    try {
      //---fetching assets
      if (selectedSite || selectedBox) {
        console.log("filtered assets deleting");
        let filters = {};
        filters.assetOwnerID = { eq: LoginUserDetails.id };
        if (selectedBox) {
          filters.distributionboxID = { eq: selectedBox };
          //---
          if (assetTemplatData.length > 0) {
            setLoading(true);
            let nextToken = "";
            let assets = [];
            let count = 0;
            console.log(assetTemplatData);
            console.log(selectedSite);
            assetTemplatData.map(async (assetTemplate) => {
              count = count + 1;
              let tempSiteId = assetTemplate.id + "_" + selectedSite;
              console.log(tempSiteId);
              console.log(filters);
              await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: tempSiteId,
                  limit: limit,
                  filter: filters,
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  console.log(result);
                  let assetDatas =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                  nextToken =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(assetDatas);
                  }
                  while (nextToken !== null) {
                    await API.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: tempSiteId,
                        limit: limit,
                        nextToken: nextToken,
                        filter: filters,
                      },
                      authMode: "API_KEY",
                    })
                      .then((results) => {
                        console.log(results);
                        nextToken =
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .nextToken;
                        if (
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .items.length > 0
                        ) {
                          assets.push(
                            results.data.assetsByAssettemplateSiteIDAndAssetNum
                              .items
                          );
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                      });
                  }

                  console.log(assets);
                  console.log(count);
                  console.log(assetTemplatData);
                  if (count === assetTemplatData.length) {
                    const mergeAssets = assets.flat(1);
                    //--deleting process
                    if (mergeAssets.length > 0) {
                      let counter = 0;
                      mergeAssets.map(async (item, i) => {
                        await sleep(Math.random() * i * 17); // wait
                        await API.graphql(
                          graphqlOperation(mutations.updateAssets, {
                            input: { id: item.id, status: "delete" },
                          })
                        )
                          .then((result) => {
                            console.log(result);
                            counter = counter + 1;
                            if (counter === mergeAssets.length) {
                              setLoading(false);
                              toast.success(
                                "Selected Assets Deleted Successfully.",
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                }
                              );
                              navigate(
                                `${process.env.PUBLIC_URL}/asset/deleteList`,
                                {
                                  state: {
                                    category: state.state.category,
                                    site: state.state.site,
                                  },
                                }
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            setLoading(false);
                          });
                      });
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                });
            });
          }
        } else {
          if (assetTemplatData.length > 0) {
            setLoading(true);
            let nextToken = "";
            let assets = [];
            let count = 0;
            console.log(assetTemplatData);
            console.log(selectedSite);
            assetTemplatData.map(async (assetTemplate) => {
              count = count + 1;
              let tempSiteId = assetTemplate.id + "_" + selectedSite;
              console.log(tempSiteId);
              console.log(filters);
              await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: tempSiteId,
                  limit: limit,
                  filter: filters,
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  console.log(result);
                  let assetDatas =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                  nextToken =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(assetDatas);
                  }
                  while (nextToken !== null) {
                    await API.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: tempSiteId,
                        limit: limit,
                        nextToken: nextToken,
                        filter: filters,
                      },
                      authMode: "API_KEY",
                    })
                      .then((results) => {
                        console.log(results);
                        nextToken =
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .nextToken;
                        if (
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .items.length > 0
                        ) {
                          assets.push(
                            results.data.assetsByAssettemplateSiteIDAndAssetNum
                              .items
                          );
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                      });
                  }

                  console.log(assets);
                  console.log(count);
                  console.log(assetTemplatData);
                  if (count === assetTemplatData.length) {
                    const mergeAssets = assets.flat(1);
                    //--deleting process
                    if (mergeAssets.length > 0) {
                      let counter = 0;
                      mergeAssets.map(async (item, i) => {
                        await sleep(Math.random() * i * 17); // wait
                        await API.graphql(
                          graphqlOperation(mutations.updateAssets, {
                            input: { id: item.id, status: "delete" },
                          })
                        )
                          .then((result) => {
                            console.log(result);
                            counter = counter + 1;
                            if (counter === mergeAssets.length) {
                              setLoading(false);
                              toast.success(
                                "Selected Assets Deleted Successfully.",
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                }
                              );
                              navigate(
                                `${process.env.PUBLIC_URL}/asset/deleteList`,
                                {
                                  state: {
                                    category: state.state.category,
                                    site: state.state.site,
                                  },
                                }
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            setLoading(false);
                          });
                      });
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                });
            });
          }
        }
        if (assetTemplatData.length > 0) {
          setLoading(true);
          let nextToken = "";
          let assets = [];
          let count = 0;
          console.log(assetTemplatData);
          console.log(selectedSite);
          assetTemplatData.map(async (assetTemplate) => {
            count = count + 1;
            let tempSiteId = assetTemplate.id + "_" + selectedSite;
            console.log(tempSiteId);
            console.log(filters);
            await API.graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: tempSiteId,
                limit: limit,
                //filter: filters,
              },
              authMode: "API_KEY",
            })
              .then(async (result) => {
                console.log(result);
                let assetDatas =
                  result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                nextToken =
                  result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
                if (
                  result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    .length > 0
                ) {
                  assets.push(assetDatas);
                }
                while (nextToken !== null) {
                  await API.graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: tempSiteId,
                      limit: limit,
                      nextToken: nextToken,
                      filter: filters,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      console.log(results);
                      nextToken =
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .nextToken;
                      if (
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items.length > 0
                      ) {
                        assets.push(
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .items
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsLoading(false);
                    });
                }

                console.log(assets);
                console.log(count);
                console.log(assetTemplatData);
                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  //--deleting process
                  if (mergeAssets.length > 0) {
                    let counter = 0;
                    mergeAssets.map(async (item, i) => {
                      await sleep(Math.random() * i * 17); // wait
                      await API.graphql(
                        graphqlOperation(mutations.updateAssets, {
                          input: { id: item.id, status: "delete" },
                        })
                      )
                        .then((result) => {
                          console.log(result);
                          counter = counter + 1;
                          if (counter === mergeAssets.length) {
                            setLoading(false);
                            toast.success(
                              "Selected Assets Deleted Successfully.",
                              {
                                position: toast.POSITION.TOP_CENTER,
                              }
                            );
                            navigate(
                              `${process.env.PUBLIC_URL}/asset/deleteList`,
                              {
                                state: {
                                  category: state.state.category,
                                  site: state.state.site,
                                },
                              }
                            );
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setLoading(false);
                        });
                    });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
              });
          });
        }
      } else {
        console.log("all assets deleting");
        //--
        setLoading(true);
        let nextToken = "";
        let assets = [];
        let count = 0;
        console.log(assetTemplatData);
        //assetTemplatData.map((assetTemplate) => {
        if (assetTemplatData.length > 0) {
          for (let assetTemplate of assetTemplatData) {
            count = count + 1;
            //siteDetails.map(async (sites) => {
            for (let sites of siteDetails) {
              let tempSiteId = assetTemplate.id + "_" + sites.id;
              const result = await API.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: tempSiteId,
                  limit: limit,
                },
                authMode: "API_KEY",
              });
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                let assetDatas =
                  result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                nextToken =
                  result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
                if (
                  result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    .length > 0
                ) {
                  assets.push(assetDatas);
                }
                while (nextToken !== null) {
                  const results = await API.graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: tempSiteId,
                      nextToken: nextToken,
                      limit: limit,
                    },
                    authMode: "API_KEY",
                  });
                  if (
                    results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    nextToken =
                      results.data.assetsByAssettemplateSiteIDAndAssetNum
                        .nextToken;
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      assets.push(
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items
                      );
                    }
                  }
                }
                console.log(assets);
                console.log(count);
                console.log(assetTemplatData);
                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  console.log(mergeAssets);
                  //--deleting process
                  if (mergeAssets.length > 0) {
                    let counter = 0;
                    mergeAssets.map(async (item, i) => {
                      await sleep(Math.random() * i * 17); // wait
                      await API.graphql(
                        graphqlOperation(mutations.updateAssets, {
                          input: { id: item.id, status: "delete" },
                        })
                      )
                        .then((result) => {
                          console.log(result);
                          counter = counter + 1;
                          if (counter === mergeAssets.length) {
                            setLoading(false);
                            toast.success(
                              "Selected Assets Deleted Successfully.",
                              {
                                position: toast.POSITION.TOP_CENTER,
                              }
                            );
                            navigate(
                              `${process.env.PUBLIC_URL}/asset/deleteList`,
                              {
                                state: {
                                  category: state.state.category,
                                  site: state.state.site,
                                },
                              }
                            );
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setLoading(false);
                        });
                    });
                  }
                }
              }
            }
          }
        } else {
          const assetTemplateResults = await API.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 1000,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            const assettempdata =
              assetTemplateResults.data.assetTemplatesByCategoriesID.items;
            for (let assetTemplate of assettempdata) {
              count = count + 1;
              //siteDetails.map(async (sites) => {
              for (let sites of siteDetails) {
                let tempSiteId = assetTemplate.id + "_" + sites.id;
                const result = await API.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                }).then(async (result) => {
                  let assetDatas =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                  nextToken =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(assetDatas);
                  }
                  while (nextToken !== null) {
                    const results = await API.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: tempSiteId,
                        nextToken: nextToken,
                        limit: limit,
                      },
                      authMode: "API_KEY",
                    });
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      nextToken =
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .nextToken;
                      if (
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items.length > 0
                      ) {
                        assets.push(
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .items
                        );
                      }
                    }
                  }
                });
              }
              console.log(assets);
              console.log(count);
              console.log(assettempdata);
              if (count === assettempdata.length) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //--deleting process
                if (mergeAssets.length > 0) {
                  let counter = 0;
                  mergeAssets.map(async (item, i) => {
                    await sleep(Math.random() * i * 17); // wait
                    await API.graphql(
                      graphqlOperation(mutations.updateAssets, {
                        input: { id: item.id, status: "delete" },
                      })
                    )
                      .then((result) => {
                        console.log(result);
                        counter = counter + 1;
                        if (counter === mergeAssets.length) {
                          setLoading(false);
                          toast.success(
                            "Selected Assets Deleted Successfully.",
                            {
                              position: toast.POSITION.TOP_CENTER,
                            }
                          );
                          navigate(
                            `${process.env.PUBLIC_URL}/asset/deleteList`,
                            {
                              state: {
                                category: state.state.category,
                                site: state.state.site,
                              },
                            }
                          );
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        setLoading(false);
                      });
                  });
                }
              }
              //});
              //}
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="p-0 project-list">
        {state.state && state.state.category && (
          <H1>{state.state.category.categoryName}</H1>
        )}
        <Card className="custom-card">
          <Row>
            <Col md="3">
              <label>Select Site</label>
              {(state.state && state.state.site && state.state.site.id) ||
              selectedSiteGlobal ? (
                <select
                  className="form-select"
                  //onChange={(e) => setSelectedSite(e.target.value)}
                  disabled={
                    state.state.site ? true : selectedSiteGlobal ? true : false
                  }
                  value={
                    state.state.site ? state.state.site.id : selectedSiteGlobal
                  }
                  menuPlacement="auto"
                  menuPosition="fixed"
                >
                  {/* <option value="">All Sites</option> */}
                  {state.state && state.state.site && state.state.site.id && (
                    <option value={state.state.site.id}>
                      {state.state.site.siteName}
                    </option>
                  )}
                  {selectedSiteGlobal &&
                    siteDetails.map((site, index) => (
                      <option
                        value={site.id}
                        key={index}
                        selected={selectedSiteGlobal === site.id}
                      >
                        {site?.siteName}
                      </option>
                    ))}
                </select>
              ) : (
                <Select
                  value={selectedSearchSiteData}
                  onChange={handleSiteDDChange}
                  options={sites}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              )}
            </Col>
            <Col md="3">
              <label>Select Distribution Board</label>
              <Select
                value={selectedSearchDBData}
                onChange={handleSiteDB}
                options={optionsDB}
                isSearchable={true}
                hideSelectedOptions={false}
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
            <Col md="6" className="d-flex">
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: handleSearch,
                  disabled: isSearch,
                }}
              >
                Search
              </Btn>
              {!deleteAsset ? (
                <Btn
                  attrBtn={{ color: "primary", onClick: () => setDelete(true) }}
                >
                  Delete
                </Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", onClick: deleteToggle }}>
                  Confirm Delete
                </Btn>
              )}
              <Btn attrBtn={{ color: "primary", onClick: deleteAllToggle }}>
                Delete All Assets
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    setsSiteIndex(0);
                    setIsSearch(false);
                    setFullItems([]);
                    setSearchAssets(false);
                    setIsSecoundSearch(false);
                    setDelete(false);
                    setIsLoading(true);
                    state.state.site
                      ? setSelectedSite(state.state.site.id)
                      : setSelectedSite("");
                    setSelectedBox("");
                    setValue("");
                    setSelectedLocation("");
                    setSelectedSearchSiteData("");
                    setSelectedSearchDBData("");
                    setSelectedItemsAsset([]);
                    isReset = true;
                    getAssets();
                  },
                  disabled: !isSearch,
                }}
              >
                {!deleteAsset ? "Reset" : "Reset Delete"}
              </Btn>
              <Link
                to={`${process.env.PUBLIC_URL}/asset/deleteList`}
                state={{
                  category:
                    state.state && state.state.category
                      ? state.state.category
                      : "",
                  site: state.state && state.state.site ? state.state.site : "",
                }}
                className="link"
              >
                Deleted Assets
              </Link>
            </Col>
            {/* <Col md="3">
              <input
                type="text"
                className="form-control"
                placeholder="Filter by location"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
              />
            </Col> */}
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="12" className="text-end">
              <div className="assetAdd">
                {/* <Badges
                  attrBadge={{
                    color: "light txt-dark",
                    onClick: () => setAssetView("list"),
                  }}
                >
                  <i className="icofont icofont-table"></i>
                </Badges>
                <Badges
                  attrBadge={{
                    color: "light txt-dark",
                    className: "icons",
                    onClick: () => setAssetView("card"),
                  }}
                >
                  <Grid />
                </Badges> */}
                <Media body className="icon-stateCat switch-smcat">
                  <Label className="mb-0 switchCat">
                    <input
                      type="checkbox"
                      onClick={activeInactive}
                      value={isActive ? "Active" : "Inactive"}
                    />
                    <span
                      className={
                        isActive
                          ? "switch-state bg-success"
                          : "switch-state bg-danger"
                      }
                    ></span>
                  </Label>
                </Media>
                {state.state &&
                  state.state.category &&
                  state.state.category.status !== "Inactive" && (
                    <>
                      {
                        // LoginUserDetails.paymentId === 5 &&
                        (
                          addOn.length > 0
                            ? assetsCount >= assetPlancount &&
                              new Date(addOn[0].dueDate) > new Date()
                            : assetsCount >= assetPlancount
                        ) ? (
                          <>
                            <Dropdown className="separated-btn">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                                title="Assets Limit Exceeded"
                              >
                                Add Assets
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {userPermission.includes("Create Assets") && (
                                  <Dropdown.Item
                                    title="Assets Limit Exceeded"
                                    //onClick={() => navigatePage("add")}
                                  >
                                    Add Asset
                                  </Dropdown.Item>
                                )}
                                {userPermission.includes("Import Assets") && (
                                  <Dropdown.Item
                                    title="Assets Limit Exceeded"
                                    //onClick={() => navigatePage("import")}
                                    // href={
                                    //   (`${process.env.PUBLIC_URL}/bulkimport`,
                                    //   {
                                    //     state: {
                                    //       category: state.state.category,
                                    //       site: state.state.site,
                                    //     },
                                    //   })
                                    // }
                                  >
                                    Bulk Import
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        ) : (
                          <>
                            <Dropdown className="separated-btn">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                Add Assets
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {userPermission.includes("Create Assets") && (
                                  <Dropdown.Item
                                    onClick={() => navigatePage("add")}
                                  >
                                    Add Asset
                                  </Dropdown.Item>
                                )}
                                {userPermission.includes("Import Assets") && (
                                  <Dropdown.Item
                                    onClick={() => navigatePage("import")}
                                    // href={
                                    //   (`${process.env.PUBLIC_URL}/bulkimport`,
                                    //   {
                                    //     state: {
                                    //       category: state.state.category,
                                    //       site: state.state.site,
                                    //     },
                                    //   })
                                    // }
                                  >
                                    Bulk Import
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        )
                      }
                    </>
                  )}
                <div className="text-end">
                  <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                    Back
                  </Btn>
                </div>
              </div>
              {/* <Row>
                  <Col md="3"> */}
              {/* </Col>
                </Row> */}
            </Col>
          </Row>
          {(addOn.length > 0
            ? assetsCount >= assetPlancount &&
              new Date(addOn[0].dueDate) > new Date()
            : assetsCount >= assetPlancount) && (
            <Row>
              <Col md="6">
                <p className="errorSubscription">
                  Sorry!.. Your Asset Limit is Exceeded, Please Upgrade your
                  Subscription to{" "}
                  {LoginUserDetails.paymentId === "1"
                    ? "Tier 1"
                    : LoginUserDetails.paymentId === "2"
                    ? "Tier 2"
                    : "Corporate"}
                </p>
              </Col>
              {LoginUserGroup !== "site_user" && (
                <Col md="6">
                  <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                    <H4>Click here to Top Up the Assets</H4>
                  </Link>
                </Col>
              )}
            </Row>
          )}
          {/* </CardHeader> */}
        </Card>
        <Row></Row>
        {assetView === "card" ? (
          <div className="card-block row">
            <Pagination
              className="p-t-10"
              pageSize={countPerPageCard}
              onChange={updatePageCard}
              current={currentPageCard}
              total={
                value ||
                (state.state && state.state.site ? false : selectedSite) ||
                selectedLocation ||
                selectedBox
                  ? filteredData.length
                  : state.state && state.state.site && state.state.category
                  ? assetDemoCard.length
                  : assetDemoCard.length
              }
              showTotal={(total, range) =>
                `${range[0]} - ${range[1]} of ${total} items`
              }
            />
            <Row>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {collectionCard.filter((item) =>
                    isActive
                      ? item.status === "Active"
                      : item.status === "Inactive"
                  ).length > 0 ? (
                    collectionCard &&
                    collectionCard
                      .filter((item) =>
                        isActive
                          ? item.status === "Active"
                          : item.status === "Inactive"
                      )
                      .map((item) => (
                        <Col md="3" key={item.id}>
                          <Link
                            to={`${process.env.PUBLIC_URL}/asset/view`}
                            state={{
                              category: state.state.category,
                              item: item,
                            }}
                          >
                            <Card>
                              <p className="activestatus">{item.assetModel}</p>
                              <div
                                className={
                                  item.status === "Active"
                                    ? "ribbon ribbon-clip-right ribbon-right ribbon-success"
                                    : "ribbon ribbon-clip-right ribbon-right ribbon-danger"
                                }
                              >
                                {item.status}
                              </div>
                              <CardBody>
                                <Media className="static-widget">
                                  <br></br>
                                  <Media body>
                                    <H4
                                      id="status"
                                      attrH6={{ className: "font-roboto" }}
                                    >
                                      {" "}
                                      {getDBName(item.distributionboxID)}{" "}
                                    </H4>
                                    <br></br>
                                    <b>Site</b>
                                    <br></br>
                                    {getSiteName(item.sitedetailsID)}
                                    <br></br> <br></br>
                                    <b>Installed On</b>
                                    <br></br>
                                    {moment(item.installedOn).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>
                      ))
                  ) : (
                    <p>No Assets Found</p>
                  )}{" "}
                </>
              )}
            </Row>
          </div>
        ) : (
          <Card className="custom-card">
            {/* <Row>
              <Col md="12">
                <InputGroup>
                  <span className="input-group-text">
                    <i className="icon-search"></i>
                  </span>
                  <Input
                    type="search"
                    placeholder="Search Assets.."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row> */}
            <div className="card-block row">
              <Col sm="12" lg="12" xl="12">
                {/* <Pagination
                className="p-b-10"
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={
                  value ||
                  (state.state && state.state.site ? false : selectedSite) ||
                  selectedLocation ||
                  selectedBox
                    ? filteredData.length
                    : state.state && state.state.site && state.state.category
                    ? assetDemo.length
                    : assetDemo.length
                }
                showTotal={(total, range) =>
                  `${range[0]} - ${range[1]} of ${total} items`
                }
              /> */}
                Loaded{" "}
                {
                  fullItems.filter((item) =>
                    isActive
                      ? item.status === "Active"
                      : item.status === "Inactive"
                  ).length
                }{" "}
                of {state.state.category.assetsCount} Assets
                <div
                  ref={containerRef}
                  onScroll={handleScroll}
                  style={{ height: "500px", overflowY: "auto" }}
                >
                  <div className="table-responsive tableFixHead">
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <Table className="table-striped  table-de ">
                          <thead
                            className="custom-table-head"
                            style={{
                              position: "sticky",
                              top: "0",
                              backgroundColor: "#fff",
                            }}
                          >
                            <tr>
                              {deleteAsset && (
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onChange={(event) =>
                                      handleCheckboxAssetChange(
                                        event,
                                        "selectAll"
                                      )
                                    }
                                  />
                                </th>
                              )}
                              <th>Fitting No.</th>
                              <th>Asset Model</th>
                              <th>Site</th>
                              <th>Distribution board</th>
                              <th>Installed On</th>
                              <th>Asset Warranty</th>
                              <th>Asset Location</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody className="trhover">
                            {fullItems.filter((item) =>
                              isActive
                                ? item.status === "Active"
                                : item.status === "Inactive"
                            ).length > 0 ? (
                              fullItems &&
                              fullItems
                                .filter((item) =>
                                  isActive
                                    ? item.status === "Active"
                                    : item.status === "Inactive"
                                )
                                .sort(
                                  (a, b) => a.fittingNumber - b.fittingNumber
                                )
                                .map((item, i) => (
                                  <tr
                                    key={item.id}
                                    className={
                                      item.duplicate ? "duplicateRow" : ""
                                    }
                                    title={item.duplicate ? item.duplicate : ""}
                                  >
                                    {/* <td>{item.duplicate}</td> */}
                                    {deleteAsset && (
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="completionLink"
                                          checked={selectedItemsAsset.includes(
                                            item.id
                                          )}
                                          onChange={(event) =>
                                            handleCheckboxAssetChange(
                                              event,
                                              item.id
                                            )
                                          }
                                        />
                                      </td>
                                    )}
                                    <td>
                                      {userPermission.includes(
                                        "Edit Assets"
                                      ) ? (
                                        state.state && (
                                          <Link
                                            to={`${process.env.PUBLIC_URL}/asset/view`}
                                            state={{
                                              category: state.state.category,
                                              item: item,
                                              site: getSiteName(
                                                item.sitedetailsID
                                              ),
                                              db: getDBName(
                                                item.distributionboxID
                                              ),
                                            }}
                                          >
                                            {item.fittingNumber}
                                          </Link>
                                        )
                                      ) : (
                                        <>{item.fittingNumber}</>
                                      )}
                                    </td>
                                    <td>{item.assetModel}</td>
                                    <td>{getSiteName(item.sitedetailsID)}</td>
                                    <td>{getDBName(item.distributionboxID)}</td>
                                    <td>
                                      {item.installedOn !== null
                                        ? moment(item.installedOn).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </td>
                                    {item.assetWarrantyStart != null && (
                                      <td>
                                        {moment(item.assetWarrantyStart).format(
                                          "DD/MM/YYYY"
                                        )}{" "}
                                        -
                                        {moment(item.assetWarrantyEnd).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                    )}
                                    {item.assetWarrantyStart === null && (
                                      <td>-</td>
                                    )}
                                    <td>{item.assetLocation}</td>
                                    <td>
                                      <Media
                                        body
                                        className="icon-state switch-sm"
                                      >
                                        <Label className="switch">
                                          <input
                                            type="checkbox"
                                            onChange={(e) =>
                                              handleChange(item, e)
                                            }
                                          />
                                          <span
                                            className={ApproveStatus(
                                              item.status
                                            )}
                                          ></span>
                                        </Label>
                                      </Media>
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <p>No Assets Found</p>
                            )}{" "}
                          </tbody>
                        </Table>
                        {hasMore &&
                          !isSearch &&
                          fullItems.filter((item) =>
                            isActive
                              ? item.status === "Active"
                              : item.status === "Inactive"
                          ).length > 0 && <H4>Loading more Assets...</H4>}
                      </>
                    )}
                  </div>
                </div>
                <div></div>
                <div></div>
                <div></div>
              </Col>
            </div>
          </Card>
        )}
      </Container>
      <Modal isOpen={deleteModal} toggle={deleteToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>Are you sure, to delete selected Assets??</H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteAllModal} toggle={deleteAllToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>
              Are you sure, to delete{" "}
              {selectedSite
                ? assetscountbysite[0] && assetscountbysite[0].assetCount
                : state.state.category.assetsCount}{" "}
              Assets??
            </H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteAllToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAllAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
export default AssetsListDetails;
